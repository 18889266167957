import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { DocumentsState } from './types';
import { Document } from '@/models/document/document';
import { Category } from '@/models/category/category';

export const getters: GetterTree<DocumentsState, RootState> = {
  getDocumentCategories(state): Array<Category> {
    return state.documentCategories;
  },

  getDocuments(state): Array<Document> {
    return state.documents;
  },
};
