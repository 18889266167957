import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { SlidesState } from './types';
import { Slide } from '@/models/slide/slide';

export const getters: GetterTree<SlidesState, RootState> = {
  getSlides(state): Array<Slide> {
    return state.slides;
  },
};
