import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ConfigurationState } from './types';
import { Configuration } from '@/models/configuration/configuration';

export const getters: GetterTree<ConfigurationState, RootState> = {
  getConfiguration(state): Configuration | null {
    return state.configuration;
  },
  getMounted(state): boolean {
    return state.value;
  },
};
