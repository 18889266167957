
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import { Document } from '@/models/document/document';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import DocumentCard from '@/components/inputs/document-card/document-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedDocument'],
  components: {
    InputFormGroupText,
    SelectFormGroup,
    ImageCard,
    DocumentCard,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      uploading: false,
      options: [] as any,
      document: this.injectedDocument,
      docName: '',
      node_id: '',
      oldData: {} as Document,
      imageTags: ['image', 'profile'],
      documentTags: ['document'],
      medias: (this as any).getMediasFormNode(this.injectedDocument, []),
      lockUpdate: true,
      myDocument: null as any,
      validate: {
        category: '',
        desc: ''
      },
    };
  },
  created: function () {
    this.$watch('document', this.documentUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  computed: {
    ...mapGetters('library', ['getDocumentCategories', 'getDocuments']),

    haveDefaultData(): boolean {
      return !!this.document;
    },
    creating(): boolean {
      return !!this.injectedDocument;
    },
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('library', ['addDocument', 'updateDocument']),
    ...mapActions('media', ['updateMediasNode']),

    documentUpdated: function () {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(media: Media[]) {
      this.medias = (this as any).updateMediasByTags(this.medias, media);
    },

    updateDocuments(documents: Media[]) {
      this.docName = documents[0].display_name;
      this.medias = (this as any).updateMediasByTags(this.medias, documents);
    },

    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },

    retrieveDocs() {
      return (this as any).retrieveMediaByTags(
        ['document'],
        this.medias,
      );
    },

    init() {
      if (!this.haveDefaultData) {
        this.document = { value: { desc: '', tag: '', slug: '' } };
      }
      if(this.creating) {
        if(this.document.category === undefined)
          this.document.category = this.getLibraryParentName(this.document.navigation_path)
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    validation(): boolean{
      let validationState = true
      if(this.document.value.tag === undefined) {
        this.validate.category = 'false'
        validationState = false
      }else{
        this.validate.category = ''
      }
      if(this.document.value.desc === '') {
        this.validate.desc = 'false'
        validationState = false
      }else{
        this.validate.desc = ''
      }
      return validationState
    },
    save() {
      const name = (this as any).buildName('document');
      if (this.validation() === true) {
        this.document.value.slug = (this as any).slugify(this.docName)

        const node = (this as any).buildCreatePayloadWithName(
          this.$data.required_nodes,
          'bibliotheque_medias',
          NodeType.content,
          this.document.value, 
          name,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addDocument(dto).then(() => {
          this.validation();
          this.$emit('cancel');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if (this.validation() === true) {
        if(this.docName !== '')
          this.document.value.slug = (this as any).slugify(this.docName)

        const mediaNavigationPath = this.document.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.document,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateDocument(this.document);
          this.validation();
          this.cancel();
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    getLibraryParentName(navigation_path: any){
      const path = navigation_path.split('/').slice(0,5)
      const my_path = path.join('/');
      const currentCategory = (this as any).getDocumentCategories!.find(
        (path: any) => path.navigation_path === my_path,
      );
      if(currentCategory)
       return currentCategory.name
      else return ''
    },
  },
  beforeMount() {
    this.init();
    this.options = [];
    this.options.push({
      id: null,
      value: null,
      text: 'Veuillez selectionner une categorie',
    });
    this.getDocumentCategories.forEach((element: any) => {
      this.options.push({id: element.node_id, value: element.node_id, text: element.value.name });
    });
  },
  watch: {
    document() {
      this.init();
    },
    getDocumentCategories() {
      this.options = [];
      this.options.push({
        value: null,
        text: 'Veuillez selectionner une categorie',
      });
      this.getDocumentCategories.forEach((element: any) => {
        this.options.push({id: element.node_id, value: element.node_id, text: element.value.name });
      });
    },
  },
});
