import { StateOfficer } from '@/models/state-officer/state-officer';
import { MutationTree } from 'vuex';
import { StateOfficerState } from './types';
import { Media } from '@/models/media/media';

export const mutations: MutationTree<StateOfficerState> = {
  setStateOfficers(state, payload: Array<StateOfficer>) {
    state.stateOfficers = payload;
  },

  addStateOfficer(state, payload: StateOfficer) {
    state.stateOfficers.unshift(payload);
  },

  addStateOfficerMedias(state, payload: Media[]) {
    state.stateOfficerMedias = state.stateOfficerMedias.concat(payload);
  },

  removeStateOfficer(state, payload: StateOfficer) {
    state.stateOfficers = state.stateOfficers.filter(
      (stateOfficer) => stateOfficer.node_id !== payload.node_id,
    );
  },

  updateStateOfficer(state, payload: StateOfficer) {
    const currentStateOfficer = state.stateOfficers.find(
      (stateOfficer) => stateOfficer.node_id == payload.node_id,
    );

    if (currentStateOfficer) {
      const index = state.stateOfficers.indexOf(currentStateOfficer);
      state.stateOfficers[index] = payload;
    }
  },
};
