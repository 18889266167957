import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/reseaux/getters';
import { actions } from '@/store/modules/reseaux/action';
import { mutations } from '@/store/modules/reseaux/mutations';
import { ReseauxState } from './types';
export const state: ReseauxState = {
  reseaux: null,
  value: false,
};

export const reseaux: Module<ReseauxState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
