
import Vue from 'vue';
export default Vue.extend({
  data() {
    return{
      inputState: ''
    }
  },
  props: {
    value: String,
    inputId: String,
    type: String,
    label: String,
    validate: Boolean,
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
    textareaRow: {
      type: Number,
      default: 3,
    },
    textareaMaxRows: {
      type: Number,
      default: 6,
    },
    alignLabel: {
      type: Boolean,
      default: true,
    },
    addFontWeigth: {
      type: Boolean,
      default: true,
    },
    addColor: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    sendChanges(event: string) {
      this.$emit('input', event);
      this.$emit('change');
    },
  },
  computed: {
    state(): boolean | null {
      if (this.inputState === 'true'){
        return true
      }else if(this.inputState === 'false'){
        return false
      }
      return null
    }
  },
  watch: {
    'validate': function validateInput(state: string){
                  this.inputState = state
                },
  }
});
