
import { Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import commonVars from "@/common/core/commonVars";
import { Utils } from "@/common/core/utils";
import { orchestratorUrl } from "@/app.config";
export default Vue.extend({
  name: "NavbarApp",
  props: {
    exclude: { type: Array, default: () => [] },
  },
  data() {
    return {
      profilDisplayName: Utils._pageMap.get(commonVars.PROFIL),
      profil: commonVars.PROFIL,
      profilPath: commonVars.PROFIL_PATH,
      workingSpacePath: commonVars.WORKINGSPACE_PATH,
      workingSpace: commonVars.HOME,
      structureName: commonVars.STRUCTURE_NAME,
      siteName: commonVars.SITE_NAME,

      logoutUrl: orchestratorUrl + '/logout'
    };
  },
  methods: {
    ...mapActions("profil", ["setIsProfilVisible"]),
    menuClickEvent(routeName: string) {
      this.$router.push({ path: routeName });
    },
    displayedName(name: string): string {
      return Utils._pageMap.get(name)!;
    },
  },
  computed: {
    ...mapGetters("routeRedirect", ["getRouteName"]),
    ...mapGetters("profil", ["getProfileMock"]),
    ...mapGetters(["getUser"]),
    currentRouteName(): string {
      return this.$route.name!;
    },
    excluded() {
      return this.exclude.indexOf(this.$route.path.split('/').pop()) > -1;
    },
    tree() {
      return [''].concat(
        this.$route.path
          .split('/')
          .slice(1)
          .map((route) =>
            route
              .split('-')
              .map((word) => word[0].toUpperCase() + word.slice(1))
              .join(' '),
          ),
      );
    },
  },
});
