import { ActionTree } from 'vuex';
import { LayoutState } from './types';
import { RootState } from '@/store/types';

export const actions: ActionTree<LayoutState, RootState> = {
  setActiveSidebar(context, payload: boolean) {
    context.commit('setActiveSidebar', payload);
  },

  setSidebarActiveElement(context, payload: string) {
    context.commit('setSidebarActiveElement', payload);
  },
};
