import { Slide } from '@/models/slide/slide';
import { MutationTree } from 'vuex';
import { SlidesState } from './types';

export const mutations: MutationTree<SlidesState> = {
  setSlides(state, payload: Array<Slide>) {
    state.slides = payload;
  },
  addSlider(state, payload: Slide) {
    state.slides.unshift(payload);
  },
  removeSlider(state, payload: Slide) {
    state.slides = state.slides.filter(
      (slide) => slide.node_id !== payload.node_id,
    );
  },
  updateSlide(state, payload: Slide) {
    const currentSlide = state.slides.find(
      (slide) => slide.node_id == payload.node_id,
    );

    if (currentSlide) {
      const index = state.slides.indexOf(currentSlide);
      state.slides[index] = payload;
    }
  },
};
