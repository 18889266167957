import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { MediaState } from './types';
import { Media } from '@/models/media/media';

export const getters: GetterTree<MediaState, RootState> = {
  getLastMediaUploaded(state): Array<Media> {
    return state.lastMediaUploaded;
  },

  isMediaStore(state): boolean {
    return state.isMediaStore;
  },
};
