import { NodeType } from '@/models/enums/enums';
import { Node } from '@/models/node/node';
import moment from 'moment';
import { DataNodeCreateDto } from '@/dto/data-node-dto';
import { Media } from '@/models/media/media';

export const build = {
  data() {
    return {
      required_nodes: [
        {
          name: 'mairie',
          display_name: 'Mairie',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie',
        },
        {
          name: 'municipalite',
          display_name: 'Municipalité',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite',
        },
        {
          name: 'executif',
          display_name: 'Executif',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/executif',
        },
        {
          name: 'officiers_etat',
          display_name: "Officiers d'état",
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/officiers_etat',
        },
        {
          name: 'commissions',
          display_name: 'Commissions',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/commissions',
        },
        {
          name: 'conseil_municipal',
          display_name: 'Conseil municipal',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/conseil_municipal',
        },
        {
          name: 'conseillers_municipaux',
          display_name: 'Conseillers municipaux',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/conseillers_municipaux',
        },
        {
          name: 'services_communaux',
          display_name: 'Services Communaux',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/services_communaux',
        },
        {
          name: 'categories',
          display_name: 'Categories',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/services_communaux/categories',
        },
        {
          name: 'bibliotheque',
          display_name: 'Bibliotheque',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/bibliotheque',
        },
        {
          name: 'bibliotheque_medias',
          display_name: 'Medias',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/bibliotheque/bibliotheque_medias',
        },
        {
          name: 'bibliotheque_categories',
          display_name: 'Categories',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/bibliotheque/bibliotheque_categories',
        },
        {
          name: 'infos_generales',
          display_name: 'Informations Generales',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales',
        },
        {
          name: 'slider',
          display_name: 'Slider',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/slider',
        },
        {
          name: 'partners',
          display_name: 'Partners',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/partners',
        },
        {
          name: 'tags',
          display_name: 'tags',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/tags',
        },
        {
          name: 'motifs',
          display_name: 'motifs',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/motifs',
        },
        {
          name: 'contact_motifs',
          display_name: 'contact_motifs',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/contact_motifs',
        },
        {
          name: 'rdv_motifs',
          display_name: 'rdv_motifs',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/rdv_motifs',
        },
        {
          name: 'editorial',
          display_name: 'editorial',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/editorial',
        },
        {
          name: 'organigramme',
          display_name: 'organigramme',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/organigramme',
        },
        {
          name: 'reseaux',
          display_name: 'reseaux',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/reseaux',
        },
        {
          name: 'localisation',
          display_name: 'localisation',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/localisation',
        },
        {
          name: 'configuration',
          display_name: 'configuration',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/configuration',
        },
        {
          name: 'mediatheque',
          display_name: 'mediatheque',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/mediatheque',
        },
      ] as Node[],
    };
  },

  methods: {
    buildName(t: string): string {
      return t + '_' + moment().format('x');
    },
     
    slugify(str: string): string{
      const code = Math.floor((999999 - 100000) * Math.random()) + 100000;
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '') +'-'+code
    },

    buildNavigationPath(prefix: string, t: string): string {
      return prefix + '/' + t;
    },

    buildCreatePayload(
      nodes: Node[],
      parent_name: string,
      nodeType: NodeType,
      payload: any,
    ): DataNodeCreateDto {
      const parent = nodes.find((node: Node) => node.name == parent_name);

      if (!parent) {
        throw new Error('Cannot find a parent');
      }

      const name = this.buildName(parent.name);

      const node: Node = {
        name: name,
        display_name: parent.display_name,
        value: payload,
        node_type: nodeType,
        navigation_path: this.buildNavigationPath(parent.navigation_path, name),
      };

      const createDto: DataNodeCreateDto = {
        contents_requests: [node],
      };

      return createDto;
    },

    buildCreatePayloadWithName(
      nodes: Node[],
      parent_name: string,
      nodeType: NodeType,
      payload: any,
      name: string,
    ): DataNodeCreateDto {
      const parent = nodes.find((node: Node) => node.name == parent_name);

      if (!parent) {
        throw new Error('Cannot find a parent');
      }

      const node: Node = {
        name: name,
        display_name: parent.display_name,
        value: payload,
        node_type: nodeType,
        navigation_path: this.buildNavigationPath(parent.navigation_path, name),
      };

      const createDto: DataNodeCreateDto = {
        contents_requests: [node],
      };

      return createDto;
    },

    buildCreateChildrenPayload(
      parent_name: string,
      nodeType: NodeType,
      payload: any, 
      navigation_path: string,
    ): DataNodeCreateDto {
      const name = this.buildName(parent_name);

      const node: Node = {
        name: name,
        display_name: parent_name,
        value: payload,
        node_type: nodeType,
        navigation_path: this.buildNavigationPath(navigation_path, name),
      };

      const createDto: DataNodeCreateDto = {
        contents_requests: [node],
      };

      return createDto;
    },

    buildCreateChildrenPayloadWithName(
      name: string,
      nodeType: NodeType,
      payload: any,
      navigation_path: string,
    ): DataNodeCreateDto {
      const node: Node = {
        name: name,
        display_name: name,
        value: payload,
        node_type: nodeType,
        navigation_path: this.buildNavigationPath(navigation_path, name),
      };

      const createDto: DataNodeCreateDto = {
        contents_requests: [node],
      };

      return createDto;
    },

    buildMediaPayload(parentNavigationPath: string, medias: Media[]): Node {
      const mediaNode = {
        name: 'medias',
        display_name: 'Medias',
        value: {
          medias: medias,
        },
        node_type: NodeType.media,
        navigation_path: parentNavigationPath + '/medias',
      };
      return mediaNode;
    },

    buildPayloadWithMedia(
      nodePayload: DataNodeCreateDto,
      mediaPayload: any,
    ): DataNodeCreateDto {
      nodePayload.contents_requests.push(mediaPayload);
      return nodePayload;
    },
  },
};
