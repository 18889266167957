import { CreateMediaDto } from '@/dto/media-dto';
import { ApiResponse } from '@/models/http/ApiResponse';
import { Media } from '@/models/media/media';
import { HttpBaseService } from './httpBase.service';
import Vue from 'vue';
import { contentManagerApiUrl } from "@/app.config";

export class MediaNodeService extends HttpBaseService {
  private static classInstance?: MediaNodeService;

  static apiBaseUrl = contentManagerApiUrl;
  
  constructor(token: string) {
    super(token, MediaNodeService.apiBaseUrl);
  }

  public static getInstance(token: string): MediaNodeService {
    if (!this.classInstance) {
      this.classInstance = new MediaNodeService(token);
    }

    return this.classInstance;
  }

  public create(mediaRequest: CreateMediaDto): Promise<ApiResponse<Media>> {
    const mediaForm = new FormData();
    mediaForm.append('tags', mediaRequest.tags.toString());
    mediaForm.append('file_name', mediaRequest.file_name);
    mediaRequest.medias.forEach((value) => {
      mediaForm.append('medias', value);
    });


    const apiResponse = new ApiResponse<Media>();
    return this.instance
      .post('/media/create', mediaForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        Vue.$toast('Echec de chargement du media');
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}
