import { Profile } from "@/models/profil/profil";
import { MutationTree } from "vuex";
import { ProfilState } from "./types";

export const mutations: MutationTree<ProfilState> = {
  setIsProfilVisible(state, payload: boolean) {
    state.isProfilVisible = payload;
  },

  setProfile(state, payload: Profile[]) {
    state.profileMock = payload
  }
};
