
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedSubCategory', 'categories'],
  components: {
    InputFormGroupText,
    ImageCard,
    SelectFormGroup,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      uploading: false,
      subCategory: this.injectedSubCategory,
      parent_category: '',
      categoriesOptions: [] as any,
      imageTags: ['image', 'profile'],
      medias: (this as any).getMediasFormNode(this.injectedSubCategory, []),
      lockUpdate: true,
      validate: {
        parent_category: '',
        name: '',
        desc: ''
      },
    };
  },
  computed: {
    ...mapGetters('serviceCommunalCategories', [
      'getServicesCommunauxCategories',
    ]),

    haveDefaultData(): boolean {
      return !!this.subCategory;
    },
    creating(): boolean {
      return !!this.injectedSubCategory;
    },
  },
  created() {
    this.$watch('subCategory', this.subCategoryUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  beforeMount() {
    this.init();
    this.categoriesOptions = [];
    this.categoriesOptions.push({
      value: null,
      text: 'Veuillez selectionner une categorie',
    });
    this.categories.forEach((element: any) => {
      this.categoriesOptions.push({
        value: element.name,
        text: element.value.name,
      });
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('serviceCommunalCategories', [
      'addSubCategorie',
      'updateSubCategory',
    ]),
    ...mapActions('media', ['updateMediasNode']),

    subCategoryUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },

    init() {
      if (!this.haveDefaultData) {
        this.subCategory = { value: { name: '', desc: '' } };
      }
      if(this.creating) {
        if(this.subCategory.parent_category === undefined)
          this.subCategory.parent_category = this.getSubCategoryParentName(this.subCategory.navigation_path)
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    validation(): boolean{
      let validationState = true
      if(this.subCategory.parent_category === undefined) {
        this.validate.parent_category = 'false'
        validationState = false
      }else{
        this.validate.parent_category = ''
      }
      if(this.subCategory.value.name === '') {
        this.validate.name = 'false'
        validationState = false
      }else{
        this.validate.name = ''
      }
      if(this.subCategory.value.desc === '') {
        this.validate.desc = 'false'
        validationState = false
      }else{
        this.validate.desc = ''
      }
      return validationState
    },
    save() {
      const name = this.subCategory.value.name.toLowerCase();
      const defineName = name.replace(/\s/g, '_');
      const nameCleaned = defineName.replace(/[^a-zA-Z0-9_-]/g,'');
      if (this.validation() === true) {
        this.subCategory.value.slug = (this as any).slugify(this.subCategory.value.name)

        const node = (this as any).buildCreateChildrenPayloadWithName(
          nameCleaned,
          NodeType.content,
          this.subCategory.value,
          '/mairie/services_communaux/categories/' + this.subCategory.parent_category,
        );
        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );
        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);
        this.addSubCategorie(dto).then(() => {
          this.validation();
          this.$emit('cancel');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if (this.validation() === true) {
        this.subCategory.value.slug = (this as any).slugify(this.subCategory.value.name)
        
        const mediaNavigationPath = this.subCategory.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.subCategory,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateSubCategory(this.subCategory);
          this.validation();
          this.$emit('cancel');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    getSubCategoryParentName(navigation_path: any){
      const path = navigation_path.split('/').slice(0,5)
      const my_path = path.join('/');
      const currentCategory = (this as any).getServicesCommunauxCategories!.find(
        (path: any) => path.navigation_path === my_path,
      );
      if(currentCategory)
       return currentCategory.name
      else return ''
    },
  },
  watch: {
    subCategory() {
      this.init();
    },
    categories() {
      this.categoriesOptions = [];
      this.categoriesOptions.push({
        value: null,
        text: 'Veuillez selectionner une categorie',
      });
      this.categories.forEach((element: any) => {
        this.categoriesOptions.push({
          value: element.name,
          text: element.value.name,
        });
      });
    },
  },
});
