
import { CreateMediaDto } from '@/dto/media-dto';
import { Media } from '@/models/media/media';
import Vue from 'vue';
import { mapActions } from 'vuex';
import { mediaMixin } from '@/mixins/media.mixin';
import { build } from '@/mixins/build.mixin';

export default Vue.extend({
  name: 'InputDocument',
  mixins: [mediaMixin, build],
  props: {
    inputId: String,
    id: Number,
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      documentName: '' as string,
      enableAdd: false,
      documents: [] as Media[],
    };
  },

  computed: {
    enableAddButton(): boolean {
      return this.documentName.length == 0;
    },
  },

  methods: {
    ...mapActions('media', ['createMedias']),

    uploadImage(files: File[]) {
      // const name = (this as any).buildName('stateOfficerProfile');
      const name = this.documentName;
      
      const payload: CreateMediaDto = (this as any).buildPayload(
        name,
        this.tags,
        files,
      );

      this.createMedias(payload).then((value) => {
        if (value.data) {
          this.documents = this.documents.concat(value.data);
          this.$emit('updateDocuments', this.documents);
          this.$parent!.$emit('updateDocuments', this.documents);
          Vue.$toast('Document(s) ajouté(s)');
        }
        if (value.apiError) {
          Vue.$toast("Erreur lors de l'upload");
        }
      });
    },

    choose() {
      let file = document.createElement('input');

      file.type = 'file';
      file.accept = (this as any).acceptDocumentFormat;
      file.multiple = false;
      file.click();

      const files: File[] = [];

      file.onchange = () => {
        if (file.files) {
          for (let i = 0; i < file.files.length; i++) {
            const currentFile = file.files.item(i);
            if (currentFile) {
              files.push(currentFile);
            }
          }
        }

        this.uploadImage(files);
      };
    },
  },
});
