var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-form-group',{class:{
      'd-flex justify-content-center': _vm.alignLabel,
      'align-items-center': _vm.type != 'textarea',
    },attrs:{"label":_vm.label,"label-class":{
      'text-right': _vm.alignLabel,
      'mb-0 p-0 pl-1': !_vm.alignLabel,
      'input-label-color label': _vm.addColor,
    },"label-for":_vm.inputId,"label-cols":_vm.alignLabel ? 3 : 12,"content-cols":_vm.alignLabel ? 9 : 12}},[(
        _vm.type == 'text' || _vm.type == 'email' || _vm.type == 'tel' || _vm.type == 'date'
      )?_c('b-form-input',{staticClass:"my-form-input",attrs:{"id":_vm.inputId,"type":_vm.type,"placeholder":_vm.inputPlaceholder,"value":_vm.value,"disabled":_vm.inputDisabled,"required":"","state":_vm.state},on:{"input":_vm.sendChanges}}):_vm._e(),(_vm.type == 'textarea')?_c('b-form-textarea',{attrs:{"id":_vm.inputId,"value":_vm.value,"placeholder":_vm.inputPlaceholder,"rows":_vm.textareaRow,"max-rows":_vm.textareaMaxRows,"disabled":_vm.inputDisabled,"required":"","state":_vm.state},on:{"input":_vm.sendChanges}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }