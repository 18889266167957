import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/mediatheques/getters';
import { actions } from '@/store/modules/mediatheques/action';
import { mutations } from '@/store/modules/mediatheques/mutations';
import { MediathequesState } from './types';
export const state: MediathequesState = {
  mediatheques: [],
};

export const mediatheques: Module<MediathequesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
