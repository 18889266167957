
  import { Vue } from 'vue-property-decorator';
  import Section from '@/components/section/section.vue';
  import Table from '@/components/table/table.vue';
  import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
  import ContactForm from '@/components/forms/contact-form/contact-form.vue';
  import { mapActions, mapGetters } from 'vuex';
  import { Category } from '@/models/category/category';
  import { layoutMixin } from '@/mixins/layout.mixin';
  export default Vue.extend({
    name: 'Contact',
    mixins: [layoutMixin],
    components: {
      Section,
      Table,
      ContactForm,
      ConfirmModal,
    },
    data() {
      return {
        tabIndex: 0,
        motifId: '',
        id: '',
        motifFormIsShown: false, 
        motifEtat: true,
  
        selectedMotif: null as any,
        motifsFilterOptions: [
          { value: null, text: 'Veuillez selectionner le filtre' },
          { value: 'motif', text: 'Motif' },
        ],
      };
    },
    computed: {
      ...mapGetters('pagesAdmin', ['getContactMotifs',]),
    },
    methods: {
      ...mapActions('dataNode', ['deleteNode']),
      ...mapActions('pagesAdmin', [
        'deleteContactMotif',
      ]),

      editTag(selectedMotif: Category) {
        this.selectedMotif = selectedMotif;
        this.showTagForm();
      },
  
      showTagForm() {
        this.motifFormIsShown = true;
        this.motifEtat = false
      },

      cancelTagForm() {
        this.motifFormIsShown = false;
        this.selectedMotif = null;
        this.motifEtat = true
      },
  
      confirmTag(selectedMotif: Category) {
        this.$bvModal.show(this.motifId);
        this.selectedMotif = selectedMotif;
      },

      del() {
        this.deleteContactMotif(this.selectedMotif);
      },
    },
    beforeMount() {
      this.motifId = Math.random().toString();
    },
  });
  