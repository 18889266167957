import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { MediathequesState } from './types';
import { Mediatheque } from '@/models/mediatheque/mediatheque';

export const getters: GetterTree<MediathequesState, RootState> = {
  getMediatheques(state): Array<Mediatheque> {
    return state.mediatheques;
  },
};
