
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import { textTransform } from '@/mixins/mixins';
export default Vue.extend({
  props: ['statutDateAutorData'],
  name: 'FileInput',
  components: {
    InputFormGroupText,
    SelectFormGroup,
  },
  data() {
    return {
      typeOptions: [
        { value: 'article', text: 'Article' },
        { value: 'procedure', text: 'Procedure' },
        { value: 'annonce', text: 'Annonce' },
        { value: 'evenement', text: 'Evenement' },
      ],
      statutOptions: [
        { value: 'publié', text: 'Publié' },
        { value: 'archivé', text: 'Archivé' },
        { value: 'brouillon', text: 'Brouillon' },
      ],
    };
  },
  methods: {
    dateFromString(date: string) {
      return textTransform.methods.dateFromString(date);
    },
  },
});
