import { textTransform } from '@/mixins/mixins';
import { mapGetters } from 'vuex';
import { Media } from '@/models/media/media';
import { mediaMixin } from '@/mixins/media.mixin';

export const layoutMixin = {
  computed: {
    ...mapGetters('library', ['getDocumentCategories']),
    ...mapGetters('serviceCommunalCategories', [
      'getServicesCommunauxCategories',
      'getServicesCommunauxSousCategories'
    ]),
  },
  mixins: [mediaMixin],
  data() {
    return {
      sidebarItems: [
        {
          id: '/configuration-generale',
          link: '/configuration-generale', 
          titleMenu: 'Configuration générale',
          icon: 'display_settings',
          subMenus: [
            {
              id: '/configuration-generale/municipalite',
              header: 'Municipalité',
              title: 'Municipalité',
              link: '/configuration-generale/municipalite',
            },
            {
              id: '/configuration-generale/bibliotheque',
              header: 'Bibliothèque communale',
              title: 'Bibliothèque communale',
              link: '/configuration-generale/bibliotheque',
            },
            {
              id: '/configuration-generale/mediatheque',
              header: 'Médiathèque',
              title: 'Médiathèque',
              link: '/configuration-generale/mediatheque',
            },
          ],
        },
        {
          id: '/services-communaux',
          titleMenu: 'Services communaux',
          link: '/services-communaux',
          icon: 'location_city',
        },
        {
          id: '/content',
          link: '/content',
          titleMenu: 'Administration de contenu',
          icon: 'newspaper',
          subMenus: [
            {
              id: '/content/tags',
              header: 'Gestion des tags',
              title: 'Gestion des tags',
              link: '/content/tags',
            },
            {
              id: '/content/content-admin',
              header: 'Gestion de contenu',
              title: 'Gestion de contenu',
              link: '/content/content-admin',
            },
          ],
        },
        {
          id: '/page',
          link: '/page',
          titleMenu: 'Administration des pages',
          icon: 'description',
          subMenus: [
            {
              id: '/page/contact',
              header: 'Page Contactez-nous',
              title: 'Page Contactez-nous',
              link: '/page/contact',
            },
            {
              id: '/page/rendez-vous',
              header: 'Page Prise de rendez-vous',
              title: 'Page Prise de rendez-vous',
              link: '/page/rendez-vous',
            },
            {
              id: '/page/anomalie',
              header: 'Page Déclaration des anomalies',
              title: 'Page Déclaration des anomalies',
              link: '/page/anomalie',
            },
          ],
        }
      ],

      categoriesFields: [
        {
          key: 'name',
          label: 'Catégorie',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.name, 30);
          },
        },
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 30);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'created_at',
          label: 'Publication',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.dateFromString(item.created_at);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      tagsFields: [
        {
          key: 'name',
          label: 'Tag',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.name, 30);
          },
        },
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 30);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'created_at',
          label: 'Publication',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.dateFromString(item.created_at);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      motifsFields: [
        {
          key: 'motif',
          label: 'Motif',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.motif, 30);
          },
        },
        {
          key: 'service',
          label: 'Service de gestion',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.service, 30);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      subCategoriesFields: [
        {
          key: 'name',
          label: 'Sous-catégorie',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.name, 20);
          },
        },
        {
          key: 'parent_category_name',
          label: 'Catégorie',
          formatter: (value: any, key: any, item: any) => {
            return item.parent_category_name ? textTransform.methods.subString(
              item.parent_category_name, 20) : 
              (this as any).getSubCategoryParentName(item.navigation_path);
          },
        },
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 20);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'created_at',
          label: 'Publication',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.dateFromString(item.created_at);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      servicesFields: [
        {
          key: 'name',
          label: 'Service',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.name, 20);
          },
        },
        {
          key: 'tied_sub_category',
          label: 'Sous-catégorie',
          formatter: (value: any, key: any, item: any) => {
            return item.parent_category_name ? textTransform.methods.subString(
              item.tied_sub_category_name, 20) : 
              (this as any).getServiceParentName(item.navigation_path);
          },
        },
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 20);
          },
        },
        {
          key: 'status',
          label: 'Status',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.status, 20);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'created_at',
          label: 'Publication',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.dateFromString(item.created_at);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      libraryFields: [
        {
          key: 'doc_name',
          label: 'Document',
          formatter: (value: any, key: any, item: any) => {
            return item.children.length == 0 ? [] : (this as any).retrieveDocs(item.children[0].value.medias);
          },
        },
        {
          key: 'category',
          label: 'Rubrique',
          formatter: (value: any, key: any, item: any) => {
            return item.category_name ? textTransform.methods.subString(item.category_name, 30) : (this as any).getLibraryParentName(item.value.tag);
          },
        },
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 30);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'created_at',
          label: 'Publication',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.dateFromString(item.created_at);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      contentElementsFields: [
        {
          key: 'name',
          label: 'Elément de contenu',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.name, 20);
          },
        }, 
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 20);
          },
        },
        {
          key: 'type',
          label: 'Type',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(
              item.value.type[0].toUpperCase() + item.value.type.substring(1),
              20,
            );
          },
        },
        {
          key: 'status',
          label: 'Statut',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.status, 20);
          },
        },
        {
          key: 'created_by',
          label: 'Auteur',
        },
        {
          key: 'created_at',
          label: 'Publication',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.dateFromString(item.created_at);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],

      mediasFields: [
        {
          key: 'name',
          label: 'Nom',
          formatter: (value: any, key: any, item: any) => {
            return (this as any).getImageName(item);
          },
        },
        {
          key: 'desc',
          label: 'Description',
          formatter: (value: any, key: any, item: any) => {
            return textTransform.methods.subString(item.value.desc, 30);
          },
        },
        {
          key: 'media_image',
          label: 'Image',
          tdClass: 'image-column',
          formatter: (value: any, key: any, item: any) => {
            return (this as any).getUri(item);
          },
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          formatter: (value: any, key: any, item: any) => {
            return item;
          },
        },
      ],
    };
  },
  methods: {
    getLibraryParentName(id: any){
      const currentCategory = (this as any).getDocumentCategories!.find(
        (path: any) => path.node_id === id,
      );
      if(currentCategory)
       return currentCategory.value.name
      else return ''
    },
    getSubCategoryParentName(navigation_path: any){
      const path = navigation_path.split('/').slice(0,5)
      const my_path = path.join('/');
      const currentCategory = (this as any).getServicesCommunauxCategories!.find(
        (path: any) => path.navigation_path === my_path,
      );
      if(currentCategory)
       return currentCategory.value.name
      else return ''
    },
    getServiceParentName(navigation_path: any){
      const path = navigation_path.split('/').slice(0,6)
      const my_path = path.join('/');
      const currentCategory = (this as any).getServicesCommunauxSousCategories!.find(
        (path: any) => path.navigation_path === my_path,
      );
      if(currentCategory)
       return currentCategory.value.name
      else return ''
    },
    retrieveDocs(medias: Media[]) {
      const table = (this as any).retrieveMediaByTags(
        ['document'],
        medias,
      );
      if(table.length !== 0)
       return table[0].display_name
      else
       return 'Document'
    },
    getUri(image: any) : string | null | undefined {
      if (image?.children != undefined) {
        if (image?.children.length != 0)
          return image.children[0].value.medias[0].uri;
        return null;
      }
    },
    getImageName(image: any) : string | null | undefined {
      if (image?.children != undefined) {
        if (image?.children.length != 0)
          return image.children[0].value.medias[0].display_name;
        return null;
      }
    },
  },
};
