
  import { Vue } from 'vue-property-decorator';
  import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
  import { mapActions, mapGetters } from 'vuex';
  import { NodeType } from '@/models/enums/enums';
  import { build } from '@/mixins/build.mixin';
  export default Vue.extend({
    props: ['injectedAnomalie'],
    components: {
      InputFormGroupText,
    },
    mixins: [build],
    data() {
      return {
        node_id: '',
        anomalie: this.injectedAnomalie,
        lockUpdate: true,
        validate: {
          motif: '',
          service: ''
        },
      };
    },
    created: function () {
      this.$watch('anomalie', this.categoryUpdated, {
        deep: true,
      });
    },
    computed: {
      haveDefaultData(): boolean {
        return !!this.anomalie;
      },
  
      creating(): boolean {
        return !!this.injectedAnomalie;
      },
    },
    methods: {
      ...mapActions('dataNode', ['createNode', 'updateNode']),
      ...mapActions('pagesAdmin', ['addMotif', 'updateMotif']),
  
      categoryUpdated() {
        this.lockUpdate = false;
      },
      init() {
        if (!this.haveDefaultData) {
          this.anomalie = { value: { motif: '', service: '', slug: '' } };
        }
      },
      cancel() {
        this.$emit('cancel');
      },
      validation(): boolean{
        let validationState = true
        if(this.anomalie.value.motif === '') {
          this.validate.motif = 'false'
          validationState = false
        }else{
          this.validate.motif = ''
        }
        if(this.anomalie.value.service === '') {
          this.validate.service = 'false'
          validationState = false
        }else{
          this.validate.service = ''
        }
        return validationState
      },
      save() {
        const motif = this.anomalie.value.motif.toLowerCase();
        const defineName = motif.replace(/\s/g, '_');
        const nameCleaned = defineName.replace(/[^a-zA-Z0-9_-]/g,'');
  
        if (this.validation() === true) {
          this.anomalie.value.slug = (this as any).slugify(this.anomalie.value.motif)
          const node = (this as any).buildCreatePayloadWithName(
            this.$data.required_nodes,
            'motifs',
            NodeType.content,
            this.anomalie.value,
            nameCleaned,
          );
          this.addMotif(node).then(() => {
            this.validation();
            this.$emit('cancel');
          });
        } else {
          this.$toast.error('Veuillez remplir les champs');
        }
      },
      update() {
        if (this.validation() === true) {
          this.anomalie.value.slug = (this as any).slugify(this.anomalie.value.motif)
          this.updateMotif(this.anomalie).then(() => {
            this.validation();
            this.$emit('cancel');
          });
        } else {
          this.$toast.error('Veuillez remplir les champs');
        }
      },
    },
    beforeMount() {
      this.init();
    },
    watch: {
      category() {
        this.init();
      },
    },
  });
  