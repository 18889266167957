
import { Vue } from 'vue-property-decorator';
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import TitleSection from './title-section/title-section.vue';
import ContentElementMetadata from '@/components/forms/content-element-metadata/content-element-metadata.vue';
import ModuleRdv from '@/components/forms/module-rdv/module-rdv.vue';
import { Media } from '@/models/media/media';
import { build } from '@/mixins/build.mixin';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import DocumentCard from '@/components/inputs/document-card/document-card.vue';
import { mediaMixin } from '@/mixins/media.mixin';
import { rdvModule } from '@/models/rdv-module/rdv-module';
import moment from 'moment'
export default Vue.extend({
  components: {
    Tab,
    TabItem,
    TitleSection,
    ContentElementMetadata,
    DocumentCard,
    ImageCard,
    ModuleRdv,
  },
  mixins: [build, mediaMixin],
  props: ['service', 'categories'],
  data() {
    return {
      tabIndex: 0,
      titleSectionData: null as any,
      module_rdv: [] as Array<rdvModule>,
      statutDateAutorData: {
        type: 'procedure',
        status: 'brouillon',
        created_by: '',
        created_at: '',
        updated_at: '',
        published_at: '',
      },
      validate: {
        tied_sub_category: '',
        name: '',
        desc: '',
        popular: null as any
      },
      imageTags: ['image', 'profile'],
      documentTags: ['document'],

      medias: [] as Media[],
    };
  },
  methods: {
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    deleteAudio(audio: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.media_id !== audio.media_id,
      );
    },
    updateMedias(media: Media[], isSingle: boolean) {
      this.medias = (this as any).updateImagesByTags(this.medias, media, isSingle);
    },

    updateDocuments(documents: Media[]) {
      this.medias = this.medias.concat(documents);
    },

    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },
    
    retrieveDocs() {
      return (this as any).retrieveMediaByTags(
        ['document'],
        this.medias,
      );
    },

    cancel(){
      this.$router.push('/services-communaux');
    },
    validation(): boolean{
      let validationState = true
      if(this.titleSectionData.tied_sub_category === undefined || this.titleSectionData.tied_sub_category === '' || this.titleSectionData.tied_sub_category === null) {
        this.validate.tied_sub_category = 'false'
        validationState = false
      }else{
        this.validate.tied_sub_category = ''
      }
      if(this.titleSectionData.name === '') {
        this.validate.name = 'false'
        validationState = false
      }else{
        this.validate.name = ''
      }
      if(this.titleSectionData.desc === '') {
        this.validate.desc = 'false'
        validationState = false
      }else{
        this.validate.desc = ''
      }
      return validationState
    },
    publish() {
      if (this.validation() === true) {
        let serviceFormData = this.titleSectionData;
        serviceFormData['type'] = this.statutDateAutorData.type;
        serviceFormData['status'] = 'publié';
        this.$emit('save', { data: serviceFormData, medias: this.medias, module_rdv: this.module_rdv });
        this.validation();
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if (this.validation() === true) {
        let serviceFormData = this.titleSectionData;
        serviceFormData['type'] = this.statutDateAutorData.type;
        serviceFormData['updated_at'] = moment().format();
        serviceFormData['status'] = 'publié';
        this.$emit('save', {
          data: serviceFormData,
          medias: this.medias,
          module_rdv: this.module_rdv
        });
        this.validation();
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    save() {
      if (this.validation() === true) {
        let serviceFormData = this.titleSectionData;
        serviceFormData['type'] = this.statutDateAutorData.type;
        serviceFormData['status'] = 'brouillon';
        this.$emit('save', { data: serviceFormData, media: this.medias, module_rdv: this.module_rdv });
        this.validation();
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
  },
  watch: {
    service(newVal) {
      if (newVal !== null && typeof newVal === 'object') {
        if(newVal.value){
          this.module_rdv = newVal.value.module_rdv;
        }
        this.medias = newVal.children[0].value.medias;
        this.statutDateAutorData.status = newVal.value.status;
        this.statutDateAutorData.type = newVal.value.type;
        this.statutDateAutorData.created_by = newVal.created_by;
        this.statutDateAutorData.created_at = newVal.created_at;
        this.statutDateAutorData.updated_at = newVal.updated_at == null ? newVal.value.updated_at : newVal.updated_at;
        this.statutDateAutorData.published_at = newVal.value.published_at;
      }
    },
  },
  beforeMount() {
    if(this.service.children){
      if (this.service.children[0].value.medias != undefined) {
        this.medias = this.service.children[0].value.medias;
      }
    }
    if(this.service.value){
      this.module_rdv = this.service.value.module_rdv;
    }
    this.statutDateAutorData.status = this.service.value.status;
    this.statutDateAutorData.type = this.service.value.type;
    this.statutDateAutorData.created_by = this.service.created_by;
    this.statutDateAutorData.created_at = this.service.created_at;
    this.statutDateAutorData.updated_at = this.service.updated_at == null ? this.service.value.updated_at : this.service.updated_at;
    this.statutDateAutorData.published_at = this.service.value.published_at;
  },
});
