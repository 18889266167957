
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'TitleSection',
  props: ['service', 'categories', 'validate'],
  components: {
    InputFormGroupText,
    SelectFormGroup,
  },
  data() {
    return {
      catOptions: null as any,
      subCatOptions: [
        {
          value: null,
          text: 'Veillez selectionner la sous-categorie',
          parent: null,
        },
      ],
      form: {
        name: '',
        desc: '',
        tied_sub_category: '', 
        popular: false
      },
      formEtats: [
        { id: 1, text: "Choisir l'etat du service", value: null },
        { id: 2, text: 'Populaire', value: true },
        { id: 3, text: 'Non populaire', value: false }
      ],
      parent_category: '',
      categoriesOptions: [] as any,
    };
  },
  computed: {
    ...mapGetters('serviceCommunalCategories', [
      'getServicesCommunauxCategories',
      'getServicesCommunauxSousCategories'
    ]),
  },
  methods: {
    sendChanges() {
      this.$emit('input', this.form);
    },
    changeCategorie() {
      this.subCatOptions = this.catOptions.filter(
        (element: any) => element.parent == this.parent_category,
      );
    },
    getServiceParentName(navigation_path: any){
      const path = navigation_path.split('/').slice(0,6)
      const my_path = path.join('/');
      if(my_path)
       return my_path
      else return ''
    },
    getServiceParent(navigation_path: any){
      const path = navigation_path.split('/').slice(0,5)
      
      return path[4]
    },
  },
  beforeMount() {
    this.categoriesOptions = [];
    this.categoriesOptions.push({
      value: null,
      text: 'Veillez selectioner une categorie',
    });
    this.getServicesCommunauxCategories.forEach((element: any) => {
      this.categoriesOptions.push({
        value: element.name,
        text: element.value.name,
      });
    });
    this.catOptions = [
      {
        value: null,
        text: 'Veillez selectionner la sous-categorie',
        parent: null,
      },
    ];
    if (this.categories.length) {
      this.categories.map((category: any) => {
        if(category.parent_category === undefined){
          this.catOptions.push({
            value: category.navigation_path,
            text: category.value.name,
            parent: this.getServiceParent(category.navigation_path),
          });
        }else{
          this.catOptions.push({
            value: category.navigation_path,
            text: category.value.name,
            parent: category.parent_category,
          });
        }
      });

    }
    this.form.name = this.service.value.name;
    this.form.desc = this.service.value.desc;
    this.form.popular = this.service.value.popular;
    this.subCatOptions = this.catOptions;

    if(this.service.tied_sub_category){
      const parent = this.catOptions.find(
        (element: any) => element.value == this.service.tied_sub_category,
      );
      this.parent_category = parent.parent;
    }
    if(this.service.navigation_path){
      const parent = this.catOptions.find(
        (element: any) => element.value == this.getServiceParentName(this.service.navigation_path),
      );
      this.parent_category = parent.parent;
      this.form.tied_sub_category = this.getServiceParentName(this.service.navigation_path);
    }
  },
});
