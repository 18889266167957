
import { Vue } from 'vue-property-decorator';
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-2';
import TiptapBtn from './tiptapBtn/tiptapBtn.vue';
import StarterKit from '@tiptap/starter-kit';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import OrderedList from '@tiptap/extension-ordered-list';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';

export default Vue.extend({
  props: ['editable', 'content'],
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    TiptapBtn,
  },
  data() {
    return {
      centeredIsActive: false,
      editor: new Editor({
        onUpdate: ({ editor }) => {
          this.$emit('input', {
            html: editor.getHTML(),
            text: editor.getText(),
          });
        },
        content: '',
        extensions: [
          StarterKit,
          OrderedList,
          Underline,
          Paragraph.configure({
            HTMLAttributes: {
              class: 'tiptap-paragraph',
            },
          }),
          Heading.configure({
            HTMLAttributes: {
              class: 'tiptap-header',
            },
          }),
          Text.configure({
            HTMLAttributes: {
              class: 'tiptap-text',
            },
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
        ],
      }),
    };
  },
  watch: {
    editable() {
      this.editor.setEditable(!this.editor.isEditable);
    },
  },
  methods: {},
  mounted() {
    this.editor.commands.insertContent(this.content);
    this.editor.setEditable(this.editable);
  },
  beforeDestroy() {
    this.editor.destroy();
  },
});
