import { Tokens } from '@/plugins/shield/models/token';
import {
  Access,
  Address,
  User,
  UserDetails,
  UserView,
} from '@/plugins/shield/models/user';
import utils from '@/plugins/shield/utils/utils';
import store from '@/store';

export class Context {
  private contextTokens: Tokens;

  constructor(tokens: any) {
    this.contextTokens = tokens;
    store.commit('setUser', this.getUser());
  }

  getExpiresIn(): number {
    return this.contextTokens.expires_in;
  }

  getContext(): Tokens {
    return this.contextTokens;
  }

  get userAccess(): Access | undefined {
    if (!this.isAccessTokenPresent()) {
      return undefined;
    }

    const parsedToken = utils.parseJWT(this.contextTokens.token);

    return {
      roles: parsedToken.realm_access,
      resource_access: parsedToken.resource_access,
      access_token: this.contextTokens.token,
      refresh_token: this.contextTokens.refreshToken
    };
  }

  getUser(): User | undefined {
    if (!this.isIdTokenPresent()) {
      return undefined;
    }
    const parsedToken = utils.parseJWT(this.contextTokens.idToken);

    const userView: UserView = {
      username: parsedToken.preferred_username,
      email: parsedToken.email,
      tenant: parsedToken.tenant,
    };

    const address: Address = parsedToken.address;

    const userDetails: UserDetails = {
      first_name: parsedToken.given_name,
      last_name: parsedToken.family_name,
      phone: parsedToken.phone_number,
      picture: parsedToken.picture,
      address: address,
      groups: parsedToken.groups,
    };
    
    return {
      username: parsedToken.preferred_username,
      email: parsedToken.email,
      tenant: parsedToken.tenant,
      details: userDetails,
      access: this.userAccess,
    };
  }

  isIdTokenPresent(): boolean {
    return (
      this.contextTokens.idToken !== undefined
    );
  }

  isAccessTokenPresent(): boolean {
    return this.contextTokens.token !== null && this.contextTokens.token !== '';
  }
}