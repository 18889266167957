
import { mapActions, mapGetters } from 'vuex';
import { Vue } from 'vue-property-decorator';
import Table from '@/components/table/table.vue';
import MediaForm from '@/components/forms/media-form/media-form.vue';
import Section from '@/components/section/section.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import { Media } from '@/models/media/media';
import { layoutMixin } from '@/mixins/layout.mixin';
export default Vue.extend({
  name: 'Médiathèque',
  components: {
    Table,
    MediaForm,
    Section,
    ConfirmModal
  },
  mixins: [layoutMixin],
  data() {
    return {
      mediasFilterOptions: [
        { value: null, text: 'Veuillez selectionner le filtre' },
        { value: 'name', text: 'Nom' },
        { value: 'desc', text: 'Description' },
      ],
      selectedMedia: null as any,
      mediaId: '',
      mediaFormIsShown: false,
      mediaEtat: true,
    }
  },
  computed: {
    ...mapGetters('mediatheques', ['getMediatheques']),
  },
  methods: {
    ...mapActions('mediatheques', ['deleteMediatheque']),
    showMediaForm() {
      this.mediaFormIsShown = true;
      this.mediaEtat = false
    },
    confirmMedia(selectedMedia: Media) {
      const nodeId = selectedMedia.node_id;
      if (nodeId) {
        this.mediaId = nodeId;
        this.$bvModal.show(this.mediaId);
        this.selectedMedia = selectedMedia;
      }
    },
    deleteMedia() {
      this.deleteMediatheque(this.selectedMedia);
      this.mediaFormIsShown = false;
      this.selectedMedia = null;
      this.mediaEtat = true
    },
    cancelMediaForm() {
      this.mediaFormIsShown = false;
      this.selectedMedia = null;
      this.mediaEtat = true
    },
  }
})
