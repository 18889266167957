import { ActionTree } from 'vuex';
import { SlidesState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { nodeMixin } from '@/mixins/node.mixin';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import Vue from 'vue';

export const actions: ActionTree<SlidesState, RootState> = {
  loadSlides(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/infos_generales/slider',
      tree,
    );

    if (parent) context.commit('setSlides', parent.children);
  },
  addSlider(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addSlider', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },
  deleteSlider(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeSlider', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  updateSlider(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateSlide', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
};
