import { DataNodeCreateDto, DataNodeUpdateDto } from '@/dto/data-node-dto';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import {
  AppointmentScheduleTemplate,
  BusinessCategoryModel,
  BusinessPurpose,
} from '@/models/schedule-manager/schedule-manager';
import { BvToast } from 'bootstrap-vue';

export const staticVars = {
  data() {
    return {
      REQUIRED_DATA_NODES: [
        {
          name: 'mairie',
          display_name: 'Mairie',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie',
        },
        {
          name: 'municipalite',
          display_name: 'Municipalité',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite',
        },
        {
          name: 'executif',
          display_name: 'Executif',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/executif',
        },
        {
          name: 'officiers_etat',
          display_name: "Officiers d'état",
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/officiers_etat',
        },
        {
          name: 'commissions',
          display_name: 'Commissions',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/commissions',
        },
        {
          name: 'conseil_municipal',
          display_name: 'Conseil municipal',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/conseil_municipal',
        },
        {
          name: 'conseillers_municipaux',
          display_name: 'Conseillers municipaux',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/municipalite/conseillers_municipaux',
        },
        {
          name: 'services_communaux',
          display_name: 'Services Communaux',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/services_communaux',
        },
        {
          name: 'categories',
          display_name: 'Categories',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/services_communaux/categories',
        },
        {
          name: 'bibliotheque',
          display_name: 'Bibliotheque',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/bibliotheque',
        },
        {
          name: 'bibliotheque_medias',
          display_name: 'Medias',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/bibliotheque/bibliotheque_medias',
        },
        {
          name: 'bibliotheque_categories',
          display_name: 'Categories',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/bibliotheque/bibliotheque_categories',
        },
        {
          name: 'infos_generales',
          display_name: 'Informations Generales',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales',
        },
        {
          name: 'slider',
          display_name: 'Slider',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/slider',
        },
        {
          name: 'partners',
          display_name: 'Partners',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/partners',
        },
        {
          name: 'editorial',
          display_name: 'editorial',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/editorial',
        },
        {
          name: 'tags',
          display_name: 'tags',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/tags',
        },
        {
          name: 'motifs',
          display_name: 'motifs',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/motifs',
        },
        {
          name: 'contact_motifs',
          display_name: 'contact_motifs',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/contact_motifs',
        },
        {
          name: 'rdv_motifs',
          display_name: 'rdv_motifs',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/rdv_motifs',
        },
        {
          name: 'organigramme',
          display_name: 'organigramme',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/organigramme',
        },
        {
          name: 'reseaux',
          display_name: 'reseaux',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/reseaux',
        },
        {
          name: 'localisation',
          display_name: 'localisation',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/localisation',
        },
        {
          name: 'configuration',
          display_name: 'configuration',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/infos_generales/configuration',
        },
        {
          name: 'mediatheque',
          display_name: 'mediatheque',
          node_type: NodeType.content,
          value: {},
          navigation_path: '/mairie/mediatheque',
        },
      ] as Array<Node>,
    };
  },
  methods: {
    checkIfNodesExist(concernedNode: Node, allNodes: Array<any>): boolean {
      let isInAllNodes = false;
      isInAllNodes =
        allNodes.filter(
          (node) => node.navigation_path == concernedNode.navigation_path,
        ).length > 0;

      return isInAllNodes;
    },
  },
};

export const bvToast = {
  methods: {
    showToast(bvToast: BvToast, type: string, title: string, message: string) {
      return new Promise((resole) => {
        bvToast.toast(message, {
          title: title,
          variant: type,
          solid: true,
        });
        setTimeout(() => {
          resole(true);
        }, 2500);
      });
    },
  },
};

export const textTransform = {
  methods: {
    dateFromString(date: string) {
      if (!date?.length) {
        return '-';
      }
      let res: any = new Date(date);
      let month = Number.parseInt(res.getMonth() + 1).toString();
      let day = Number.parseInt(res.getDate()).toString();
      month = month.length > 1 ? month : '0' + month;
      day = day.length > 1 ? day : '0' + day;
      res = day + '-' + month + '-' + res.getFullYear();
      return res;
    },
    subString(text: string, limit: number): string {
      if (text)
        return text!.length < limit ? text : text.substring(0, limit) + ' ...';
      return '';
    },
  },
};

export const extractNode = {
  methods: {
    extractMediaNode(data: Array<Node>, node_name: string): Array<Media> {
      const mediaNode: Node | undefined = data.find(
        (node) => node.name === node_name,
      );
      let media: Array<Media> = [];
      if (mediaNode)
        media = mediaNode.children!.map((imageNode) => {
          imageNode.value.node_id = imageNode.node_id;
          return imageNode.value;
        });
      return media;
    },
    extractDataNode(data: Array<Node>, node_name: string): Array<Node> {
      const dataNode: Node | undefined = data.find(
        (node) => node.name == node_name,
      );
      if (dataNode) return dataNode.children!;
      else return [];
    },
  },
};

export const constructDataNodeCreateDto = {
  methods: {
    constructMedia(
      dataNodeCreateDto: DataNodeCreateDto,
      medias: Array<Media>,
      navigation_path: string,
      parentNodeName: string,
      name: string,
    ) {
      const mediaNodes: Array<Node> = [];
      mediaNodes.push({
        name: parentNodeName.toLowerCase(),
        node_type: NodeType.content,
        display_name: parentNodeName,
        navigation_path: navigation_path + '/' + parentNodeName.toLowerCase(),
        value: {},
      });

      medias?.forEach((media, i: number) => {
        const mediaName = name.toLowerCase() + '_' + (i + 1);
        mediaNodes.push({
          name: mediaName,
          node_type: NodeType.media,
          display_name: name + ' ' + (i + 1),
          navigation_path:
            navigation_path +
            '/' +
            parentNodeName.toLowerCase() +
            '/' +
            mediaName,
          value: media,
        });
      });
      mediaNodes.forEach((mediaNode) => {
        dataNodeCreateDto.contents_requests.push(mediaNode);
      });
    },
  },
};

export const constructDataNodeUpdateDto = {
  methods: {
    constructMedia(
      dataNodeUpdateDto: Array<DataNodeUpdateDto>,
      dataNodeCreateDto: DataNodeCreateDto,
      medias: Array<Media>,
      navigation_path: string,
      parentNodeName: string,
      name: string,
    ) {
      medias?.forEach((media, i: number) => {
        const mediaName = name.toLowerCase() + '_' + (i + 1);
        if (media.node_id) {
          dataNodeUpdateDto.push({
            node_id: media.node_id,
            name: mediaName,
            node_type: NodeType.media,
            display_name: name + ' ' + (i + 1),
            navigation_path:
              navigation_path +
              '/' +
              parentNodeName.toLowerCase() +
              '/' +
              mediaName,
            value: media,
          });
        } else {
          dataNodeCreateDto.contents_requests.push({
            name: mediaName,
            node_type: NodeType.media,
            display_name: name + ' ' + (i + 1),
            navigation_path:
              navigation_path +
              '/' +
              parentNodeName.toLowerCase() +
              '/' +
              mediaName,
            value: media,
          });
        }
      });
    },
    constructOldDto(dataNodeUpdateDto: Array<DataNodeUpdateDto>, data: Node) {
      dataNodeUpdateDto.push({
        node_id: data.node_id!,
        name: data.name,
        node_type: NodeType.content,
        display_name: data.display_name,
        navigation_path: data.navigation_path,
        value: data.value,
      });
      data.imgs?.forEach((media, i: number) => {
        const mediaName = 'image_' + (i + 1);
        dataNodeUpdateDto.push({
          node_id: media.node_id,
          name: mediaName,
          node_type: NodeType.media,
          display_name: 'Image ' + (i + 1),
          navigation_path: data.navigation_path + '/images/' + mediaName,
          value: media,
        });
      });
      data.docs?.forEach((media, i: number) => {
        const mediaName = 'document_' + (i + 1);
        dataNodeUpdateDto.push({
          node_id: media.node_id,
          name: mediaName,
          node_type: NodeType.media,
          display_name: 'Document ' + (i + 1),
          navigation_path: data.navigation_path + '/documents/' + mediaName,
          value: media,
        });
      });
      data.galery?.forEach((media, i: number) => {
        const mediaName = 'image_' + (i + 1);
        dataNodeUpdateDto.push({
          node_id: media.node_id,
          name: mediaName,
          node_type: NodeType.media,
          display_name: 'Image ' + (i + 1),
          navigation_path: data.navigation_path + '/galery/' + mediaName,
          value: media,
        });
      });
      data.carousel?.forEach((media, i: number) => {
        const mediaName = 'image_' + (i + 1);
        dataNodeUpdateDto.push({
          node_id: media.node_id,
          name: mediaName,
          node_type: NodeType.media,
          display_name: 'Image ' + (i + 1),
          navigation_path: data.navigation_path + '/carousel/' + mediaName,
          value: media,
        });
      });
    },
    isObject(object: any) {
      return object != null && typeof object === 'object';
    },
    deepEqual(arrayToInspect: any, valuesToExclude: any) {
      const keys1 = Object.keys(arrayToInspect);
      const keys2 = Object.keys(valuesToExclude);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (const key of keys1) {
        const val1 = arrayToInspect[key];
        const val2 = valuesToExclude[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          (areObjects && !this.deepEqual(val1, val2)) ||
          (!areObjects && val1 !== val2)
        ) {
          return false;
        }
      }
      return true;
    },
    compareData(
      arrayToInspect: Array<DataNodeUpdateDto>,
      valuesToExclude: Array<DataNodeUpdateDto>,
      haveToUpdateDto: Array<DataNodeUpdateDto>,
      haveToDeleteDto: Array<string>,
    ) {
      let i = 0;
      const concernedData: Array<DataNodeUpdateDto> = [];
      arrayToInspect.forEach((element) => {
        valuesToExclude.forEach((valueToExclude) => {
          if (element.node_id == valueToExclude.node_id)
            concernedData.push(valueToExclude);
        });
      });

      arrayToInspect.forEach((element, j) => {
        if (!this.deepEqual(element, concernedData[j]))
          haveToUpdateDto.push(element);
        i = j;
      });

      for (let k = valuesToExclude.length - 1; k > i; k--) {
        haveToDeleteDto.push(valuesToExclude[k].node_id);
      }
    },
  },
};

export const selectBoxManagment = {
  methods: {
    businessSelectBox(
      payload: Array<BusinessPurpose> | undefined,
    ): Array<{ value: string; text: string }> {
      let buisnessSelectBox: { value: string; text: string }[] = [
        { value: '', text: 'sélectionner un thème' },
      ];
      buisnessSelectBox = [{ value: '', text: 'sélectionner un thème' }];
      payload?.forEach((element) => {
        buisnessSelectBox.push({
          value: element.purpose_id,
          text: element.name,
        });
      });
      return buisnessSelectBox;
    },
    categorySelectBox(
      payload: Array<BusinessCategoryModel> | undefined,
    ): Array<{ value: string; text: string }> {
      let categorySelectBox: { value: string; text: string }[] = [
        { value: '', text: 'sélectionner une catégorie' },
      ];
      categorySelectBox = [{ value: '', text: 'sélectionner un catégorie' }];
      payload?.forEach((element) => {
        categorySelectBox.push({
          value: element.category_id,
          text: element.name,
        });
      });
      return categorySelectBox;
    },
    scheduleTemplateSelectBox(
      payload: Array<AppointmentScheduleTemplate> | undefined,
    ): Array<{ value: string; text: string }> {
      let scheduleTemplateSelectBox: { value: string; text: string }[] = [
        { value: '', text: 'sélectionner un template' },
      ];
      scheduleTemplateSelectBox = [
        { value: '', text: 'sélectionner un template' },
      ];
      payload?.forEach((element) => {
        scheduleTemplateSelectBox.push({
          value: element.template_id,
          text: element.metadata.title,
        });
      });
      return scheduleTemplateSelectBox;
    },
  },
};
