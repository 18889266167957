import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ReseauxState } from './types';
import { Reseaux } from '@/models/reseaux/reseaux';

export const getters: GetterTree<ReseauxState, RootState> = {
  getReseaux(state): Reseaux | null {
    return state.reseaux;
  },
  getMounted(state): boolean {
    return state.value;
  },
};
