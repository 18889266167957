import { MutationTree } from 'vuex';
import { AdminState } from './types';

export const mutations: MutationTree<AdminState> = {
  setSortBy(state, payload: string) {
    state.sortBy = payload;
  },

  setPageTitle(state, payload: string) {
    state.pageTitle = payload;
  },
};
