import { ActionTree } from 'vuex';
import { NodeState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { staticVars } from '@/mixins/mixins';
import {
  DataNodeCreateDto,
  DataNodeSearchDto,
  DataNodeUpdateDto,
} from '@/dto/data-node-dto';

export const actions: ActionTree<NodeState, RootState> = {
  async fetchAllTree(context) {
    const dataNodeSearchDto: DataNodeSearchDto = {
      navigation_path: '/',
      distance: 'all',
    };
      await NodeService.getInstance(this.getters.getUser.access.access_token)
      .search(dataNodeSearchDto)
      .then((value) => {
        if (value.data) {
          context.commit('setTree', value.data);
        }
        if (value.apiError) {
          if (value.apiError.error_code == 2001) {
            context.dispatch('createRequiredNodes');
          }
        }
      });
  },

  createRequiredNodes(context) {
    const payload: DataNodeCreateDto = {
      contents_requests: staticVars.data().REQUIRED_DATA_NODES,
    };
    NodeService.getInstance('')
      .create(payload)
      .then((value) => {
        if (value.data) {
          context.commit('setTree', value.data);
        }
        if (value.apiError) {
          console.log(value.apiError);
        }
      });
  },

  createNode(context, payload: DataNodeCreateDto) {
    if (payload.contents_requests.length)
      return NodeService.getInstance(this.getters.getUser.access.access_token).create(payload);
    return;
  },

  updateNode(context, payload: DataNodeUpdateDto) {
    return NodeService.getInstance('').update(payload);
  },

  updateNodes(context, payload: Array<DataNodeUpdateDto>) {
    return new Promise((resolve, reject) => {
      if (payload.length)
        payload.forEach((dto) => {
          return context
            .dispatch('updateNode', dto)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      else resolve(true);
    });
  },

  deleteNode(context, node_id) {
    return NodeService.getInstance(this.getters.getUser.access.access_token).delete(node_id);
  },

  deleteNodes(context, payload: Array<string>) {
    return new Promise((resolve, reject) => {
      if (payload.length)
        payload.forEach((node_id) => {
          return context
            .dispatch('deleteNode', node_id)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      else resolve(true);
    });
  },
};
