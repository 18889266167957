import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/state-officer/getters';
import { actions } from '@/store/modules/state-officer/action';
import { mutations } from '@/store/modules/state-officer/mutations';
import { StateOfficerState } from './types';

export const state: StateOfficerState = {
  stateOfficers: [],
  stateOfficerMedias: [],
};

export const stateOfficer: Module<StateOfficerState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
