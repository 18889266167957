
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  props: ['disabled', 'icon', 'text', 'isActive', 'id'],
  data() {
    return {
      link: '',
      linkTtype: 'link',
      modalShow: false,
    };
  },
  methods: {
    addLink() {
      if (this.linkTtype == 'link') {
        this.$emit('addLink', this.link);
        this.modalShow = false;
      } else if (this.linkTtype == 'email') {
        this.link = 'mailto: ' + this.link;
        this.$emit('addLink', this.link);
        this.modalShow = false;
      }
    },
  },
});
