
import { Vue } from 'vue-property-decorator';
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-2';
import TiptapBtn from './tiptapBtn/tiptapBtn.vue';
import TiptapLinkBtn from './tiptapLinkBtn/tiptapBtn.vue';
import StarterKit from '@tiptap/starter-kit';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import { ImageWidth } from '@/components/edition-components/tiptap/components/image/image-width';
import OrderedList from '@tiptap/extension-ordered-list';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import Link from '@tiptap/extension-link';
import { CreateMediaDto } from '@/dto/media-dto';
import { Media } from '@/models/media/media';
import { mapActions } from 'vuex';

const CustomImage = Image.extend({
  renderHTML({ HTMLAttributes }) {
    const container = document.createElement('div');
    container.style.width = '100%';
    container.style.display = 'flex';
    container.style.justifyContent = 'center';

    const img = document.createElement('img');
    if (HTMLAttributes.width == 'full') img.style.width = '100%';
    else if (HTMLAttributes.width == 'contain') img.style.width = '70%';
    else img.style.width = '100%';
    img.src = HTMLAttributes.src;
    container.appendChild(img);

    return container;
  },
});

export default Vue.extend({
  props: ['editable', 'content'],
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    TiptapBtn,
    TiptapLinkBtn,
  },
  data() {
    return {
      centeredIsActive: false,
      editor: new Editor({
        onUpdate: ({ editor }) => {
          this.$emit('input', {
            html: editor.getHTML(),
            text: editor.getText(),
          });
        },
        content: '',
        extensions: [
          StarterKit,
          OrderedList,
          Underline,
          Paragraph.configure({
            HTMLAttributes: {
              class: 'tiptap-paragraph',
            },
          }),
          Text.configure({
            HTMLAttributes: {
              class: 'tiptap-text',
            },
          }),
          CustomImage.configure({
            HTMLAttributes: {
              class: 'tiptap-img',
              width: 'full'
            },
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          ImageWidth.configure({
            types: ['image'],
          }),
          Heading.configure({
            HTMLAttributes: {
              class: 'tiptap-header',
            },
          }),
          Link.configure({
            openOnClick: true,
          }),
        ],
      }),
    };
  },
  watch: {
    editable() {
      this.editor.setEditable(!this.editor.isEditable);
    },
    content() {
      this.editor.commands.insertContent(this.content);
    },
  },
  methods: {
    ...mapActions('media', ['createMedia']),

    addImage() {
      let file = document.createElement('input');
      file.type = 'file';
      file.accept = 'image/*';
      file.click();

      file.onchange = () => {
        if (file.files) {
          const medias = [];
          medias.push(file.files[0]);
          const data: CreateMediaDto = {
            file_name: file.files[0].name,
            tags: ['image'],
            medias: medias,
          };
          this.createMedia(data).then((res) => {
            const newMedia = res as Media[];
            this.editor
              .chain()
              .focus()
              .setImage({ src: newMedia[0].uri, title: '' })
              .run();
          });
        }
      };
    },
    addLink(link: string) {
      if (link.length == 0) return;
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: link })
        .run();
    },
  },
  mounted() {
    this.editor.setEditable(this.editable);
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  beforeMount() {
    this.editor.setEditable(!this.editor.isEditable);
    this.editor.commands.insertContent(this.content);
  },
});
