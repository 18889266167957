
import { Vue } from 'vue-property-decorator';
import { Media } from '@/models/media/media';
import ActionButton from '@/components/buttons/action-button/action-button.vue';
import InputDocument from '@/components/inputs/input-document/input-document.vue';
export default Vue.extend({
  name: 'DocumentCard',
  components: {
    InputDocument,
    ActionButton
  },
  props: [
    'id',
    'inputTitle',
    'defaultDocs',
    'isSingle',
    'inputDisabled',
    'disableCard',
    'disableFontWeigth',
    'disableColor',
    'tags',
  ],

  data() {
    return {
      docChoosen: null as any,
      docName: null as any,
      display_name: null as any,
      createdDocs: [] as any,
      documentsUploaded: this.defaultDocs,
    };
  },

  methods: {
    init() {
      if (this.defaultDocs) this.createdDocs = this.defaultDocs;
    },
    deleteDocument() {
      this.docName = null;
      this.$emit('deleteImage', this.documentsUploaded[0]);
    },
    updateDocuments(medias: Media[]) {
      this.docName = medias[0]!.file_name;
      this.display_name = medias[0]!.display_name;
      this.documentsUploaded = medias;
    },
  },

  watch: {
    docChoosen() {
      if (this.isSingle) this.createdDocs = [this.docChoosen];
      else this.createdDocs.push(this.docChoosen);
      this.$emit('input', this.createdDocs);
    },
    createdDocs() {
      this.$emit('input', this.createdDocs);
    },
    defaultDocs() {
      this.init();
    },
  },

  beforeMount() {
    this.init();
    if(this.defaultDocs.length != 0){
      this.docName = this.defaultDocs[0]!.file_name
      this.display_name = this.defaultDocs[0]!.display_name
    }
  },
});
