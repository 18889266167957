import { Category } from '@/models/category/category';
import { ContentElement } from '@/models/content_element/content_element';
import { MutationTree } from 'vuex';
import { ServiceCommunalCategoriesState } from './types';

export const mutations: MutationTree<ServiceCommunalCategoriesState> = {
  setServicesCommunauxCategories(state, payload: Array<Category>) {
    state.servicesCommunauxCategories = payload;
  },
  addServicesCommunauxCategories(state, payload: Category) {
    state.servicesCommunauxCategories.unshift(payload);
  },
  addServiceCommunalCategorie(state, payload: Category) {
    state.servicesCommunauxCategories.unshift(payload);
  },

  setServicesCommunauxSousCategories(state, payload: Array<Category>) {
    state.servicesCommunauxSousCategories = payload;
  },
  addServiceCommunalSousCategorie(state, payload: Category) {
    state.servicesCommunauxSousCategories.unshift(payload);
  },

  setServicesCommunaux(state, payload: Array<ContentElement>) {
    state.servicesCommunaux = payload;
  },
  addServiceCommunal(state, payload: ContentElement) {
    state.servicesCommunaux.unshift(payload);
  },
  removeCategory(state, payload: Category) {
    state.servicesCommunauxCategories =
      state.servicesCommunauxCategories.filter(
        (category) => category.node_id !== payload.node_id,
      );
  },
  removeSubCategory(state, payload: Category) {
    state.servicesCommunauxSousCategories =
      state.servicesCommunauxSousCategories.filter(
        (sub_category) => sub_category.node_id !== payload.node_id,
      );
  },
  removeService(state, payload: ContentElement) {
    state.servicesCommunaux = state.servicesCommunaux.filter(
      (service) => service.node_id !== payload.node_id,
    );
  },
  updateCategory(state, payload: Category) {
    const currentCategory = state.servicesCommunauxCategories.find(
      (category) => category.node_id == payload.node_id,
    );

    if (currentCategory) {
      const index = state.servicesCommunauxCategories.indexOf(currentCategory);
      state.servicesCommunauxCategories[index] = payload;
    }
  },
  updateSubCategory(state, payload: Category) {
    const currentSubCategory = state.servicesCommunauxSousCategories.find(
      (subcategory) => subcategory.node_id == payload.node_id,
    );

    if (currentSubCategory) {
      const index =
        state.servicesCommunauxSousCategories.indexOf(currentSubCategory);
      state.servicesCommunauxSousCategories[index] = payload;
    }
  },
  updateService(state, payload: ContentElement) {
    const currentService = state.servicesCommunaux.find(
      (service) => service.node_id == payload.node_id,
    );

    if (currentService) {
      const index = state.servicesCommunaux.indexOf(currentService);
      state.servicesCommunaux[index] = payload;
    }
  },
};
