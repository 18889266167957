
import { Vue } from 'vue-property-decorator';
import Section from '@/components/section/section.vue';
import Table from '@/components/table/table.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import { mapGetters, mapActions } from 'vuex';
import { textTransform } from '@/mixins/mixins';
import { layoutMixin } from '@/mixins/layout.mixin';
import { ContentElement } from '@/models/content_element/content_element';
import { node } from '@/store/modules/node';
export default Vue.extend({
  name: 'ContentAdmin',
  mixins: [layoutMixin],
  components: {
    Section,
    Table,
    ConfirmModal,
  },
  data() {
    return {
      id: '',
      contentId: '',
      pageTitle: 'Administration des éléments de contenu',

      formRoutes: [
        { id: 1, title: 'Article', route: '/nouvelle-article/article' },
        { id: 3, title: 'Évènement', route: '/nouvelle-article/evenement' }
      ],
      selectedContent: null as any,
    };
  },
  computed: {
    ...mapGetters('admin', ['getSortBy']),
    ...mapGetters('editorial', ['getEditorial']),
  },
  methods: {
    ...mapActions('admin', ['setPageTitle']),
    ...mapActions('dataNode', ['deleteNode']),
    ...mapActions('editorial', ['deleteEditorial']),
    changePageTitle(pageTitle: string): any {
      this.setPageTitle(pageTitle);
    },
    confirmContent(content: ContentElement) {
      const nodeId = content.node_id;
      if (nodeId) {
        this.contentId = nodeId;
        this.$bvModal.show(nodeId);
        this.selectedContent = content;
      }
    },
    deleteContent() {
      this.deleteEditorial(this.selectedContent);
    },
  },
  beforeMount() {
    this.changePageTitle(this.pageTitle);
    this.contentId = Math.random().toString();
  },
});
