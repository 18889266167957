import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ExecutifState } from './types';
import { Executif } from '@/models/executif/executif';

export const getters: GetterTree<ExecutifState, RootState> = {
  getExecutif(state): Executif | undefined {
    return state.executif;
  },
};
