import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/pages-admin/getters';
import { actions } from '@/store/modules/pages-admin/action';
import { mutations } from '@/store/modules/pages-admin/mutations';
import { PagesAdminState } from './types';
export const state: PagesAdminState = {
  motifs: [],
  contactMotifs: [],
  rdvMotifs: null
};

export const pagesAdmin: Module<PagesAdminState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};