
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedSlide'],
  components: {
    InputFormGroupText,
    ImageCard,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      uploading: false,
      imageTags: ['image', 'slide'],
      medias: (this as any).getMediasFormNode(this.injectedSlide, []),
      lockUpdate: true,
      slide: this.injectedSlide,
    };
  },
  computed: {
    ...mapGetters('slides', ['getSlides']),

    haveDefaultData(): boolean {
      return !!this.slide;
    },

    creating(): boolean {
      return !!this.injectedSlide;
    },
  },
  created: function () {
    this.$watch('slide', this.slideUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('slides', ['loadSlides', 'addSlider', 'updateSlider']),

    ...mapActions('media', ['updateMediasNode']),

    slideUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    init() {
      if (!this.haveDefaultData) {
        this.slide = {
          value: {
            accroche: '',
            slug: ''
          },
        };
        this.uploading = false;
        this.lockUpdate = true;
        this.imageTags = ['image', 'slide'];
      }
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(['image', 'slide'], this.medias);
    },
    cancel() {
      this.lockUpdate = true;
      this.slide = this.injectedSlide;
      this.medias = (this as any).getMediasFormNode(this.injectedSlide, [])
      this.$emit('toggleAddSlide');
    },
    validation(): boolean{
      let validationState = true
      if(this.medias.length == 0) {
        validationState = false
      }
      return validationState
    },
    save() {
      if (this.medias.length !== 0) {
        this.slide.value.slug = (this as any).slugify(this.slide.value.accroche)

        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'slider',
          NodeType.content,
          this.slide.value,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addSlider(dto).then(() => {
          this.cancel();
        });
      } else {
        this.$toast.error("Veuillez ajouter l'image");
      }
    },
    update() {
      if(this.medias.length !== 0){
        this.slide.value.slug = (this as any).slugify(this.slide.value.accroche)

        const mediaNavigationPath = this.slide.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.slide,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateSlider(this.slide);
          this.lockUpdate = true;
          this.$emit('toggleAddSlide');
        });
      } else {
        this.$toast.error("Veuillez ajouter l'image");
      }
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    slides() {
      this.init();
    },
  },
});
