import {
  DataNodeCreateDto,
  DataNodeSearchDto,
  DataNodeUpdateDto,
} from '@/dto/data-node-dto';
import { Node } from '@/models/node/node';
import { ApiResponse } from '@/models/http/ApiResponse';
import { HttpBaseService } from './httpBase.service';
import { CreateResponse } from '@/models/response/CreateResponse';
import { contentManagerApiUrl } from "@/app.config";

export class NodeService extends HttpBaseService {
  private static classInstance?: NodeService;
  static apiBaseUrl = contentManagerApiUrl;

  constructor(token: string) {
    super(token, NodeService.apiBaseUrl);
  }

  public static getInstance(token: string): NodeService {

    if (!this.classInstance) {
      this.classInstance = new NodeService(token);
    }

    return this.classInstance;
  }

  public create(
    dataNodeCreateDto: DataNodeCreateDto,
  ): Promise<ApiResponse<CreateResponse>> {
    const apiResponse = new ApiResponse<CreateResponse>();

    return this.instance
      .post('/content/create', dataNodeCreateDto)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public update(
    dataNodeUpdateDto: DataNodeUpdateDto,
  ): Promise<ApiResponse<any>> {
    const apiResponse = new ApiResponse<any>();

    return this.instance
      .put('/content/update', dataNodeUpdateDto)
      .then((response) => {
        apiResponse.data = 'succeed';
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public search(dataSearchDto: DataNodeSearchDto): Promise<ApiResponse<Node>> {
    const apiResponse = new ApiResponse<Node>();

    return this.instance
      .post('/content/search', dataSearchDto)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public delete(nodeId: string): Promise<ApiResponse<any>> {
    const apiResponse = new ApiResponse<any>();
    return this.instance
      .delete(`content/delete?content_id=${nodeId}`)
      .then((response) => {
        apiResponse.data = 'succeed';
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}
