import { Category } from '@/models/category/category';
import { Document } from '@/models/document/document';
import { MutationTree } from 'vuex';
import { DocumentsState } from './types';

export const mutations: MutationTree<DocumentsState> = {
  setDocumentCategories(state, payload: Array<Category>) {
    state.documentCategories = payload;
  },
  addDocumentCategories(state, payload: Category) {
    state.documentCategories.unshift(payload);
  },
  setDocuments(state, payload: Array<Document>) {
    state.documents = payload;
  },
  addDocuments(state, payload: Document) {
    state.documents.unshift(payload);
  },
  removeLibraryCategory(state, payload: Category) {
    state.documentCategories = state.documentCategories.filter(
      (category) => category.node_id !== payload.node_id,
    );
  },
  removeLibraryDocument(state, payload: Document) {
    state.documents = state.documents.filter(
      (document) => document.node_id !== payload.node_id,
    );
  },
  updateCategory(state, payload: Category) {
    const currentCategory = state.documentCategories.find(
      (category) => category.node_id == payload.node_id,
    );

    if (currentCategory) {
      const index = state.documentCategories.indexOf(currentCategory);
      state.documentCategories[index] = payload;
    }
  },
  updateDocument(state, payload: Document) {
    const currentDocument = state.documents.find(
      (document) => document.node_id == payload.node_id,
    );

    if (currentDocument) {
      const index = state.documents.indexOf(currentDocument);
      state.documents[index] = payload;
    }
  },
};
