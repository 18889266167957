
import { textTransform } from '@/mixins/mixins';
import { Vue } from 'vue-property-decorator';
import ActionButton from '@/components/buttons/action-button/action-button.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  props: ['id', 'headers', 'activeAccordion', 'hideDeleteBtn', 'type', 'slug'],
  components: {
    ActionButton,
  },
  data() {
    return {
      visible: false,
      isVisible: false,
      node_id: '',
    };
  },
  watch: {
    activeAccordion() {
      if (this.activeAccordion == this.id) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
  },
  computed: {
    ...mapGetters('configuration', ['getConfiguration']), 
  },
  methods: {
    toggleAccordion() {
      if (this.activeAccordion == this.id) this.$emit('toggleAccordion', 0);
      else this.$emit('toggleAccordion', this.id);
    },
    handleDelete() {
      this.$emit('handleDelete');
    },
    subString(header: string) {
      return textTransform.methods.subString(header, 20);
    },
    isImage(header: any) {
      if (header! && header.isImage! == true) return true;
      return false;
    },
    getUri(header: any) {
      if (header?.uri) return header.uri;
      return null;
    },
    shareItem() {
      this.isVisible = !this.isVisible
    }
  },
});
