import { Node } from '@/models/node/node';
import { MutationTree } from 'vuex';
import { NodeState } from './types';

export const mutations: MutationTree<NodeState> = {
  setNodes(state, payload: Array<Node>) {
    state.nodes = payload;
  },

  setTree(state, payload: Node) {
    state.tree = payload;
  },
};
