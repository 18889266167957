
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import RadioFormGroup from '@/components/inputs/radio-form-group/radio-form-group.vue';
import { Media } from '@/models/media/media';
import { CommissionMember } from '@/models/commission/commission-member/commission-member';
import { mapActions } from 'vuex';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { mediaMixin } from '@/mixins/media.mixin';
import { build } from '@/mixins/build.mixin';
import { Gender } from '@/models/enums/enums';
export default Vue.extend({
  props: ['commission', 'commissionMember', 'creating'],
  components: {
    InputFormGroupText,
    ImageCard,
    RadioFormGroup,
  },
  mixins: [build, mediaMixin],
  data() {
    return {
      canEdit: true,
      uploading: false,
      validate: {
        profession: '',
        last_name: '',
        first_name: '',
      },
      member: this.commissionMember, 
      civilities: [
        { value: Gender.male, text: 'M.' },
        { value: Gender.female, text: 'Mme' },
      ],
      imageTags: ['image', 'profile'],

      medias: [] as Media[],
      lockUpdate: true,
    };
  },
  created: function () {
    this.$watch('member', this.commissionMemberUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('commissions', ['loadCommissions']),

    commissionMemberUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    onCivilityUpdate(civility: string) {
      this.member.value.civility = civility;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    retrieveImage(member: CommissionMember): Media[] {
      if(this.commissionMember == null){
        return (this as any).retrieveMediaByTags(
          ['image', 'profile'],
          this.medias,
        );
      }else {
        if (!member.imgs) return [];
        return member.imgs;
      }
    },

    cancel() {
      this.lockUpdate = true;
      this.validation();
      this.$emit('cancelAddCommissionMember');
    },
    validation(): boolean{
      let validationState = true
      if(this.member.value.profession === '') {
        this.validate.profession = 'false'
        validationState = false
      }else{
        this.validate.profession = ''
      }
      if(this.member.value.first_name === '') {
        this.validate.first_name = 'false'
        validationState = false
      }else{
        this.validate.first_name = ''
      }
      if(this.member.value.last_name === '') {
        this.validate.last_name = 'false'
        validationState = false
      }else{
        this.validate.last_name = ''
      }
      return validationState
    },
    save() {
      if (this.validation() === true) {
        this.member.value.slug = (this as any).slugify(this.member.value.last_name)
        if (this.member.value.fonction === '') this.member.value.fonction = 'Membre'
        this.member.imgs = this.medias;
        this.$emit('addACommissionMember', this.member);
        this.cancel();
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if(this.validation() === true){
        this.member.value.slug = (this as any).slugify(this.member.value.last_name)
        if (this.member.value.fonction === '') this.member.value.fonction = 'Membre'
        this.member.imgs = this.medias;
        this.$emit('updateACommissionMember', this.member);
        this.cancel();
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    edit() {
      this.canEdit = true;
    },
  },
  beforeMount(){
    if(this.member == null){
      this.member = {
        value: {
          civility: 'M.',
          last_name: '',
          first_name: '',
          profession: '',
          fonction: '',
        },
        imgs: [] as Media[],
      }
    }
  }
});
