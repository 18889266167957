import { Motif } from '@/models/motif/motif';
import { MutationTree } from 'vuex';
import { PagesAdminState } from './types';
import { rdvMotifs } from '@/models/rdv-module/motifs';

export const mutations: MutationTree<PagesAdminState> = {
  setMotifs(state, payload: Array<Motif>) {
    state.motifs = payload;
  },
  setContactMotifs(state, payload: Array<Motif>) {
    state.contactMotifs = payload;
  },
  addMotif(state, payload: Motif) {
    state.motifs.unshift(payload);
  },
  addContactMotif(state, payload: Motif) {
    state.contactMotifs.unshift(payload);
  },
  updateMotif(state, payload: Motif) {
    const currentCategory = state.motifs.find(
      (motif) => motif.node_id == payload.node_id,
    );

    if (currentCategory) {
      const index = state.motifs.indexOf(currentCategory);
      state.motifs[index] = payload;
    }
  },
  updateContactMotif(state, payload: Motif) {
    const currentMotif = state.contactMotifs.find(
      (motif) => motif.node_id == payload.node_id,
    );

    if (currentMotif) {
      const index = state.contactMotifs.indexOf(currentMotif);
      state.contactMotifs[index] = payload;
    }
  },
  removeMotif(state, payload: Motif) {
    state.motifs = state.motifs.filter(
      (motif) => motif.node_id !== payload.node_id,
    );
  },
  removeContactMotif(state, payload: Motif) {
    state.motifs = state.contactMotifs.filter(
      (motif) => motif.node_id !== payload.node_id,
    );
  },
  addRdvMotif(state, payload: rdvMotifs) {
    state.rdvMotifs = payload;
  },
};
