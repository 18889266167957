import { ContentElement } from '@/models/content_element/content_element';
import { Category } from '@/models/category/category';
import { MutationTree } from 'vuex';
import { EditorialState } from './types';

export const mutations: MutationTree<EditorialState> = {
  setEditorial(state, payload: Array<ContentElement>) {
    state.editorial = payload;
  },
  addEditorial(state, payload: ContentElement) {
    state.editorial.unshift(payload);
  },
  removeEditorial(state, payload: ContentElement) {
    state.editorial = state.editorial.filter(
      (ed) => ed.node_id !== payload.node_id,
    );
  },
  updateContent(state, payload: ContentElement) {
    const currentEditorial = state.editorial.find(
      (ed) => ed.node_id == payload.node_id,
    );

    if (currentEditorial) {
      const index = state.editorial.indexOf(currentEditorial);
      state.editorial[index] = payload;
    }
  },
  setTags(state, payload: Array<Category>) {
    state.tags = payload;
  },
  addTag(state, payload: Category) {
    state.tags.unshift(payload);
  },
  updateTag(state, payload: Category) {
    const currentCategory = state.tags.find(
      (category) => category.node_id == payload.node_id,
    );

    if (currentCategory) {
      const index = state.tags.indexOf(currentCategory);
      state.tags[index] = payload;
    }
  },
  removeTag(state, payload: Category) {
    state.tags = state.tags.filter(
      (category) => category.node_id !== payload.node_id,
    );
  },
};
