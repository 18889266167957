
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { mapActions } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { build } from '@/mixins/build.mixin';
export default Vue.extend({
  props: ['injectedReseaux'],
  components: {
    InputFormGroupText,
  },
  mixins: [build],
  data() {
    return {
      canEdit: true,
      reseaux: this.injectedReseaux,
      lockUpdate: true,
      validate: {
        fb: '',
        tw: '',
        ig: '',
        wa: '',
        you: '',
        lk: ''
      } as any,
      tab: [
        'fb',
        'tw',
        'ig',
        'wa',
        'you',
        'lk'
      ]
    };
  },
  computed: {
    haveDefaultData(): boolean {
      return !!this.reseaux;
    },

    creating(): boolean {
      return !!this.injectedReseaux;
    },
  },
  created: function () {
    this.$watch('reseaux', this.reseauxUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('reseaux', ['addReseaux', 'updateReseaux']),

    reseauxUpdated: function () {
      this.lockUpdate = false;
    },
    validateData(value: string): boolean {
      let validationState = true;
      if (this.reseaux.value[value] && !this.validateUrl(this.reseaux.value[value])) {
        this.validate[value] = "false";
        validationState = false;
      } else {
        this.validate[value] = "";
      }
      return validationState;
    },
    validation(): boolean{
      let validationState = true;
      for (const element of this.tab) {
        validationState = this.validateData(element);
        if (!validationState) {
          break;
        }
      }
      return validationState;
    },
    init() {
      if (!this.haveDefaultData) {
        this.reseaux = {
          value: {
            fb: '',
            tw: '',
            ig: '',
            wa: '',
            you: '',
            lk: ''
          },
        };
        this.lockUpdate = true;
      }
    },
    cancel() {
      this.lockUpdate = true;
    },
    save() {
      if (this.validation() === true) {
        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'reseaux',
          NodeType.content, 
          this.reseaux.value,
        );
        this.addReseaux(node);
        this.lockUpdate = true;
      } else {
        this.$toast.error('Les champs doivent etre des liens');
      }
    },
    update() {
      if (this.validation() === true) {
        this.updateReseaux(this.reseaux);
        this.lockUpdate = true;
      } else {
        this.$toast.error('Les champs doivent etre des liens');
      }
    },
    edit() {
      this.canEdit = true;
    },
    validateUrl(string: string) : boolean {
      if(string == ''){
        return true;
      }else{
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }

        return url.protocol === "http:" || url.protocol === "https:";
      }
      
    }
  },
  beforeMount() {
    this.init();
  }
});
