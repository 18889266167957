import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    ImageWidth: {
      setImageWidth: (someProp: any) => ReturnType;
    };
  }
}

export const ImageWidth = Extension.create({
  name: 'imageWidth',
  addOptions() {
    return {
      types: [],
      widths: ['contain', 'full'],
      defaultWidth: '',
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          imageWidth: {
            default: this.options.defaultWidth,
            parseHTML: (element: any) =>
              element.style.imageWidth || this.options.defaultWidth,
            renderHTML: (attributes) => {
              if (attributes.imageWidth === this.options.defaultWidth) {
                return {};
              }
              return { width: attributes.imageWidth };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setImageWidth:
        (width) =>
        ({ commands }) => {
          if (!this.options.widths.includes(width)) {
            return false;
          }
          return this.options.types.every((type: any) =>
            commands.updateAttributes(type, { imageWidth: width }),
          );
        },
    };
  },
});
