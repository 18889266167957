
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  data() {
    return {
      tabIndex: 0,
    };
  },
  watch: {
    tabIndex() {
      this.$emit('input', this.tabIndex);
    },
  },
});
