import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ContentAdmin from '@/pages/content-admin/content-admin.vue';
import NouvelArticle from '@/pages/nouvel-article/nouvel-article.vue';
import NouveauService from '@/pages/nouveau-service/nouveau-service.vue';
import Municipalite from '@/pages/municipalite/municipalite.vue';
import ServicesCommunaux from '@/pages/services-communaux/services-communaux.vue';
import Bibliotheque from '@/pages/bibliotheque/bibliotheque.vue';
import Médiathèque from '@/pages/mediatheque/mediatheque.vue';
import Tags from '@/pages/tags/tags.vue';
import Contact from '@/pages/pages-admin/contact.vue';
import Anomalie from '@/pages/pages-admin/anomalies.vue';
import RendezVous from '@/pages/pages-admin/rendez-vous.vue'
import Layout from '@/components/layout/layout.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { name: 'home', path: '/', redirect: { name: 'ContentAdmin' } },

  {
    path: '/layout',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/content/content-admin',
        name: 'ContentAdmin',
        component: ContentAdmin,
      },
      {
        path: '/nouvelle-article/:type',
        name: 'NouvelleArticle',
        component: NouvelArticle,
      },
      {
        path: '/edit-article/:node_id',
        name: 'EditArticle',
        component: NouvelArticle,
      },
      {
        path: '/configuration-generale/municipalite',
        name: 'Municipalite',
        component: Municipalite,
      },
      {
        path: '/nouveau-service',
        name: 'NouveauService',
        component: NouveauService,
      },
      {
        path: '/nouveau-service/:node_id',
        name: 'NouveauServiceById',
        component: NouveauService,
      },
      {
        path: '/services-communaux',
        name: 'ServicesCommunaux',
        component: ServicesCommunaux,
      },
      {
        path: '/configuration-generale/bibliotheque',
        name: 'Bibliotheque',
        component: Bibliotheque,
      },
      {
        path: '/configuration-generale/mediatheque',
        name: 'Médiathèque',
        component: Médiathèque,
      },
      {
        path: '/content/tags',
        name: 'Tags',
        component: Tags,
      },
      {
        path: '/page/contact',
        name: 'Contact',
        component: Contact,
      },
      {
        path: '/page/rendez-vous',
        name: 'RendezVous',
        component: RendezVous,
      },
      {
        path: '/page/anomalie',
        name: 'Anomalie',
        component: Anomalie,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: 'content-app'
});

export default router;
