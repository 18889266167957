import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/conseil-municipal/getters';
import { actions } from '@/store/modules/conseil-municipal/action';
import { mutations } from '@/store/modules/conseil-municipal/mutations';
import { ConseilMunicipalState } from './types';
export const state: ConseilMunicipalState = {
  conseilMunicipal: undefined,
};

export const conseilMunicipal: Module<ConseilMunicipalState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
