
import { Vue } from 'vue-property-decorator';
import TipTapLiteFormModal from '@/components/edition-components/tiptap-lite-form-modal/tiptap-lite-form-modal.vue';
import { mapActions } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import DocumentCard from '@/components/inputs/document-card/document-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedOrganigramme'],
  components: {
    TipTapLiteFormModal,
    ImageCard,
    DocumentCard,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      imageTags: ['image', 'présentation'],
      documentTags: ['document'],
      medias: (this as any).getMediasFormNode(this.injectedOrganigramme, []),
      lockUpdate: true,
      organigramme: this.injectedOrganigramme,
    };
  },
  computed: {
    haveDefaultData(): boolean {
      return !!this.organigramme;
    },

    creating(): boolean {
      return !!this.injectedOrganigramme;
    },
  },
  created: function () {
    this.$watch('organigramme', this.organigrammeUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('organigramme', ['addOrganigramme', 'updateOrganigramme']),
    ...mapActions('media', ['updateMediasNode']),

    organigrammeUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = (this as any).updateMediasByTags(this.medias, medias);
    },
    updateDocuments(documents: Media[]) {
      this.medias = (this as any).updateMediasByTags(this.medias, documents);
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'présentation'],
        this.medias,
      );
    },

    retrieveDocs() {
      return (this as any).retrieveMediaByTags(
        ['document'],
        this.medias,
      );
    },
    
    init() {
      if (!this.haveDefaultData) {
        this.organigramme = {
          value: {
            desc: '',
            slug: ''
          },
        };
        this.lockUpdate = true;
        this.imageTags = ['image', 'présentation'];
        this.documentTags = ['document'];
      }
    },
    cancel() {
      this.lockUpdate = true;
      this.medias = (this as any).getMediasFormNode(this.injectedOrganigramme, [])
    },
    save() {
      if(this.organigramme.value.desc.text){
        this.organigramme.value.slug = (this as any).slugify(this.organigramme.value.desc.text)
      }else{
        this.organigramme.value.slug = (this as any).slugify('oraganigramme')
      }

        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'organigramme',
          NodeType.content,
          this.organigramme.value,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addOrganigramme(dto).then(() => {
          this.creating = false;
          this.lockUpdate = true;
        });

    },
    update() {
      if(this.organigramme.value.desc.text){
        this.organigramme.value.slug = (this as any).slugify(this.organigramme.value.desc.text)
      }else{
        this.organigramme.value.slug = (this as any).slugify('oraganigramme')
      }

      const mediaNavigationPath = this.organigramme.navigation_path + '/medias';
      const mediaNode = (this as any).findNodeInTree(
        mediaNavigationPath,
        this.organigramme,
      );
      mediaNode.value.medias = this.medias;

      this.updateMediasNode(mediaNode).then(() => {
        this.updateOrganigramme(this.organigramme);
        this.lockUpdate = true;
      });
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    organigramme() {
      this.init();
    },
  },
});
