
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import RadioFormGroup from '@/components/inputs/radio-form-group/radio-form-group.vue';
import { mapActions, mapGetters } from 'vuex';
import { Media } from '@/models/media/media';
import { Gender, NodeType } from '@/models/enums/enums';
import { ConseillerMunicipal } from '@/models/conseiller-municipal/conseiller-municipal';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedCouncillor'],
  components: {
    InputFormGroupText,
    ImageCard,
    RadioFormGroup,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      uploading: false,
      conseillerMunicipal: this.injectedCouncillor,
      oldData: {} as ConseillerMunicipal,
      validate: {
        profession: '',
        last_name: '',
        first_name: '',
      },
      civilities: [
        { value: Gender.male, text: 'M.' },
        { value: Gender.female, text: 'Mme' },
      ],
      imageTags: ['image', 'profile'],
      medias: (this as any).getMediasFormNode(this.injectedCouncillor, []),
      lockUpdate: true,
    };
  },
  computed: {
    ...mapGetters('conseillersMunicipaux', ['getConseillersMunicipaux']),
    ...mapGetters('media', ['getLastMediaUploaded']),

    haveDefaultData(): boolean {
      return !!this.conseillerMunicipal;
    },

    creating(): boolean {
      return !!this.injectedCouncillor;
    },
  },
  created: function () {
    this.$watch('conseillerMunicipal', this.conseilMunicipalUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('conseillersMunicipaux', [
      'addMunicipalCouncillors',
      'updateMunicipalCouncillors',
    ]),
    ...mapActions('media', ['updateMediasNode']),

    conseilMunicipalUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated () {
      this.lockUpdate = false;
    },
    onCivilityUpdate(civility: string) {
      this.conseillerMunicipal.value.civility = civility;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },
    init() {
      if (!this.haveDefaultData) {
        this.conseillerMunicipal = {
          value: {
            civility: 'M.',
            last_name: '',
            first_name: '',
            profession: '',
            role: '',
            slug: ''
          },
        };
        this.civilities = [
          { value: Gender.male, text: 'M.' },
          { value: Gender.female, text: 'Mme' },
        ];
      }
    },
    cancel() {
      this.lockUpdate = true;
      this.$emit('cancelAddConseillerMunicipal');
      this.medias = (this as any).getMediasFormNode(
        this.injectedCouncillor,
        [],
      );
    },
    validation(): boolean{
      let validationState = true
      if(this.conseillerMunicipal.value.profession === '') {
        this.validate.profession = 'false'
        validationState = false
      }else{
        this.validate.profession = ''
      }
      if(this.conseillerMunicipal.value.first_name === '') {
        this.validate.first_name = 'false'
        validationState = false
      }else{
        this.validate.first_name = ''
      }
      if(this.conseillerMunicipal.value.last_name === '') {
        this.validate.last_name = 'false'
        validationState = false
      }else{
        this.validate.last_name = ''
      }
      return validationState
    },
    save() {
      if (this.validation() === true) {
        this.conseillerMunicipal.value.slug = (this as any).slugify(this.conseillerMunicipal.value.last_name)
        if (this.conseillerMunicipal.value.role === '') this.conseillerMunicipal.value.role = 'Conseiller'
        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'conseillers_municipaux',
          NodeType.content,
          this.conseillerMunicipal.value,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addMunicipalCouncillors(dto).then(() => {
          this.validation();
          this.cancel();
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if (this.validation() === true) {
        this.conseillerMunicipal.value.slug = (this as any).slugify(this.conseillerMunicipal.value.last_name)
        if (this.conseillerMunicipal.value.role === '') this.conseillerMunicipal.value.role = 'Conseiller'
        const mediaNavigationPath =
          this.conseillerMunicipal.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.conseillerMunicipal,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateMunicipalCouncillors(this.conseillerMunicipal);
          this.lockUpdate = true;
          this.validation();
          this.$emit('cancelAddConseillerMunicipal');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
  },
  beforeMount() {
    this.init();
  },
});
