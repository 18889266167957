
import Vue from 'vue';

export default Vue.extend({
  props: [
    'inputId',
    'inputDisabled',
    'label',
    'value',
    'options',
    'disableFontWeigth',
    'disableColor',
  ],
  data() {
    return {
      selected: '',
    };
  },
  watch: {
    selected() {
      this.$emit('change-civility', this.selected);
    },
  },
  beforeMount() {
    if (this.value) {
      this.selected = this.value;
    }
  },
});
