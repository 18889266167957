
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedPartner'],
  components: {
    ImageCard,
    InputFormGroupText,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      canEdit: true,
      uploading: false,
      validate: {
        name: ''
      },
      imageTags: ['image', 'profile'],
      medias: (this as any).getMediasFormNode(this.injectedPartner, []),
      lockUpdate: true,
      partner: this.injectedPartner,
    };
  },
  computed: {
    ...mapGetters('partners', ['getPartners']),

    haveDefaultData(): boolean {
      return !!this.partner;
    },

    creating(): boolean {
      return !!this.injectedPartner;
    },
  },
  created: function () {
    this.$watch('partner', this.partnerUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('partners', ['loadPartners', 'addPartners', 'updatePartner']),
    ...mapActions('media', ['updateMediasNode']),

    partnerUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },

    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },

    init() {
      if (!this.haveDefaultData) {
        this.partner = {
          value: {
            name: '',
            slug: ''
          },
        };
        this.uploading = false;
        this.lockUpdate = true;
        this.imageTags = ['image', 'profile'];
      }
    },
    cancel() {
      this.lockUpdate = true;
      this.partner = this.injectedPartner;
      this.medias = (this as any).getMediasFormNode(this.injectedPartner, [])
      this.$emit('toggleAddPartner');
    },
    validation(): boolean{
      let validationState = true
      if(this.partner.value.name === '') {
        this.validate.name = 'false'
        validationState = false
      }else{
        this.validate.name = ''
      }
      return validationState
    },
    save() {
      if (this.validation() === true && this.medias.length !== 0) {
        this.partner.value.slug = (this as any).slugify(this.partner.value.name)
        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'partners',
          NodeType.content,
          this.partner.value,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addPartners(dto).then(() => {
          this.validation();
          this.cancel();
        });
      } else {
        this.$toast.error("Veuillez ajouter remplir tous les champs");
      }
    },
    update() {
      if (this.validation() === true && this.medias.length !== 0) {
        this.partner.value.slug = (this as any).slugify(this.partner.value.name)

        const mediaNavigationPath = this.partner.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.partner,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updatePartner(this.partner);
          this.lockUpdate = true;
          this.validation();
          this.$emit('toggleAddPartner');
        });
      } else {
        this.$toast.error("Veuillez ajouter remplir tous les champs");
      }
    },
    edit() {
      this.canEdit = true;
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    partner() {
      this.init();
    },
  },
});
