import { ApiResponse } from "@/models/http/ApiResponse"
import { AppointmentScheduleTemplate, BusinessCategoryModel, BusinessPurpose } from "@/models/schedule-manager/schedule-manager"
import { HttpBaseService } from "./httpBase.service"
import { scheduleManagerApiUrl } from "@/app.config"


export class ScheduleManagerService extends HttpBaseService{

    private static classInstance?: ScheduleManagerService

    static apiBaseUrl = scheduleManagerApiUrl;

    constructor(token:string) {
        super(token, ScheduleManagerService.apiBaseUrl)
    }


    public static getInstance(token:string): ScheduleManagerService {

        if(!this.classInstance) {
            this.classInstance = new ScheduleManagerService(token)
        }

        return this.classInstance
    }


    public loadBusinessCategory(resourceId: string) : Promise<ApiResponse<BusinessCategoryModel>>{

        return this.instance.get(`admin/metadata/business_category/${resourceId}`)
            .then(response => {
                const apiResponse = new ApiResponse<BusinessCategoryModel>()
                switch(response.status) {
                    case 204: {
                        return apiResponse
                    }
                    default: {
                        apiResponse.data = response.data
                        return apiResponse
                    }
                }
            })
            .catch(() => {
                return new ApiResponse<BusinessCategoryModel>()
            })
    }

    public loadBusinessPurposes() : Promise<ApiResponse<BusinessPurpose[]>>{

        return this.instance.get('admin/metadata/business_purpose')
            .then(response => {
                const apiResponse = new ApiResponse<BusinessPurpose[]>()
                switch(response.status) {
                    case 404: {
                        return apiResponse
                    }
                    default: {
                        apiResponse.data = response.data
                        return apiResponse
                    }
                }
            })
            .catch(() => {
                return new ApiResponse<BusinessPurpose[]>()
            })
    }


    public loadBusinessPurpose(resourceId: string) : Promise<ApiResponse<BusinessPurpose>>{

        return this.instance.get(`admin/metadata/business_purpose/${resourceId}`)
            .then(response => {
                const apiResponse = new ApiResponse<BusinessPurpose>()
                switch(response.status) {
                    case 204: {
                        return apiResponse
                    }
                    default: {
                        apiResponse.data = response.data
                        return apiResponse
                    }
                }
            })
            .catch(() => {
                return new ApiResponse<BusinessPurpose>()
            })
    }    
    
    public loadScheduleTemplate(templateId: string) : Promise<ApiResponse<AppointmentScheduleTemplate>>{
        return this.instance.get(`templates/${templateId}`)
            .then(response => {
                const apiResponse = new ApiResponse<AppointmentScheduleTemplate>()
                switch(response.status) {
                    case 204: {
                        return apiResponse
                    }
                    default: {
                        apiResponse.data = response.data
                        return apiResponse
                    }
                }
            })
            .catch(() => {
                return new ApiResponse<AppointmentScheduleTemplate>()
            })
    }


    public loadScheduleTemplates() : Promise<ApiResponse<AppointmentScheduleTemplate[]>>{

        return this.instance.get('templates')
            .then(response => {
                const apiResponse = new ApiResponse<AppointmentScheduleTemplate[]>()
                switch(response.status) {
                    case 404: { 
                        return apiResponse
                    }
                    default: {
                        apiResponse.data = response.data
                        return apiResponse
                    }
                }
            })
            .catch(() => {
                return new ApiResponse<AppointmentScheduleTemplate[]>()
            })
    }

}