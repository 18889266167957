import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/layout/getters';
import { actions } from '@/store/modules/layout/actions';
import { mutations } from '@/store/modules/layout/mutations';
import { LayoutState } from './types';

export const state: LayoutState = {
  activeSideBar: true,
  sideBarActiveElement: 'O',
};

export const layout: Module<LayoutState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
