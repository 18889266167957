import { Partner } from '@/models/partner/partner';
import { MutationTree } from 'vuex';
import { PartnersState } from './types';

export const mutations: MutationTree<PartnersState> = {
  setPartners(state, payload: Array<Partner>) {
    state.partners = payload;
  },
  addPartners(state, payload: Partner) {
    state.partners.push(payload);
  },
  removePartner(state, payload: Partner) {
    state.partners = state.partners.filter(
      (partner) => partner.node_id !== payload.node_id,
    );
  },
  updatePartners(state, payload: Partner) {
    const currentPartner = state.partners.find(
      (partner) => partner.node_id == payload.node_id,
    );

    if (currentPartner) {
      const index = state.partners.indexOf(currentPartner);
      state.partners[index] = payload;
    }
  },
};
