import { ActionTree } from 'vuex';
import { MediathequesState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { nodeMixin } from '@/mixins/node.mixin';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import Vue from 'vue';

export const actions: ActionTree<MediathequesState, RootState> = {
  loadMediatheques(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/mediatheque',
      tree,
    );
    if (parent) {
      context.commit('setMediatheques', parent.children);
    }
  },
  addMediathque(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addMedia', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },
  deleteMediatheque(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeMediatheque', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
};
