import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { PagesAdminState } from './types';
import { Motif } from '@/models/motif/motif';
import { rdvMotifs } from '@/models/rdv-module/motifs';

export const getters: GetterTree<PagesAdminState, RootState> = {
  getMotifs(state): Array<Motif> {
    return state.motifs;
  },
  getContactMotifs(state): Array<Motif> {
    return state.contactMotifs;
  },
  getRdvMotifs(state): rdvMotifs | null {
    return state.rdvMotifs;
  },
};