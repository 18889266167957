import { Executif } from '@/models/executif/executif';
import { MutationTree } from 'vuex';
import { ExecutifState } from './types';

export const mutations: MutationTree<ExecutifState> = {
  setExecutif(state, payload: Executif) {
    state.executif = payload;
  },
  updateExecutifInfo(state, payload: Executif) {
    state.executif = payload;
  },
};
