
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { build } from '@/mixins/build.mixin';
import { Category } from '@/models/category/category';
export default Vue.extend({
  props: ['injectedCategory'],
  components: {
    InputFormGroupText,
  },
  mixins: [build],
  data() {
    return {
      node_id: '',
      category: this.injectedCategory,
      lockUpdate: true,
      validate: {
        name: '',
        desc: ''
      },
    };
  },
  created: function () {
    this.$watch('category', this.categoryUpdated, {
      deep: true,
    });
  },
  computed: {
    ...mapGetters('library', ['getDocumentCategories']),

    haveDefaultData(): boolean {
      return !!this.category;
    },

    creating(): boolean {
      return !!this.injectedCategory;
    },
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNode']),
    ...mapActions('library', ['addRubrique', 'updateRubrique']),

    categoryUpdated: function (category: Category, oldCategory: Category) {
      this.lockUpdate = false;
    },
    init() {
      if (!this.haveDefaultData) {
        this.category = { value: { name: '', desc: '', slug: '' } };
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    validation(): boolean{
      let validationState = true
      if(this.category.value.name === '') {
        this.validate.name = 'false'
        validationState = false
      }else{
        this.validate.name = ''
      }
      if(this.category.value.desc === '') {
        this.validate.desc = 'false'
        validationState = false
      }else{
        this.validate.desc = ''
      }
      return validationState
    },
    save() {
      const name = this.category.value.name.toLowerCase();
      const defineName = name.replace(/\s/g, '_');
      const nameCleaned = defineName.replace(/[^a-zA-Z0-9_-]/g,'');

      if (this.validation() === true) {
        this.category.value.slug = (this as any).slugify(this.category.value.name)
        const node = (this as any).buildCreatePayloadWithName(
          this.$data.required_nodes,
          'bibliotheque_categories',
          NodeType.content,
          this.category.value,
          nameCleaned,
        );
        this.addRubrique(node).then(() => {
          this.validation();
          this.$emit('cancel');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs');
      }
    },
    update() {
      if (this.validation() === true) {
        this.category.value.slug = (this as any).slugify(this.category.value.name)
        this.updateRubrique(this.category).then(() => {
          this.validation();
          this.$emit('cancel');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs');
      }
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    category() {
      this.init();
    },
  },
});
