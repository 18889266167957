import { MutationTree } from 'vuex';
import { LayoutState } from './types';

export const mutations: MutationTree<LayoutState> = {
  setActiveSidebar(state, payload: boolean) {
    state.activeSideBar = payload;
  },

  setSidebarActiveElement(state, payload: string) {
    state.sideBarActiveElement = payload;
  },
};
