
import Vue from 'vue';
export default Vue.extend({
  props: {
    value: String,
    inputId: String,
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
