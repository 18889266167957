
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  props: {
    type: String,
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) this.$emit('handleClick');
    },
  },
});
