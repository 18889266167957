
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import TipTapLiteFormModal from '@/components/edition-components/tiptap-lite-form-modal/tiptap-lite-form-modal.vue';
import Accordion from '@/components/accordion/accordion.vue';
import AccordionItem from '@/components/accordion/accordion-item/accordion-item.vue';
import CommissionsCommunalesMemberForm from './commissions-communales-member-form/commissions-communales-member-form.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import { CommissionMember } from '@/models/commission/commission-member/commission-member';
import { Commission } from '@/models/commission/commission';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import DocumentCard from '@/components/inputs/document-card/document-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedCommission'],
  components: {
    InputFormGroupText,
    TipTapLiteFormModal,
    Accordion,
    AccordionItem,
    CommissionsCommunalesMemberForm,
    ConfirmModal,
    ImageCard,
    DocumentCard,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      uploading: false,
      canEdit: true,
      addCommissionMember: false,
      activeAccordion: 0,
      validate: {
        presentation: '',
        commission_name: '',
      },
      member: {
        value: {
          civility: 'M.',
          last_name: '',
          first_name: '',
          profession: '',
          fonction: '',
          slug: ''
        },
        imgs: [] as Media[],
      },
      oldData: {} as Commission,
      members: [] as Array<CommissionMember>,
      node_id: 0,
      memberId: '',
      commissionCommunal: this.injectedCommission,
      medias: (this as any).getMediasFormNode(this.injectedCommission, []),
      imageTags: ['image', 'images'],
      documentTags: ['document'],
      lockUpdate: true,
    };
  },
  computed: {
    ...mapGetters('commissions', ['getCommissions']),
    ...mapGetters('media', ['getLastMediaUploaded']),

    haveDefaultData(): boolean {
      return !!this.commissionCommunal;
    },

    creating(): boolean {
      return !!this.injectedCommission;
    },
  },
  created: function () {
    this.$watch('commissionCommunal', this.commissionCommunalUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', [
      'createNode',
      'updateNodes',
      'deleteNodes',
      'deleteNode',
    ]),
    ...mapActions('commissions', [
      'loadCommissions',
      'addCommission',
      'updateCommission',
    ]),
    ...mapActions('media', ['updateMediasNode']),

    commissionCommunalUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    updateDocuments(documents: Media[]) {
      this.medias = this.medias.concat(documents);
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'images'],
        this.medias,
      );
    },

    retrieveDocs() {
      return (this as any).retrieveMediaByTags(
        ['document'],
        this.medias,
      );
    },
    
    init() {
      if (!this.haveDefaultData) {
        this.commissionCommunal = {
          value: {
            commission_name: '',
            presentation: '',
            slug: '',
            members: [] as Array<CommissionMember>,
          },
        };
        this.members = [] as Array<CommissionMember>;
        this.member = {
          value: {
            civility: 'M.',
            last_name: '',
            first_name: '',
            profession: '',
            fonction: '',
            slug: ''
          },
          imgs: [] as Media[],
        };
        this.addCommissionMember = false;
        this.activeAccordion = 0;
        this.uploading = false;
        this.lockUpdate = true;
        this.imageTags = ['image', 'images'];
        this.documentTags = ['document'];
      }
      Object.assign(this.members, this.commissionCommunal.value.members);
      this.oldData = this.commissionCommunal;
    },
    cancel() {
      this.lockUpdate = true;
      this.commissionCommunal = this.injectedCommission;
      this.medias = (this as any).getMediasFormNode(this.injectedCommission, [])
      this.$emit('toggleAddCommission');
    },
    validation(): boolean{
      let validationState = true
      if(this.commissionCommunal.value.commission_name === '') {
        this.validate.commission_name = 'false'
        validationState = false
      }else{
        this.validate.commission_name = ''
      }
      if(this.commissionCommunal.value.presentation === '') {
        this.validate.presentation = 'false'
        validationState = false
      }else{
        this.validate.presentation = ''
      }
      return validationState
    },
    save() {
      if (this.validation() === true) {
        this.commissionCommunal.value.slug = (this as any).slugify(this.commissionCommunal.value.commission_name)
        this.commissionCommunal.value.members = this.members;
        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'commissions',
          NodeType.content,
          this.commissionCommunal.value,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addCommission(dto).then(() => {
          this.lockUpdate = true;
          this.validation();
          this.cancel();
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },

    update() {
      if(this.validation() === true){
        this.commissionCommunal.value.slug = (this as any).slugify(this.commissionCommunal.value.commission_name)
        const mediaNavigationPath =
          this.commissionCommunal.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.commissionCommunal,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateCommission(this.commissionCommunal);
          this.lockUpdate = true;
          this.validation();
          this.$emit('toggleAddCommission');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },

    toggleAddCommissionMember(show: boolean) {
      this.addCommissionMember = show;
      this.activeAccordion = 0;
    },
    addACommissionMember(member: any) {
      member['node_id'] = this.members.length + 1;
      this.members.push(member);
      this.commissionCommunal.value.members = this.members;
    },
    updateACommissionMember(member: any) {
      let concernedMember = this.members.find(
        (oneMember: any) => oneMember.node_id == member.node_id,
      );
      if (concernedMember) {
        const index = this.members.indexOf(concernedMember);
        this.members[index] = member;
      }
      this.commissionCommunal.value.members = this.members;
    },
    handleDelete(node_id: number) {
      this.$bvModal.show(this.memberId);
      this.node_id = node_id;
    },
    handleOk() {
      this.lockUpdate = false;
      this.members = this.members.filter(
        (oneMember: any) => oneMember.node_id !== this.node_id,
      );
      this.commissionCommunal.value.members = this.members;
    },
  },
  beforeMount() {
    this.init();
    this.memberId = Math.random().toString();
  },
});
