import { ActionTree } from 'vuex';
import { AdminState } from '@/store/modules/admin/types';
import { RootState } from '@/store/types';

export const actions: ActionTree<AdminState, RootState> = {
  setSortBy(context, payload: string) {
    context.commit('setSortBy', payload);
  },

  setPageTitle(context, payload: string) {
    context.commit('setPageTitle', payload);
  },
};
