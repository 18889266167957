import { ActionTree } from 'vuex';
import { ServiceCommunalCategoriesState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { Category } from '@/models/category/category';
import { extractNode } from '@/mixins/mixins';
import { ContentElement } from '@/models/content_element/content_element';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import { nodeMixin } from '@/mixins/node.mixin';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import Vue from 'vue';

export const actions: ActionTree<ServiceCommunalCategoriesState, RootState> = {
  loadServicesCommunauxCategories(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }
    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/services_communaux/categories',
      tree,
    );

    if (parent) {
      const contentNode = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      contentNode?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      
      context.commit('setServicesCommunauxCategories', contentNode);

      const serviceCommunauxCategories: Array<Category> =
        contentNode as Array<Category>;

      const serviceCommunauxSousCategories: Array<Category> = [];
      serviceCommunauxCategories.forEach((serviceCommunauxCategorie) => {
        serviceCommunauxCategorie.children?.forEach(
          (serviceCommunauxSousCategorie) => {
            if (serviceCommunauxSousCategorie.node_type == 'content') {
              serviceCommunauxSousCategorie.parent_category =
                serviceCommunauxCategorie.name;
              serviceCommunauxSousCategorie.parent_category_name =
                serviceCommunauxCategorie.value.name;
              serviceCommunauxSousCategorie.medias =
                extractNode.methods.extractMediaNode(
                  serviceCommunauxSousCategorie.children!,
                  'medias',
                );
              serviceCommunauxSousCategories.push(
                serviceCommunauxSousCategorie,
              );
            }
          },
        );
      });
      serviceCommunauxSousCategories?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })

      context.commit(
        'setServicesCommunauxSousCategories',
        serviceCommunauxSousCategories,
      );

      const serviceCommunaux: Array<ContentElement> = [];
      serviceCommunauxSousCategories.forEach(
        (serviceCommunauxSousCategorie) => {
          serviceCommunauxSousCategorie.children?.forEach((serviceNode) => {
            const service: ContentElement = serviceNode as ContentElement;
            if (service.node_type == 'content') {
              service.tied_sub_category =
                serviceCommunauxSousCategorie.navigation_path;
              service.tied_sub_category_name =
                serviceCommunauxSousCategorie.value.name;
              service.medias = extractNode.methods.extractMediaNode(
                service.children!,
                'medias',
              );
              serviceCommunaux.push(service);
            }
          });
        },
      );
      serviceCommunaux?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setServicesCommunaux', serviceCommunaux);
    }
  },
  addServiceCategorie(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode) {
            context.commit('addServiceCommunalCategorie', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  addSubCategorie(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addServiceCommunalSousCategorie', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },
  addService(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addServiceCommunal', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },
  deleteCategory(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeCategory', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  deleteSubCategory(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeSubCategory', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  deleteService(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeService', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  updateCategory(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateCategory', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  updateSubCategory(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateSubCategory', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  updateSubCategoryChildren(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
      });
  },
  updateServiceContent(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateService', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  updateServiceChildren(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
      });
  },
};
