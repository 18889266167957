import { ActionTree } from 'vuex';
import { ScheduleManagerState } from "./types";
import { RootState } from "@/store/types"
import { AppointmentScheduleTemplate, BusinessCategoryModel, BusinessPurpose, RessourceStateEnum } from '@/models/schedule-manager/schedule-manager';
import { ScheduleManagerService } from '@/services/schedule-manager.service';

export const actions: ActionTree<ScheduleManagerState, RootState> = {
    setBusinessCategory(context, payload: BusinessCategoryModel) {
        context.commit("setBusinessCategory", payload)
    },

    setBusinessCategories(context, payload: Array<BusinessCategoryModel>) {
        context.commit("setBusinessCategories", payload)
    },

    setBusinessPurpose(context, payload: BusinessPurpose) {
        context.commit("setBusinessPurpose", payload)
    },

    setBusinessPurposeOverride(context, payload: string) {
        context.commit("setBusinessPurposeOverride", payload)
    },

    setScheduleExcludeFreeday(context, payload: boolean) {
        context.commit("setScheduleExcludeFreeday", payload)
    },

    setScheduleTemplate(context, payload: AppointmentScheduleTemplate) {
        context.commit("setScheduleTemplate", payload)
    },


    retrieveBusinessPurpose(context, payload: string) {
        
        return ScheduleManagerService.getInstance(this.getters.getUser.access.access_token).loadBusinessPurpose(payload)
            .then(value => {
                if (value.data) {
                    context.commit("setBusinessPurpose", value.data)
                }
            }) 
            .catch(error => {
                console.log(error)
            })
    },

    retrieveBusinessPurposes(context) {

        return ScheduleManagerService.getInstance(this.getters.getUser.access.access_token).loadBusinessPurposes()
            .then(value => {
                if (value.data) {
                    const publishedPurposes = value.data.filter((busnessPurpose: BusinessPurpose) => busnessPurpose.state == RessourceStateEnum.Published);
                    context.commit("setBusinessPurposes", publishedPurposes)
                }
            })
            .catch(error => {
                console.log(error)
            })
    },

    retrieveScheduleTemplate(context, payload: string) {       
        return ScheduleManagerService.getInstance(this.getters.getUser.access.access_token).loadScheduleTemplate(payload)
            .then(value => {
                if(value.data) {
                    context.commit("setScheduleTemplate", value.data)
                }
            }) 
            .catch(error => {
                console.log(error)
            })
    },

    retrieveScheduleTemplates(context) {
        return ScheduleManagerService.getInstance(this.getters.getUser.access.access_token).loadScheduleTemplates()
            .then(value => {
                if(value.data) {
                    context.commit("setScheduleTemplates", value.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    },
}