import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { PartnersState } from './types';
import { Partner } from '@/models/partner/partner';

export const getters: GetterTree<PartnersState, RootState> = {
  getPartners(state): Array<Partner> {
    return state.partners;
  },
};
