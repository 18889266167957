import { ActionTree } from 'vuex';
import { CommissionsState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { nodeMixin } from '@/mixins/node.mixin';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import Vue from 'vue';

export const actions: ActionTree<CommissionsState, RootState> = {
  loadCommittees(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/municipalite/commissions',
      tree,
    );

    if (parent) {
      parent.children?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setCommissions', parent.children);
    }
  },

  addCommission(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addCommission', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },
  deleteCommissionCommunal(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeCommission', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  updateCommission(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateCommissionCommunal', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
};
