
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { mapActions } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { build } from '@/mixins/build.mixin';
export default Vue.extend({
  props: ['injectedConfiguration'],
  components: {
    InputFormGroupText,
  },
  mixins: [build],
  data() {
    return {
      canEdit: true,
      configuration: this.injectedConfiguration,
      lockUpdate: true,
      coordinate: { lat: 0, lng: 0 },
      validate: {
        site_url: '',
      } as any,
      tab: ['site_url'],
    };
  },
  computed: {
    haveDefaultData(): boolean {
      return !!this.configuration;
    },

    creating(): boolean {
      return !!this.injectedConfiguration;
    },
    coordonnes(): string {
      this.configuration.value.localisation = this.coordinate;
      return this.coordinate.lat + ' , ' + this.coordinate.lng;
    },
  },
  created: function () {
    this.$watch('configuration', this.configurationUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('configuration', ['addConfiguration', 'updateConfiguration']),

    configurationUpdated: function () {
      this.lockUpdate = false;
    },
    init() {
      if (!this.haveDefaultData) {
        this.configuration = {
          value: {
            site_url: '',
            localisation: '',
          },
        };
        this.lockUpdate = true;
      } else {
        this.coordinate = this.configuration.value.localisation;
      }
    },
    cancel() {
      this.lockUpdate = true;
    },
    save() {
      if (this.configuration.value.site_url) {
        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'configuration',
          NodeType.content,
          this.configuration.value,
        );
        this.addConfiguration(node);
        this.lockUpdate = true;
      } else {
        this.$toast.error('Les champs doivent etre des liens');
      }
    },
    update() {
      if (this.validation() === true) {
        this.updateConfiguration(this.configuration);
        this.lockUpdate = true;
      } else {
        this.$toast.error('Les champs doivent etre des liens');
      }
    },
    edit() {
      this.canEdit = true;
    },
    validateUrl(string: string): boolean {
      if (string == '') {
        return true;
      } else {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
      }
    },
    validateData(value: string): boolean {
      let validationState = true;
      if (
        this.configuration.value[value] &&
        !this.validateUrl(this.configuration.value[value])
      ) {
        this.validate[value] = 'false';
        validationState = false;
      } else {
        this.validate[value] = '';
      }
      return validationState;
    },
    validation(): boolean {
      let validationState = true;
      for (const element of this.tab) {
        validationState = this.validateData(element);
        if (!validationState) {
          break;
        }
      }
      return validationState;
    },
    getCoordinate() {
      navigator.geolocation.getCurrentPosition((position) => {
        (this as any).coordinate = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
  beforeMount() {
    this.init();
  },
});
