
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  props: ['id', 'title', 'message'],
  methods: {
    handleOk() {
      this.$emit('handleOk');
    },
  },
});
