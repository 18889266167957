
import { Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { staticVars } from '@/mixins/mixins';
import { DataNodeCreateDto } from './dto/data-node-dto';
import { Node } from './models/node/node';
import { nodeMixin } from '@/mixins/node.mixin';
import NavbarApp from '@/components/layout/navbar-app/navbar-app.vue';

export default Vue.extend({
  mixins: [staticVars, nodeMixin],

  components: {
    NavbarApp,
  }, 
  
  computed: {
    ...mapGetters('node', ['getTree']),
  },

  methods: {
    ...mapActions('node', ['fetchAllTree', 'createNode']),
  },

  beforeMount() {
    this.fetchAllTree().then(() => {
      let nodes: Node[] = (this as any).checkRequiredAbsentNodes(
        this.$data['REQUIRED_DATA_NODES'],
        this.getTree,
      );
      if (nodes.length) {
        let baseNode: DataNodeCreateDto = {
          contents_requests: nodes,
        };
        this.createNode(baseNode);
      }

      (this as any).dispatchTree(this.getTree);
    });
  },
});
