
import { CreateMediaDto } from '@/dto/media-dto';
import Vue from 'vue';
import { mapActions } from 'vuex';
import { mediaMixin } from '@/mixins/media.mixin';
import { build } from '@/mixins/build.mixin';
import { Media } from '@/models/media/media';

export default Vue.extend({
  name: 'input-image',
  mixins: [mediaMixin, build],
  props: {
    inputPlaceholder: {
      type: String, 
      default: '',
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: 'Selectionner',
    },
    mediaName: {
      type: String,
      default: '',
    },
    isSingle: {
      type: Boolean,
      default: true,
    },
    filename: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mediatheque: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      value: null as any,
      medias: [] as Media[],
      imageName: '' as string,
    };
  },
  computed: {
    enableAddButton(): boolean {
      return this.imageName.length == 0;
    },
  },
  methods: {
    ...mapActions('media', ['createMedias']),

    uploadImage(files: File[]) {
      let name = '';
      if(this.mediatheque) name = this.imageName;
      else name = (this as any).buildName(this.filename);
      const payload: CreateMediaDto = (this as any).buildPayload(
        name,
        this.tags,
        files,
      );
      this.createMedias(payload).then((value) => {
        if (value.data) {
          if (this.isSingle) this.medias = value.data;
          else this.medias = value.data;
          this.$emit('updateMedias', this.medias, this.isSingle);
          this.$parent!.$emit('updateMedias', this.medias, this.isSingle);
          Vue.$toast('Image(s) ajouté(s)');
        }
        if (value.apiError) {
          Vue.$toast("Erreur lors de l'upload");
        }
      });
    },

    choose() {
      let file = document.createElement('input');

      file.type = 'file';
      file.accept = (this as any).accept;
      file.multiple = true;
      file.click();

      const files: File[] = [];

      file.onchange = () => {
        if (file.files) {
          for (let i = 0; i < file.files.length; i++) {
            const currentFile = file.files.item(i);
            if (currentFile) {
              files.push(currentFile);
            }
          }
        }

        this.uploadImage(files);
      };
    },
  },
});
