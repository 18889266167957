
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { selectBoxManagment } from '@/mixins/mixins';
import {
  AppointmentScheduleTemplate,
  BusinessCategoryModel,
  BusinessPurpose,
  RessourceStateEnum,
} from '@/models/schedule-manager/schedule-manager';
import ActionButton from '@/components/buttons/action-button/action-button.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import { rdvModule } from '@/models/rdv-module/rdv-module';
import InputAudio from '@/components/inputs/input-audio/input-audio.vue';
import { mediaMixin } from '@/mixins/media.mixin';
import { build } from '@/mixins/build.mixin';
import { Media } from '@/models/media/media';

export default Vue.extend({
  props: ['disableCard', 'disableColor', 'disableFontWeigth', 'module_rdv', 'medias'],
  mixins: [mediaMixin, build],
  components: {
    SelectFormGroup,
    ActionButton,
    InputAudio
  },
  data() {
    return {
      purpose: '',
      category: '',
      template: '',
      programmingStart: '',
      programmingEnd: '',
      purposeOptions: new Array<{ value: string; text: string }>(),
      categoryOptions: new Array<{ value: string; text: string }>(),
      templateOptions: new Array<{ value: string; text: string }>(),
      businessPurposeProjection: new Array<BusinessPurpose>(),
      scheduleTemplateOfCategory: new Array<AppointmentScheduleTemplate>(),
      templates: Array.isArray(this.module_rdv) ? this.module_rdv : [] as Array<rdvModule>,
      templateList: new Array<AppointmentScheduleTemplate>(),
      pisteAudio: false,
      rendezVous: false,
      openedCollapses: [] as Array<number>,
      tags: ['audio'],
      myMedias: [] as Media[]
    };
  },

  computed: {
    ...mapGetters('scheduleManager', [
      'getScheduleTemplate',
      'getScheduleTemplates',
    ]),
    ...mapGetters('scheduleManager', [
      'getBusinessPurposeSelectBox',
      'getBusinessPurpose',
      'getBusinessPurposes',
    ]),
    ...mapGetters('scheduleManager', ['getBusinessCategory']),
  },

  methods: {
    ...mapActions('scheduleManager', [
      'retrieveBusinessPurpose',
      'retrieveBusinessPurposes',
    ]),
    ...mapActions('scheduleManager', ['retriveBusinessCategory']),
    ...mapActions('scheduleManager', [
      'retrieveScheduleTemplate',
      'retrieveScheduleTemplates',
      'loadAppointmentWithSummary',
    ]),
    ...mapActions('media', ['createMedias']),

    async filterPurpose(value: string): Promise<BusinessPurpose[]> {
      return this.getBusinessPurposes.filter(
        (purpose: BusinessPurpose) => purpose.organization_unit == value,
      );
    },

    emitData() {
      this.$emit('input', this.templates);
    },

    async onPurposeSet() {
      await this.retrieveBusinessPurpose(this.purpose).then(() => {
        this.categoryOptions = selectBoxManagment.methods.categorySelectBox(
          this.getBusinessPurpose.categories.filter(
            (businessCategory: BusinessCategoryModel) =>
              businessCategory.state === RessourceStateEnum.Published,
          ),
        );
        this.emitData();
      });
    },

    async onCategorySet() {
      this.retrieveScheduleTemplates().then(() => {
        this.scheduleTemplateOfCategory = [];
        this.getScheduleTemplates.forEach(
          (template: AppointmentScheduleTemplate) => {
            this.templateList.push(template);
            if (
              template.metadata.business_reference.category_id === this.category
            ) {
              this.scheduleTemplateOfCategory.push(template);
            }
          },
        );
        this.templateOptions =
          selectBoxManagment.methods.scheduleTemplateSelectBox(
            this.scheduleTemplateOfCategory.filter(
              (template) => template.state === RessourceStateEnum.Published,
            ),
          );
        this.emitData();
      });
    },

    async onTemplateSet() {
      this.retrieveScheduleTemplate(this.template).then(() => this.emitData());
    },

    purposeSet(value: string) {
      this.purpose = value;
    },

    categorySet(value: string) {
      this.category = value;
    },

    templateSet(value: string) {
      this.template = value;
    },

    onDateStartSet(value: string) {
      this.programmingStart = value;
    },

    deleteAudio(media: Media) {
      this.$emit('deleteAudio', media);
    },
    updateMedias(medias: Media[]) {
      this.myMedias = medias;
      this.pisteAudio = false;
    },
    retrieveAudio(): Media[] {
      return (this as any).retrieveMediaByTags(
        this.tags,
        this.medias,
      );
    },
    addTemplate() {
      this.templates.push({
        purposeId: this.purpose,
        templateId: this.template,
        categoryId: this.category,
      });
      this.emitData();
      this.purpose = '';
      this.category = '';
      this.template = '';
      this.rendezVous = false;
    },
    
    deleteTemplate(idx: number) {
      this.templates = this.templates.filter((element: any, index: number) => index !== idx);
    },
    getTheme(id: string): string {
      let template = this.purposeOptions?.find(( template: any ) => template.value === id)
      if(template){
        return template.text
      }else{
        return ''
      }
    },
    getCategory(id: string, purpose: string): string {
      let categories = [] as Array<BusinessCategoryModel> | undefined;
      this.getBusinessPurposes.forEach(
        (template: BusinessPurpose) => {
          if (
            template.purpose_id === purpose
          ) {
            categories = template.categories;
          }
        },
      );
      let template = categories?.find(({ category_id }) => category_id === id)
      if(template){
        return template?.name
      }else{
        return ''
      }
      
    },
    getTemplate(id: string): string {
      let template = this.templateList?.find(({ template_id }) => template_id === id)
      if(template){
        return template?.metadata?.title
      }else{
        return ''
      }
    },
    openCollapse(index: number) {
      const isOpened = this.openedCollapses.includes(index);
      if (isOpened) {
        this.openedCollapses = this.openedCollapses.filter((i) => i !== index);
      } else {
        this.openedCollapses.push(index);
      }
    }
  },
  created() {
    this.retrieveBusinessPurposes().then(() => {
      this.purposeOptions = selectBoxManagment.methods.businessSelectBox(
        this.getBusinessPurposes,
      );
    });
    this.retrieveScheduleTemplates().then(() => {
      this.getScheduleTemplates.forEach(
        (template: AppointmentScheduleTemplate) => {
          this.templateList.push(template);
        },
      );
    });
  },
});
