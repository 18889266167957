import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/localisation/getters';
import { actions } from '@/store/modules/localisation/action';
import { mutations } from '@/store/modules/localisation/mutations';
import { LocalisationState } from './types';
export const state: LocalisationState = {
  localisation: null
};

export const localisation: Module<LocalisationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
