import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { LayoutState } from './types';

export const getters: GetterTree<LayoutState, RootState> = {
  getActiveSidebar(state): boolean {
    return state.activeSideBar;
  },

  getSidebarActiveElement(state): string {
    return state.sideBarActiveElement;
  },
};
