import { ActionTree } from 'vuex';
import { EditorialState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { nodeMixin } from '@/mixins/node.mixin';
import { NodeType } from '@/models/enums/enums';
import { Node } from '@/models/node/node';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import Vue from 'vue';

export const actions: ActionTree<EditorialState, RootState> = {
  loadEditorial(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }
    const parent = nodeMixin.methods.findNodeInTree('/mairie/editorial', tree);
    if (parent) {
      const editorial = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      editorial?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setEditorial', editorial);
    }
  },

  loadTags(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/tags',
      tree,
    );
    if (parent) {
      const contentNode = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      contentNode?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setTags', contentNode);
    }
  },

  addArticle(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
            context.commit('addEditorial', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  deleteEditorial(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeEditorial', payload);
        }
      });
  },
  updateContent(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateContent', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  updateContentChildren(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          // context.commit('updateSlide', payload);
        }
      });
  },
  addTag(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode) {
            context.commit('addTag', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  updateTag(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateTag', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  deleteTag(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeTag', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
};
