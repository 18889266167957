
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { selectBoxManagment } from '@/mixins/mixins';
import {
  AppointmentScheduleTemplate,
  BusinessCategoryModel,
  BusinessPurpose,
  RessourceStateEnum,
} from '@/models/schedule-manager/schedule-manager';
import { NodeType } from '@/models/enums/enums';
import Section from '@/components/section/section.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import ActionButton from '@/components/buttons/action-button/action-button.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { layoutMixin } from '@/mixins/layout.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { build } from '@/mixins/build.mixin';
import { Media } from '@/models/media/media';
import { rdvModule } from '@/models/rdv-module/rdv-module';
export default Vue.extend({
  name: 'RendezVous',
  mixins: [layoutMixin, mediaMixin, build],
  components: {
    Section,
    ConfirmModal,
    ActionButton,
    SelectFormGroup,
    InputFormGroupText,
  },
  data() {
    return {
      purpose: '',
      category: '',
      template: '',
      programmingStart: '',
      programmingEnd: '',
      purposeOptions: new Array<{ value: string; text: string }>(),
      categoryOptions: new Array<{ value: string; text: string }>(),
      templateOptions: new Array<{ value: string; text: string }>(),
      businessPurposeProjection: new Array<BusinessPurpose>(),
      scheduleTemplateOfCategory: new Array<AppointmentScheduleTemplate>(),
      templates: [] as Array<rdvModule>,
      templateList: new Array<AppointmentScheduleTemplate>(),
      pisteAudio: false,
      rendezVous: false,
      openedCollapses: [] as Array<number>,
      myMedias: [] as Media[],
      disableColor: false,
      disableFontWeigth: true,
      mention_complementaire: '',
      disableCard: false,
    };
  },
  computed: {
    ...mapGetters('scheduleManager', [
      'getScheduleTemplate',
      'getScheduleTemplates',
    ]),
    ...mapGetters('scheduleManager', [
      'getBusinessPurposeSelectBox',
      'getBusinessPurpose',
      'getBusinessPurposes',
    ]),
    ...mapGetters('scheduleManager', ['getBusinessCategory']),
    ...mapGetters('pagesAdmin', ['getRdvMotifs']),
  },

  watch: {
    getRdvMotifs() {
      this.templates = this.getRdvMotifs.value.templates;
    },
  },

  methods: {
    ...mapActions('scheduleManager', [
      'retrieveBusinessPurpose',
      'retrieveBusinessPurposes',
    ]),
    ...mapActions('scheduleManager', ['retriveBusinessCategory']),
    ...mapActions('scheduleManager', [
      'retrieveScheduleTemplate',
      'retrieveScheduleTemplates',
      'loadAppointmentWithSummary',
    ]),
    ...mapActions('media', ['createMedias']),
    ...mapActions('pagesAdmin', ['addRdvMotif', 'updateRdvMotif']),

    async filterPurpose(value: string): Promise<BusinessPurpose[]> {
      return this.getBusinessPurposes.filter(
        (purpose: BusinessPurpose) => purpose.organization_unit == value,
      );
    },

    async onPurposeSet() {
      await this.retrieveBusinessPurpose(this.purpose).then(() => {
        this.categoryOptions = selectBoxManagment.methods.categorySelectBox(
          this.getBusinessPurpose.categories.filter(
            (businessCategory: BusinessCategoryModel) =>
              businessCategory.state === RessourceStateEnum.Published,
          ),
        );
      });
    },

    async onCategorySet() {
      this.retrieveScheduleTemplates().then(() => {
        this.scheduleTemplateOfCategory = [];
        this.getScheduleTemplates.forEach(
          (template: AppointmentScheduleTemplate) => {
            this.templateList.push(template);
            if (
              template.metadata.business_reference.category_id === this.category
            ) {
              this.scheduleTemplateOfCategory.push(template);
            }
          },
        );
        this.templateOptions =
          selectBoxManagment.methods.scheduleTemplateSelectBox(
            this.scheduleTemplateOfCategory.filter(
              (template) => template.state === RessourceStateEnum.Published,
            ),
          );
      });
    },

    async onTemplateSet() {
      this.retrieveScheduleTemplate(this.template);
    },

    purposeSet(value: string) {
      this.purpose = value;
    },

    categorySet(value: string) {
      this.category = value;
    },

    templateSet(value: string) {
      this.template = value;
    },

    addTemplate() {
      this.templates.push({
        purposeId: this.purpose,
        templateId: this.template,
        categoryId: this.category,
        mention_complementaire: this.mention_complementaire,
      });
      let object = {
        templates: this.templates,
      };
      if (this.getRdvMotifs) {
        this.update();
      } else {
        this.save(object);
      }
    },

    save(object: Object) {
      const node = (this as any).buildCreatePayload(
        this.$data.required_nodes,
        'rdv_motifs',
        NodeType.content,
        object,
      );
      this.addRdvMotif(node).then(() => {
        this.rendezVous = false;
        this.purpose = '';
        this.category = '';
        this.template = '';
        this.mention_complementaire = '';
      });
    },

    update() {
      let obj = this.getRdvMotifs;
      obj.value = {
        templates: this.templates,
      };
      this.updateRdvMotif(obj);
    },

    cancelTemplate() {
      this.purpose = '';
      this.category = '';
      this.template = '';
      this.mention_complementaire = '';
      this.rendezVous = false;
    },

    deleteTemplate(idx: number) {
      this.templates = this.templates.filter(
        (element: any, index: number) => index !== idx,
      );
      this.update();
    },
    getTheme(id: string): string {
      let template = this.purposeOptions?.find(
        (template: any) => template.value === id,
      );
      if (template) {
        return template.text;
      } else {
        return '';
      }
    },
    getCategory(id: string, purpose: string): string {
      let categories = [] as Array<BusinessCategoryModel> | undefined;
      this.getBusinessPurposes.forEach((template: BusinessPurpose) => {
        if (template.purpose_id === purpose) {
          categories = template.categories;
        }
      });
      let template = categories?.find(({ category_id }) => category_id === id);
      if (template) {
        return template?.name;
      } else {
        return '';
      }
    },
    getTemplate(id: string): string {
      let template = this.templateList?.find(
        ({ template_id }) => template_id === id,
      );
      if (template) {
        return template?.metadata?.title;
      } else {
        return '';
      }
    },
    openCollapse(index: number) {
      const isOpened = this.openedCollapses.includes(index);
      if (isOpened) {
        this.openedCollapses = this.openedCollapses.filter((i) => i !== index);
      } else {
        this.openedCollapses.push(index);
      }
    },
  },
  created() {
    this.retrieveBusinessPurposes().then(() => {
      this.purposeOptions = selectBoxManagment.methods.businessSelectBox(
        this.getBusinessPurposes,
      );
    });
    this.retrieveScheduleTemplates().then(() => {
      this.getScheduleTemplates.forEach(
        (template: AppointmentScheduleTemplate) => {
          this.templateList.push(template);
        },
      );
    });
    if (this.getRdvMotifs) {
      this.templates = this.getRdvMotifs.value.templates;
    }
  },
});
