import Vue from 'vue';
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";

export abstract class HttpBaseService {
    protected instance: AxiosInstance
    protected token: string
    protected readonly baseUrl: string

    protected constructor(token: string, baseUrl: string) {
        this.baseUrl = baseUrl;
        this.instance = axios.create({
            baseURL: baseUrl,
        });
        this.token = token
        this.initializeRequestInterceptor();
        this.initializeResponseInterceptor();
    }

    private handleRequest = (config: AxiosRequestConfig) => {
        config.headers['Authorization'] = `Bearer ${this.token}`;
        return config;
    };

    private initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this.handleRequest);
    };

    private initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                return this.handleError(error);
            }
        );
    }

    private handleError = async (error: AxiosError) => {
        if(error.response?.status == 401) {
            this.handleUnAuthorizeError(error)
        }
        return Promise.reject(error);
    };

    private handleUnAuthorizeError = (error: AxiosError) => {
        Vue.$toast("Vous n'êtes pas autorisé à se connecter");
    }

}