import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ProfilState } from "./types";
import { ProfileService } from "@/services/profil.service";

export const actions: ActionTree<ProfilState, RootState> = {
  setIsProfilVisible(context, payload: boolean) {
    context.commit("setIsProfilVisible", payload);
  },

  retrieveProfile(context){
    ProfileService.getInstance(this.getters.getUser.access.access_token)
    .retrieveProfile()
    .then((value)=> {
      if (value.data){
        context.commit("setProfile", value.data)
      }
    })
    .catch((reason)=> {
      console.log(reason)
    })
  }
};
