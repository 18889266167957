import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ConseillersMunicipauxState } from './types';
import { ConseillerMunicipal } from '@/models/conseiller-municipal/conseiller-municipal';

export const getters: GetterTree<ConseillersMunicipauxState, RootState> = {
  getConseillersMunicipaux(state): Array<ConseillerMunicipal> {
    return state.conseillersMunicipaux;
  },
};
