import { Commission } from '@/models/commission/commission';
import { MutationTree } from 'vuex';
import { CommissionsState } from './types';

export const mutations: MutationTree<CommissionsState> = {
  setCommissions(state, payload: Array<Commission>) {
    state.commissions = payload;
  },
  addCommission(state, payload: Commission) {
    state.commissions.push(payload);
    state.commissions?.sort(function (a: any, b: any) {
      const A = new Date(a.created_at)
      const B = new Date(b.created_at)
      return A < B ? 1 : -1
    })
  },
  removeCommission(state, payload: Commission) {
    state.commissions = state.commissions.filter(
      (commission) => commission.node_id !== payload.node_id,
    );
  },
  updateCommissionCommunal(state, payload: Commission) {
    const currentCommission = state.commissions.find(
      (commission) => commission.node_id == payload.node_id,
    );

    if (currentCommission) {
      const index = state.commissions.indexOf(currentCommission);
      state.commissions[index] = payload;
    }
  },
};
