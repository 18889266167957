import { ActionTree } from 'vuex';
import { StateOfficerState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { nodeMixin } from '@/mixins/node.mixin';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import Vue from 'vue';

export const actions: ActionTree<StateOfficerState, RootState> = {
  loadStateOfficers(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }
    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/municipalite/officiers_etat',
      tree,
    );
    if (parent) {
      const stateOfficers = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      context.commit('setStateOfficers', stateOfficers);
    }
  },

  addStateOfficer(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addStateOfficer', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },

  updateStateOfficer(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateStateOfficer', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },

  deleteStateOfficer(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeStateOfficer', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
};
