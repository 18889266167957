import { Configuration } from '@/models/configuration/configuration';
import { MutationTree } from 'vuex';
import { ConfigurationState } from './types';

export const mutations: MutationTree<ConfigurationState> = {
  setConfiguration(state, payload: Configuration) {
    state.configuration = payload;
  },
  setMounted(state, payload: boolean) {
    state.value = payload;
  },
};
