
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import TipTapLiteFormModal from '@/components/edition-components/tiptap-lite-form-modal/tiptap-lite-form-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { Media } from '@/models/media/media';
import { NodeType } from '@/models/enums/enums';
import { ConseilMunicipal } from '@/models/conseil-municipal/conseil-municipal';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['conseilMunicipal'],
  components: {
    InputFormGroupText,
    TipTapLiteFormModal,
    ImageCard,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      canEdit: true,
      uploading: false,
      lockUpdate: true,
      form: {
        title: '',
        presentation: '',
        slug: ''
      },
      oldData: {} as ConseilMunicipal,
      medias: (this as any).getMediasFormNode(this.conseilMunicipal, []),
      imageTags: ['image', 'présentation'],
    };
  },
  created: function () {
    this.$watch('conseilMunicipal', this.conseilMunicipalUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('node', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('conseilMunicipal', [
      'addMunicipalCouncil',
      'updateMunicipalCouncil',
    ]),
    ...mapActions('media', ['updateMediasNode']),

    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    conseilMunicipalUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'présentation'],
        this.medias,
      );
    },
    init() {
      if (this.haveDefaultData) {
        this.oldData = this.conseilMunicipal;
        this.form = this.conseilMunicipal.value;
      }
    },
    cancel() {
      this.lockUpdate = true;
      this.init();
      this.medias = (this as any).getMediasFormNode(this.conseilMunicipal, [])
    },
    save() {
      this.form.slug = (this as any).slugify(this.form.title)
      const node = (this as any).buildCreatePayload(
        this.$data.required_nodes,
        'conseil_municipal',
        NodeType.content,
        this.form,
      );

      const mediaNode = (this as any).buildMediaPayload(
        node.contents_requests[0].navigation_path,
        this.medias,
      );

      const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

      this.addMunicipalCouncil(dto);
      this.lockUpdate = true;
    },

    update() {
      this.conseilMunicipal.value = this.form;
      this.conseilMunicipal.value.slug = (this as any).slugify(this.form.title)

      const mediaNavigationPath =
        this.conseilMunicipal.navigation_path + '/medias';
      const mediaNode = (this as any).findNodeInTree(
        mediaNavigationPath,
        this.conseilMunicipal,
      );

      mediaNode.value.medias = this.medias;

      this.updateMediasNode(mediaNode).then(() => {
        this.updateMunicipalCouncil(this.conseilMunicipal);
        this.lockUpdate = true;
      });
    },
    edit() {
      this.canEdit = true;
    },
  },
  computed: {
    ...mapGetters('media', ['getLastMediaUploaded']),
    haveDefaultData() {
      if (this.conseilMunicipal != undefined) return true;
      else return false;
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    conseilMunicipal() {
      this.init();
    },
  },
});
