import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ServiceCommunalCategoriesState } from './types';
import { Category } from '@/models/category/category';
import { ContentElement } from '@/models/content_element/content_element';

export const getters: GetterTree<ServiceCommunalCategoriesState, RootState> = {
  getServicesCommunauxCategories(state): Array<Category> {
    return state.servicesCommunauxCategories;
  },

  getServicesCommunauxSousCategories(state): Array<Category> {
    return state.servicesCommunauxSousCategories;
  },
  getServicesCommunaux(state): Array<ContentElement> {
    return state.servicesCommunaux;
  },
};
