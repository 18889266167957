import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { ScheduleManagerState } from './types'
import { AppointmentScheduleTemplate, BusinessCategoryModel, BusinessPurpose } from "@/models/schedule-manager/schedule-manager";


export const getters: GetterTree<ScheduleManagerState, RootState> = {
    getBusinessCategory(state): BusinessCategoryModel | undefined{
        return state.businessCategory
    },

    getBusinessCategories(state): Array<BusinessCategoryModel> | undefined {
        return state.businessCategories
    },

    getBusinessPurpose(state): BusinessPurpose | undefined{
        return state.businessPurpose
    },

    getBusinessPurposes(state): Array<BusinessPurpose> | undefined{
        return state.businessPurposes
    },

    getScheduleTemplate(state): AppointmentScheduleTemplate | undefined{
        return state.scheduleTemplate
    },

    getScheduleTemplates(state): Array<AppointmentScheduleTemplate> | undefined{
        return state.scheduleTemplates
    },
};