import { Mediatheque } from '@/models/mediatheque/mediatheque';
import { MutationTree } from 'vuex';
import { MediathequesState } from './types';

export const mutations: MutationTree<MediathequesState> = {
  setMediatheques(state, payload: Array<Mediatheque>) {
    state.mediatheques = payload;
  },
  addMedia(state, payload: Mediatheque) {
    state.mediatheques.unshift(payload);
  },
  removeMediatheque(state, payload: Mediatheque) {
    state.mediatheques = state.mediatheques.filter(
      (mediatheque) => mediatheque.node_id !== payload.node_id,
    );
  },
};
