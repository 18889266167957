import { ApiResponse } from "@/models/http/ApiResponse";
import { HttpBaseService } from "@/services/httpBase.service";
import { Profile } from "@/models/profil/profil";


export class ProfileService extends HttpBaseService {
    private static classInstance : ProfileService;
    static profile_mock_url = "https://my.api.mockaroo.com/";

    constructor(token: string) {
        super(token, ProfileService.profile_mock_url);
    }

    public static getInstance(token: string): ProfileService {
        if (!this.classInstance) {
            this.classInstance = new ProfileService(token);
          }
          return this.classInstance;
    }

    public async retrieveProfile(): Promise< ApiResponse<Profile[]> > {
        return this.instance
            .get("working_space.json?key=a1d55460")
            .then((response)=> {
                const apiResponse = new ApiResponse<Profile[]>();
                switch (response.status) {
                    case 204: {
                      return apiResponse;
                    }
                    default: {
                      apiResponse.data = response.data;
                      return apiResponse;
                    }
                  }
            })
            .catch((error) => {
                return new ApiResponse<Profile[]>();
            })
    }
}