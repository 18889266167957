export enum NodeType {
  content = 'content',
  article = 'article',
  media = 'media',
}

export enum MediaType {
  document = 'document',
  image = 'images',
}

export enum Gender {
  male = 'M.',
  female = 'Mme',
}
