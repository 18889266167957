export const dateMixin = {
  methods: {
    formatDate(data: Date): string {
        const originalDate = new Date(data);

        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
        const day = originalDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    },
  },
};
