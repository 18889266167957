
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import TipTapLiteFormModal from '@/components/edition-components/tiptap-lite-form-modal/tiptap-lite-form-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { Media } from '@/models/media/media';
import { NodeType } from '@/models/enums/enums';
import { Executif } from '@/models/executif/executif';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import { build } from '@/mixins/build.mixin';
import { mediaMixin } from '@/mixins/media.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['executif'],
  components: {
    InputFormGroupText,
    TipTapLiteFormModal,
    ImageCard,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      canEdit: true,
      uploading: false,
      lockUpdate: true,
      form: {
        title: '',
        presentation: '',
        slug: ''
      },
      oldData: {} as Executif,
      medias: (this as any).getMediasFormNode(this.executif, []),
      imageTags: ['image', 'présentation'],
    };
  },
  created: function () {
    this.$watch('executif', this.executifUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('node', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('executif', [
      'addExecutif',
      'updateExecutif',
    ]),
    ...mapActions('media', ['updateMediasNode']),

    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri !== oneImage.uri,
      );
    },
    updateMedias(medias: Media[]) {
      this.medias = medias;
    },
    executifUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'présentation'],
        this.medias,
      );
    },
    init() {
      if (this.haveDefaultData) {
        this.oldData = this.executif;
        this.form = this.executif.value;
      }
    },
    cancel() {
      this.lockUpdate = true;
      this.init();
      this.medias = (this as any).getMediasFormNode(this.executif, [])
    },
    save() {
      if(this.form.title == ''){
        this.$toast.error("Veuillez renseigner au moins le titre");
      }else{
        this.form.slug = (this as any).slugify(this.form.title)
        const node = (this as any).buildCreatePayload(
          this.$data.required_nodes,
          'executif',
          NodeType.content,
          this.form,
        );

        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );

        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

        this.addExecutif(dto).then(() => {
          this.lockUpdate = true;
        });
      }
    },

    update() {
      if(this.executif.value.title == ''){
        this.$toast.error("Veuillez renseigner au moins le titre");
      }else{
        this.executif.value = this.form;
        this.executif.value.slug = (this as any).slugify(this.executif.value.title)

        const mediaNavigationPath =
          this.executif.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.executif,
        );

        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateExecutif(this.executif);
          this.lockUpdate = true;
        });
      }
    },
    edit() {
      this.canEdit = true;
    },
  },
  computed: {
    ...mapGetters('media', ['getLastMediaUploaded']),
    haveDefaultData() {
      if (this.executif != undefined) return true;
      else return false;
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    executif() {
      this.init();
    },
  },
});
