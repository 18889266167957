
import { mapActions, mapGetters } from 'vuex';
import { Vue } from 'vue-property-decorator';
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import Section from '@/components/section/section.vue';
import Accordion from '@/components/accordion/accordion.vue';
import AccordionItem from '@/components/accordion/accordion-item/accordion-item.vue';
import OfficierEtatForm from '@/components/forms/officier-etat-form/officier-etat-form.vue';
import CommissionsCommunalesForm from '@/components/forms/commissions-communales-form/commissions-communales-form.vue';
import ConseilMunicipalPresentation from '@/components/forms/conseil-municipal-presentation/conseil-municipal-presentation.vue';
import ExecutifPresentation from '@/components/forms/executif-presentation/executif-presentation.vue'
import ConseillerMunicipalForm from '@/components/forms/conseiller-municipal-form/conseiller-municipal-form.vue';
import SlideForm from '@/components/forms/slide-form/slide-form.vue';
import PartnerForm from '@/components/forms/partner-form/partner-form.vue';
import LocalisationForm from '@/components/forms/localisation-form/localisation-form.vue';
import ReseauxSociauxForm from '@/components/forms/reseaux-sociaux-form/reseaux-sociaux-form.vue';
import OrganigrammeForm from '@/components/forms/organigramme-form/organigramme-form.vue';
import ConfigurationForm from '@/components/forms/configuration-form/configuration-form.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import { nodeMixin } from '@/mixins/node.mixin';
import { build } from '@/mixins/build.mixin';
import { StateOfficer } from '@/models/state-officer/state-officer';
import { Media } from '@/models/media/media';
import { ConseillerMunicipal } from '@/models/conseiller-municipal/conseiller-municipal';
import { Commission } from '@/models/commission/commission';
import Table from '@/components/table/table.vue';
import { layoutMixin } from '@/mixins/layout.mixin';
export default Vue.extend({
  name: 'Municipalite',
  components: {
    Tab,
    TabItem,
    Accordion,
    AccordionItem,
    Section,
    OfficierEtatForm,
    CommissionsCommunalesForm,
    ConseilMunicipalPresentation,
    ConseillerMunicipalForm,
    SlideForm,
    PartnerForm,
    LocalisationForm,
    ReseauxSociauxForm,
    OrganigrammeForm,
    ConfirmModal,
    ExecutifPresentation,
    ConfigurationForm,
    Table
  },
  mixins: [nodeMixin, build, layoutMixin],
  data() {
    return {
      pageTitle: 'Municipalite',
      tabIndex: 0,
      node_id: '',

      addOfficierCivilForm: false,
      activeOfficierEtat: 0,

      addCommission: false,
      activeCommissionCmmunal: 0,

      addConseillerMunicipalForm: false,
      activeConseillerMunicipal: 0,

      addSlide: false,
      activeSlide: 0,

      addMediatheque: false,
      activeMediatheque: 0,

      addPartner: false,
      activePartner: 0,
      stateOfficer: null as any,
      conseillerMunicipal: null as any,
      commissionCommunal: null as any,
      slide: null as any,
      partner: null as any,
    };
  },
  computed: {
    ...mapGetters('node', ['getTree']),
    ...mapGetters('stateOfficer', ['getStateOfficers']),
    ...mapGetters('commissions', ['getCommissions']),
    ...mapGetters('conseilMunicipal', ['getConseilMunicipal']),
    ...mapGetters('conseillersMunicipaux', ['getConseillersMunicipaux']),
    ...mapGetters('slides', ['getSlides']),
    ...mapGetters('partners', ['getPartners']),
    ...mapGetters('localisation', ['getLocalisation']),
    ...mapGetters('reseaux', ['getMounted']),
    ...mapGetters('reseaux', ['getReseaux']),
    ...mapGetters('organigramme', ['getOrganigramme']), 
    ...mapGetters('configuration', ['getConfiguration']), 
    ...mapGetters('executif', ['getExecutif']),
  },
  methods: {
    ...mapActions('stateOfficer', ['deleteStateOfficer']),
    ...mapActions('conseillersMunicipaux', ['deleteConseillerMunicipal']),
    ...mapActions('commissions', ['deleteCommissionCommunal']),
    ...mapActions('slides', ['deleteSlider']),
    ...mapActions('partners', ['deletePartner']),

    changePagTitle(pageTitle: string) {
      (this as any).setPageTitle(pageTitle);
    },

    toggleAddOfficierCivilForm(show: boolean) {
      this.addOfficierCivilForm = show;
      this.activeOfficierEtat = 0;
    },

    toggleAddConseillerMunicipalForm(show: boolean) {
      this.addConseillerMunicipalForm = show;
      this.activeConseillerMunicipal = 0;
    },

    toggleAddCommission(show: boolean) {
      this.addCommission = show;
      this.activeCommissionCmmunal = 0;
    },

    toggleAddSlide(show: boolean) {
      this.addSlide = show;
      this.activeSlide = 0;
    },
    toggleAddMediatheque(show: boolean) {
      this.addMediatheque = show;
      this.activeMediatheque = 0;
    },
    toggleAddPartner(show: boolean) {
      this.addPartner = show;
      this.activePartner = 0;
    },

    handleDelete(nodeId: string) {
      this.$bvModal.show(nodeId);
    },

    handleOk() {
      return;
    },

    getUri(slide: any) {
      if (slide?.children != undefined) {
        if (slide?.children.length != 0)
          return slide.children[0].value.medias[0].uri;
        return null;
      }
    },

    buildStateOfficerHeaders(stateOfficer: StateOfficer) {
      return [
        stateOfficer.value.civility + ' ' + stateOfficer.value.last_name,
        stateOfficer.value.first_name,
        'Officier de mairie',
        stateOfficer.value.title,
      ];
    },

    buildMunicipalCouncilHeaders(municipalCouncillor: ConseillerMunicipal) {
      return [
        municipalCouncillor.value.civility +
          ' ' +
          municipalCouncillor.value.last_name,
        municipalCouncillor.value.first_name,
        'Conseiller',
        municipalCouncillor.value.role,
        municipalCouncillor.value.profession,
      ];
    },

    buildCommissionsHeaders(commission: Commission) {
      return [
        commission.value.commission_name,
        commission.value.presentation.text,
        commission.value.members?.length.toString(),
      ];
    },

    deleteStateOfficers(stateOfficer: Node) {
      this.deleteStateOfficer(stateOfficer);
    },

    deleteMunicipalCouncillors(conseillerMunicipal: Node) {
      this.deleteConseillerMunicipal(conseillerMunicipal);
    },

    deleteCommission(commissionCommunal: Node) {
      this.deleteCommissionCommunal(commissionCommunal);
    },

    deleteSlide(slide: Node) {
      this.deleteSlider(slide);
    },

    deletePartners(partner: Node) {
      this.deletePartner(partner);
    },
  },
  beforeMount() {
    this.changePagTitle(this.pageTitle);
  },
});
