import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { NodeState } from './types';
import { Node } from '@/models/node/node';

export const getters: GetterTree<NodeState, RootState> = {
  getNodes(state): Array<Node> {
    return state.nodes;
  },

  getTree(state): Node | undefined {
    return state.tree;
  },
};
