import { Module } from "vuex";
import { RootState } from "@/store/types";
import {getters} from "@/store/modules/schedule-manager/getters";
import {actions} from "@/store/modules/schedule-manager/action";
import {mutations} from "@/store/modules/schedule-manager/mutations";
import { ScheduleManagerState } from "./types";
export const state: ScheduleManagerState = {
    businessCategory: undefined,
    businessCategories: [],
    businessPurpose: undefined,
    businessPurposes: [],
    scheduleTemplate: undefined,
    scheduleTemplates: [],
};

export const scheduleManager: Module<ScheduleManagerState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};