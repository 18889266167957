import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ConseilMunicipalState } from './types';
import { ConseilMunicipal } from '@/models/conseil-municipal/conseil-municipal';

export const getters: GetterTree<ConseilMunicipalState, RootState> = {
  getConseilMunicipal(state): ConseilMunicipal | undefined {
    return state.conseilMunicipal;
  },
};
