import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { OrganigrammeState } from './types';
import { Organigramme } from '@/models/organigramme/organigramme';

export const getters: GetterTree<OrganigrammeState, RootState> = {
  getOrganigramme(state): Organigramme | null {
    return state.organigramme;
  },
};
