
import { Vue } from 'vue-property-decorator';
import { textTransform } from '@/mixins/mixins';
import ActionButton from '@/components/buttons/action-button/action-button.vue';
import TipTapLite from '@/components/edition-components/tiptap-lite/tiptap-lite.vue';
import InputFormGroupTextOnly from '@/components/inputs/input-form-group-text-only/input-form-group-text-only.vue';
export default Vue.extend({
  props: [
    'title',
    'defaultData',
    'editable',
    'disableFontWeigth',
    'disableColor',
  ],
  components: {
    ActionButton,
    TipTapLite,
    InputFormGroupTextOnly,
  },
  data() {
    return {
      uid: '',
      html: '',
      text: '',
      editorData: null as any,
    };
  },
  methods: {
    init() {
      this.html = this.defaultData?.html;
      if (this.defaultData?.text)
        this.text = textTransform.methods.subString(this.defaultData.text, 30);
    },
    handleOk() {
      this.$emit('input', this.editorData);
      this.html = this.editorData.html;
      this.text = this.editorData.text;
    },
    showModal() {
      this.$bvModal.show(this.uid);
    },
    edit() {
      if (this.editable) this.showModal();
    },
    del() {
      if (this.editable) {
        this.editorData = null;
        this.html = '';
        this.text = '';
        this.$emit('input', this.editorData);
      }
    },
  },
  beforeMount() {
    this.init();
    this.uid = Math.random().toString();
  },
  watch: {
    defaultData() {
      this.init();
    },
  },
});
