
import Vue from "vue";
import NavLink from './nav-link/nav-link.vue';
export default Vue.extend({
  name: "SideBarApp",
  components: {
    NavLink,
  },
  props:{
    /**
     * Sets the menus and submenus in sidebar
     */
    menus:{
      type: Array,
      default: () => []
    },
    /**
     * Submenu open close state
     */
    isOpen:{
      type: Boolean,
      default: true
    },
    /**
     * Show menus
     */
    showmenu:{
      type: Boolean,
      default: true,
    },
    /**
     * Show SideBar
     */
    showSide:{
      type: Boolean,
      default: true,
    },
    /**
     * Sets icons for menus and submenus
     */
    icon:{
      type: String,
      default: ""
    },
    /**
     * Sets link (href tag) for the menus and submenus in sidebar
     */
    link:{
      type: String,
      default: ""
    },
    /**
     * Menu title
     */
    title:{
      type: String,
      default: ""
    },
  },
  data() {
    return {
      showside: this.showSide,
    };
  },
  methods: {
    /**
     * Toggle sidebar
     */
    toggleSidebar(): any {
      this.showside = !this.showside;
    },
  },
});
