import { ActionTree } from 'vuex';
import { MediaState } from './types';
import { RootState } from '@/store/types';
import { MediaNodeService } from '@/services/media-node.service';
import { CreateMediaDto } from '@/dto/media-dto';
import { Media } from '@/models/media/media';
import Vue from 'vue';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import { NodeService } from '@/services/node.service';

export const actions: ActionTree<MediaState, RootState> = {
  createMedia(context, payload: CreateMediaDto) {
    return MediaNodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        const data: Media = value.data as Media;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  createMedias(context, payload: CreateMediaDto) {
    return MediaNodeService.getInstance(this.getters.getUser.access.access_token).create(payload);
  },

  updateMediasNode(context, payload: DataNodeUpdateDto) {
    return NodeService.getInstance('')
      .update(payload)
      .then((value) => {
        if (value.data) {
          console.log(value);
        }
        if (value.apiError) {
          console.log(value);
        }
      });
  },
};
