import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import SideBarPlugin from "@/plugins";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueSocialSharing from 'vue-social-sharing'
import { PluginOptions } from 'vue-toastification/dist/types/src/types';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/common/style/app.scss';
import shield from "@/plugins/shield/shield";

Vue.use(VueSocialSharing);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(SideBarPlugin);

const options: PluginOptions = {};

Vue.use(Toast, options);

Vue.config.productionTip = false;

const containerSelector = '#appPlace'

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h: any) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        },
      });
    },
    router,
    store,
    el: containerSelector
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export function mount(props: any): any {
    Vue.use(shield, props.tokens)
    return vueLifecycles.mount(props);
}
export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [
      containerSelector
    ],
  }
};
