import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { ProfilState } from "./types";
import { Profile } from "@/models/profil/profil";

export const getters: GetterTree<ProfilState, RootState> = {
  getIsProfilVisible(state): boolean {
    return state.isProfilVisible;
  },
  getProfileMock(state): Profile[] {
    return state.profileMock
  }
};
