import { ConseillerMunicipal } from '@/models/conseiller-municipal/conseiller-municipal';
import { MutationTree } from 'vuex';
import { ConseillersMunicipauxState } from './types';

export const mutations: MutationTree<ConseillersMunicipauxState> = {
  setConseillersMunicipaux(state, payload: Array<ConseillerMunicipal>) {
    state.conseillersMunicipaux = payload;
  },
  addConseillerMunicipal(state, payload: ConseillerMunicipal) {
    state.conseillersMunicipaux.unshift(payload);
  },
  addMunicipalCouncillors(state, payload: ConseillerMunicipal) {
    state.conseillersMunicipaux.unshift(payload);
  },
  removeConseillerMunicipal(state, payload: ConseillerMunicipal) {
    state.conseillersMunicipaux = state.conseillersMunicipaux.filter(
      (conseillers) => conseillers.node_id !== payload.node_id,
    );
  },
  updateConseillerMunicipal(state, payload: ConseillerMunicipal) {
    const currentConseiller = state.conseillersMunicipaux.find(
      (conseiller) => conseiller.node_id == payload.node_id,
    );

    if (currentConseiller) {
      const index = state.conseillersMunicipaux.indexOf(currentConseiller);
      state.conseillersMunicipaux[index] = payload;
    }
  },
};
