import { ActionTree } from 'vuex';
import { DocumentsState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import { nodeMixin } from '@/mixins/node.mixin';
import { Node } from '@/models/node/node';
import { NodeType } from '@/models/enums/enums';
import Vue from 'vue';

export const actions: ActionTree<DocumentsState, RootState> = {
  loadDocumentCategories(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/bibliotheque/bibliotheque_categories',
      tree,
    );
    const docParent = nodeMixin.methods.findNodeInTree(
      '/mairie/bibliotheque/bibliotheque_medias',
      tree,
    );
    if (parent) {
      const contentNode = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      contentNode?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setDocumentCategories', contentNode);
      /* const documentCategories: Array<Category> =
        contentNode as Array<Category>;
      const documents: Array<Document> = [];
      documentCategories.forEach((documentCategorie) => {
        documentCategorie.children!.forEach((document) => {
          if (document.node_type == 'content') {
            document.category = documentCategorie.name;
            document.category_name = documentCategorie.value.name;
            document.medias = extractNode.methods.extractMediaNode(
              document.children!,
              'medias',
            );
            documents.push(document);
          }
        });
      });
      documents?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setDocuments', documents); */
    }
    if (docParent) {
      const contentNode = docParent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      contentNode?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setDocuments', contentNode);
    }
  },
  addRubrique(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode) {
            context.commit('addDocumentCategories', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  updateRubrique(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateCategory', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  updateDocument(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateDocument', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  addDocument(context, payload) {
    return NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const mediaNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.media,
          );
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode && mediaNode) {
            contentNode.children?.push(mediaNode);
          }
          context.commit('addDocuments', contentNode);
          Vue.$toast('Ajout effectué avec succès');
        }
      });
  },
  deleteLibraryCategory(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeLibraryCategory', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  deleteLibraryDocument(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeLibraryDocument', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
};
