import { ConseilMunicipal } from '@/models/conseil-municipal/conseil-municipal';
import { MutationTree } from 'vuex';
import { ConseilMunicipalState } from './types';

export const mutations: MutationTree<ConseilMunicipalState> = {
  setConseilMunicipal(state, payload: ConseilMunicipal) {
    state.conseilMunicipal = payload;
  },
  updateCouncil(state, payload: ConseilMunicipal) {
    state.conseilMunicipal = payload;
  },
};
