import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/admin/getters';
import { actions } from '@/store/modules/admin/actions';
import { mutations } from '@/store/modules/admin/mutations';
import { AdminState } from './types';

export const state: AdminState = {
  sortBy: '',
  pageTitle: ''
};

export const admin: Module<AdminState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
