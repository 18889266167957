
import Vue from 'vue';
export default Vue.extend({
  props: [
    'inputId',
    'inputDisabled',
    'label',
    'value',
    'options',
    'align',
    'disableFontWeigth',
    'disableColor',
    'validate'
  ],
  data() {
    return {
      selected: null as any,
      inputState: ''
    };
  },
  computed: {
    state(): boolean | null {
      if (this.inputState === 'true'){
        return true
      }else if(this.inputState === 'false'){
        return false
      }
      return null
    }
  },
  watch: {
    selected() {
      this.$emit('input', this.selected);
      this.$emit('change');
    },
    value() {
      if (this.value) this.selected = this.value;
    },
    'validate': function validateInput(state: string){
                  this.inputState = state
                },
  },
  beforeMount() {
    if (this.value) this.selected = this.value;
  },
});
