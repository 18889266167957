
  import { Vue } from 'vue-property-decorator';
  import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
  import { mapActions, mapGetters } from 'vuex';
  import { NodeType } from '@/models/enums/enums';
  import { build } from '@/mixins/build.mixin';
  import { Category } from '@/models/category/category';
  export default Vue.extend({
    props: ['injectedTag'],
    components: {
      InputFormGroupText,
    },
    mixins: [build],
    data() {
      return {
        node_id: '',
        tag: this.injectedTag,
        lockUpdate: true,
        validate: {
          name: '',
          desc: ''
        },
      };
    },
    created: function () {
      this.$watch('tag', this.categoryUpdated, {
        deep: true,
      });
    },
    computed: {
  
      haveDefaultData(): boolean {
        return !!this.tag;
      },
  
      creating(): boolean {
        return !!this.injectedTag;
      },
    },
    methods: {
      ...mapActions('dataNode', ['createNode', 'updateNode']),
      ...mapActions('editorial', ['addTag', 'updateTag']),
  
      categoryUpdated() {
        this.lockUpdate = false;
      },
      init() {
        if (!this.haveDefaultData) {
          this.tag = { value: { name: '', desc: '', slug: '' } };
        }
      },
      cancel() {
        this.$emit('cancel');
      },
      validation(): boolean{
        let validationState = true
        if(this.tag.value.name === '') {
          this.validate.name = 'false'
          validationState = false
        }else{
          this.validate.name = ''
        }
        if(this.tag.value.desc === '') {
          this.validate.desc = 'false'
          validationState = false
        }else{
          this.validate.desc = ''
        }
        return validationState
      },
      save() {
        const name = this.tag.value.name.toLowerCase();
        const defineName = name.replace(/\s/g, '_');
        const nameCleaned = defineName.replace(/[^a-zA-Z0-9_-]/g,'');
  
        if (this.validation() === true) {
          this.tag.value.slug = (this as any).slugify(this.tag.value.name)
          const node = (this as any).buildCreatePayloadWithName(
            this.$data.required_nodes,
            'tags',
            NodeType.content,
            this.tag.value,
            nameCleaned,
          );
          this.addTag(node).then(() => {
            this.validation();
            this.$emit('cancel');
          });
        } else {
          this.$toast.error('Veuillez remplir les champs');
        }
      },
      update() {
        if (this.validation() === true) {
          this.tag.value.slug = (this as any).slugify(this.tag.value.name)
          this.updateTag(this.tag).then(() => {
            this.validation();
            this.$emit('cancel');
          });
        } else {
          this.$toast.error('Veuillez remplir les champs');
        }
      },
    },
    beforeMount() {
      this.init();
    },
    watch: {
      category() {
        this.init();
      },
    },
  });
  