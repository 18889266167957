import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { StateOfficerState } from './types';
import { StateOfficer } from '@/models/state-officer/state-officer';
import { Media } from '@/models/media/media';

export const getters: GetterTree<StateOfficerState, RootState> = {
  getStateOfficers(state): Array<StateOfficer> {
    return state.stateOfficers;
  },

  getStateOfficerMedias(state): Media[] {
    return state.stateOfficerMedias;
  },
};
