
import { Vue } from 'vue-property-decorator';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import ActionButton from '@/components/buttons/action-button/action-button.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'Table',
  props: [
    'fields',
    'items',
    'addFormIsShown',
    'formRoutes',
    'editRoute',
    'filterOptions',
    'etat'
  ],
  components: {
    SelectFormGroup,
    ActionButton,
  },
  data() {
    return {
      filter: null,
      filterType: null,
      filterOn: [] as any,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortDesc: true,
      isVisible: false,
      node_id: '',
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      element: null as any,
    };
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  computed: {
    ...mapGetters('configuration', ['getConfiguration']), 
    hasMediaImageHeader(): any {
      return this.fields.some((field: { key: string; }) => field.key === 'media_image');
    },
  },
  methods: {
    onFiltered(filteredItems: any) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    showAddForm() {
      if (this.formRoutes && this.formRoutes.length == 1)
        this.$router.push(this.formRoutes[0].route);
      else this.$emit('showAddForm');
    },
    navigate(route: string) {
      this.$router.push(route);
    },
    edit(item: any) {
      if (this.formRoutes && this.formRoutes.length == 1)
        this.$router.push(this.formRoutes[0].route + '/' + item.node_id);
      else if (this.formRoutes && this.formRoutes.length > 1)
        this.$router.push(this.editRoute + '/' + item.node_id);
      else this.$emit('edit', item);
    },
    deleteItem(item: any) {
      this.$emit('delete', item);
    },
    shareItem(item: any) {
      this.node_id = item.node_id
      this.isVisible = !this.isVisible
    },
    shareLink(type: string, slug: string): string{
      if(type === 'editorial'){
        return this.getConfiguration?.value.site_url+'/articles/'+slug
      }else{
        return this.getConfiguration?.value.site_url+'/services/'+slug
      }
    },
    getUri(slide: any) : string | null | undefined {
      if (slide?.children != undefined) {
        if (slide?.children.length != 0)
          return slide.children[0].value.medias[0].uri;
        return null;
      }
    },
  },
  watch: {
    items() {
      this.totalRows = this.items.length;
    },
    filterType() {
      this.filterOn = [this.filterType];
    },
  },
});
