import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/partners/getters';
import { actions } from '@/store/modules/partners/action';
import { mutations } from '@/store/modules/partners/mutations';
import { PartnersState } from './types';
export const state: PartnersState = {
  partners: [],
};

export const partners: Module<PartnersState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
