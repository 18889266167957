
  import { Vue } from 'vue-property-decorator';
  import Section from '@/components/section/section.vue';
  import Table from '@/components/table/table.vue';
  import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
  import TagForm from '@/components/forms/tag-form/tag-form.vue';
  import { mapActions, mapGetters } from 'vuex';
  import { Category } from '@/models/category/category';
  import { layoutMixin } from '@/mixins/layout.mixin';
  export default Vue.extend({
    name: 'Tags',
    mixins: [layoutMixin],
    components: {
      Section,
      Table,
      TagForm,
      ConfirmModal,
    },
    data() {
      return {
        tabIndex: 0,
        tagId: '',
        id: '',
        tagFormIsShown: false,
        tagEtat: true,
  
        selectedTag: null as any,
        tagsFilterOptions: [
          { value: null, text: 'Veuillez selectionner le filtre' },
          { value: 'name', text: 'Tag' },
          { value: 'desc', text: 'Description' },
        ],
      };
    },
    computed: {
      ...mapGetters('editorial', ['getTags',]),
    },
    methods: {
      ...mapActions('dataNode', ['deleteNode']),
      ...mapActions('editorial', [
        'deleteTag',
      ]),

      editTag(selectedTag: Category) {
        this.selectedTag = selectedTag;
        this.showTagForm();
      },
  
      showTagForm() {
        this.tagFormIsShown = true;
        this.tagEtat = false
      },

      cancelTagForm() {
        this.tagFormIsShown = false;
        this.selectedTag = null;
        this.tagEtat = true
      },
  
      confirmTag(selectedTag: Category) {
        this.$bvModal.show(this.tagId);
        this.selectedTag = selectedTag;
      },

      del() {
        this.deleteTag(this.selectedTag);
      },
    },
    beforeMount() {
      this.tagId = Math.random().toString();
    },
  });
  