import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/configuration/getters';
import { actions } from '@/store/modules/configuration/action';
import { mutations } from '@/store/modules/configuration/mutations';
import { ConfigurationState } from './types';
export const state: ConfigurationState = {
  configuration: null,
  value: false,
};

export const configuration: Module<ConfigurationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
