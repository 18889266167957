import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/conseillers-municipaux/getters';
import { actions } from '@/store/modules/conseillers-municipaux/action';
import { mutations } from '@/store/modules/conseillers-municipaux/mutations';
import { ConseillersMunicipauxState } from './types';
export const state: ConseillersMunicipauxState = {
  conseillersMunicipaux: [],
};

export const conseillersMunicipaux: Module<
  ConseillersMunicipauxState,
  RootState
> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
