
import Vue from 'vue';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import RadioFormGroup from '@/components/inputs/radio-form-group/radio-form-group.vue';
import TipTapLiteFormModal from '@/components/edition-components/tiptap-lite-form-modal/tiptap-lite-form-modal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Media } from '@/models/media/media';
import { NodeType } from '@/models/enums/enums';
import { TiptapData } from '@/models/tiptap-data/tiptap-data';
import { build } from '@/mixins/build.mixin';
import ImageCard from '@/components/inputs/image-card/image-card.vue';
import DocumentCard from '@/components/inputs/document-card/document-card.vue';
import { mediaMixin } from '@/mixins/media.mixin';
import { Gender } from '@/models/enums/enums';
import { nodeMixin } from '@/mixins/node.mixin';
export default Vue.extend({
  props: ['injectedStateOfficer'],
  components: {
    DocumentCard,
    ImageCard,
    InputFormGroupText,
    RadioFormGroup,
    TipTapLiteFormModal,
  },
  mixins: [build, mediaMixin, nodeMixin],
  data() {
    return {
      uploading: false,
      validate: {
        title: '',
        last_name: '',
        first_name: '',
      },
      civilities: [
        { value: Gender.male, text: 'M.' },
        { value: Gender.female, text: 'Mme' },
      ],
      imageTags: ['image', 'profile'],
      documentTags: ['document'],
      medias: (this as any).getMediasFormNode(this.injectedStateOfficer, []),
      lockUpdate: true,
      stateOfficer: this.injectedStateOfficer,
    };
  },
  computed: {
    ...mapGetters('media', ['getLastMediaUploaded']),
    ...mapGetters('stateOfficer', ['getStateOfficers']),

    haveDefaultData(): boolean {
      return !!this.stateOfficer;
    },

    creating(): boolean {
      return !!this.injectedStateOfficer;
    },
  },
  created: function () {
    this.$watch('stateOfficer', this.stateOfficerUpdated, {
      deep: true,
    });
    this.$watch('medias', this.mediasUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('stateOfficer', ['addStateOfficer', 'updateStateOfficer']),
    ...mapActions('media', ['updateMediasNode']),
    ...mapMutations('stateOfficer', ['addStateOfficerMedias']),

    validation(): boolean{
      let validationState = true
      if(this.stateOfficer.value.title === '') {
        this.validate.title = 'false'
        validationState = false
      }else{
        this.validate.title = ''
      }
      if(this.stateOfficer.value.first_name === '') {
        this.validate.first_name = 'false'
        validationState = false
      }else{
        this.validate.first_name = ''
      }
      if(this.stateOfficer.value.last_name === '') {
        this.validate.last_name = 'false'
        validationState = false
      }else{
        this.validate.last_name = ''
      }
      return validationState
    },

    init() {
      if (!this.haveDefaultData) {
        this.stateOfficer = {
          value: {
            civility: 'M.' as string,
            officier: false as boolean,
            title: '' as string,
            last_name: '' as string,
            first_name: '' as string,
            biography: {} as TiptapData,
            speech: {} as TiptapData,
            slug: ''
          },
        };
        this.civilities = [
          { value: Gender.male, text: 'M.' },
          { value: Gender.female, text: 'Mme' },
        ];
        this.imageTags = ['image', 'profile'];
        this.documentTags = ['document'];
        this.uploading = false;
        this.lockUpdate = true;
        this.medias = (this as any).getMediasFormNode(this.injectedStateOfficer, [])
      }
    },
    
    haveMayorStateOfficer(): boolean {
      let table = this.getStateOfficers.filter((element: { value: { officier: boolean; }; }) => element.value.officier == true)
      if(table.length !== 0) {
        if(this.creating){
          if(table[0].node_id == this.injectedStateOfficer.node_id)
            return true
          else
            return true
        }else
          return false
      }else return true
    },

    stateOfficerUpdated() {
      this.lockUpdate = false;
    },
    mediasUpdated() {
      this.lockUpdate = false;
    },
    deleteImage(oneImage: Media) {
      this.medias = this.medias.filter(
        (media: Media) => media.uri != oneImage.uri,
      );
    },
    updateMedias(media: Media[]) {
      this.medias = (this as any).updateMediasByTags(this.medias, media);
    },
    updateDocuments(documents: Media[]) {
      this.medias = (this as any).updateMediasByTags(this.medias, documents);
    },
    retrieveImage() {
      return (this as any).retrieveMediaByTags(
        ['image', 'profile'],
        this.medias,
      );
    },
    
    retrieveDocs() {
      return (this as any).retrieveMediaByTags(
        ['document'],
        this.medias,
      );
    },

    onCivilityUpdate(civility: string) {
      this.stateOfficer.value.civility = civility;
    },
    cancel() {
      this.lockUpdate = true;
      this.stateOfficer = this.injectedStateOfficer;
      this.medias = (this as any).getMediasFormNode(
        this.injectedStateOfficer,
        [],
      );
      this.$emit('cancelAddOfficierCivil');
    },
    add() {
      const name = this.stateOfficer.value.title.toLowerCase();
      const defineName = name.replace(/\s/g, '_');
      const nameCleaned = defineName.replace(/[^a-zA-Z0-9_-]/g,'');
      this.stateOfficer.value.slug = (this as any).slugify(this.stateOfficer.value.last_name)
      if (this.validation() === true) {
        const node = (this as any).buildCreatePayloadWithName(
          this.$data.required_nodes,
          'officiers_etat',
          NodeType.content,
          this.stateOfficer.value,
          nameCleaned,
        );
        const mediaNode = (this as any).buildMediaPayload(
          node.contents_requests[0].navigation_path,
          this.medias,
        );
        const dto = (this as any).buildPayloadWithMedia(node, mediaNode);
        this.addStateOfficer(dto);
        this.validation()
        this.$emit('cancelAddOfficierCivil');
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
    update() {
      if (this.validation() === true) {
        this.stateOfficer.value.slug = (this as any).slugify(this.stateOfficer.value.last_name)
        const mediaNavigationPath = this.stateOfficer.navigation_path + '/medias';
        const mediaNode = (this as any).findNodeInTree(
          mediaNavigationPath,
          this.stateOfficer,
        );
        mediaNode.value.medias = this.medias;

        this.updateMediasNode(mediaNode).then(() => {
          this.updateStateOfficer(this.stateOfficer);
          this.lockUpdate = true;
          this.validation()
          this.$emit('cancelAddOfficierCivil');
        });
      } else {
        this.$toast.error('Veuillez remplir les champs requis');
      }
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    stateOfficer() {
      this.init();
    },
  },
});
