
import { Vue } from 'vue-property-decorator';
import SidebarApp from '@/components/layout/sidebar-app/sidebar-app.vue';
import NavbarApp from '@/components/layout/navbar-app/navbar-app.vue';
import { mapGetters, mapActions } from 'vuex';
import { layoutMixin } from '@/mixins/layout.mixin';
import { staticVars } from '@/mixins/mixins';
import { nodeMixin } from '@/mixins/node.mixin';

export default Vue.extend({
  name: 'Layout',
  mixins: [staticVars, nodeMixin, layoutMixin],
  components: {
    SidebarApp,
    NavbarApp
  },
  data() {
    return {
      showmenu: false,
      isOpen: false,
      showSide: true,
    };
  },
  computed: {
    ...mapGetters('admin', ['getPageTitle']),
    ...mapGetters('layout', ['getActiveSidebar']),
    ...mapGetters('node', ['getTree']), 
  },
  methods: {
    ...mapActions('layout', ['setActiveSidebar']),
    ...mapActions('node', ['fetchAllTree', 'createNode']),

    toggleSidebar(activeSidebar: boolean) {
      if (activeSidebar !== true) {
        this.setActiveSidebar(true);
      } else {
        this.setActiveSidebar(false);
      }
    },
  }
});
