import { Node } from '@/models/node/node';
import { mapActions, mapGetters } from 'vuex';

export const nodeMixin = {
  computed: {
    ...mapGetters('node', ['getTree']),
  },

  methods: {
    ...mapActions('admin', ['setPageTitle']),
    ...mapActions('stateOfficer', ['loadStateOfficers']),
    ...mapActions('executif', [
      'loadExecutif',
    ]),
    ...mapActions('conseilMunicipal', [
      'loadMunicipalCouncil',
    ]),
    ...mapActions('conseillersMunicipaux', [
      'loadMunicipalCouncillors',
    ]),
    ...mapActions('commissions', ['loadCommissions', 'loadCommittees']),
    ...mapActions('slides', ['loadSlides']),
    ...mapActions('partners', ['loadPartners']),
    ...mapActions('localisation', ['loadLocalisation']),
    ...mapActions('reseaux', ['loadReseaux']),
    ...mapActions('organigramme', [
      'loadOrganizationChart',
    ]),
    ...mapActions('node', ['deleteNode']),
    ...mapActions('library', ['loadDocumentCategories']),
    ...mapActions('serviceCommunalCategories', [
      'loadServicesCommunauxCategories',
    ]),
    ...mapActions('editorial', ['loadEditorial', 'loadTags']),
    ...mapActions('pagesAdmin', ['loadMotifs', 'loadContactMotifs', 'loadRdvMotifs']),
    ...mapActions('configuration', ['loadConfiguration']),
    ...mapActions('mediatheques', ['loadMediatheques']),

    dispatchTree(tree: Node) {
      this.loadStateOfficers(tree);
      this.loadCommittees(tree);
      this.loadMunicipalCouncillors(tree);
      this.loadMunicipalCouncil(tree);
      this.loadSlides(tree);
      this.loadPartners(tree);
      this.loadLocalisation(tree);
      this.loadReseaux(tree);
      this.loadOrganizationChart(tree);
      this.loadDocumentCategories(tree);
      this.loadServicesCommunauxCategories(tree);
      this.loadEditorial(tree);
      this.loadTags(tree);
      this.loadMotifs(tree);
      this.loadContactMotifs(tree);
      this.loadRdvMotifs(tree);
      this.loadExecutif(tree);
      this.loadConfiguration(tree)
      this.loadMediatheques(tree)
    },

    findNodeInTree(navigationPath: string, tree: Node): Node | null {
      if (navigationPath == tree.navigation_path) {
        return tree;
      } else if (tree.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < tree.children.length; i++) {
          result = this.findNodeInTree(navigationPath, tree.children[i]);
        }
        return result;
      }
      return null;
    },

    findNodeInTreeDeprecated(
      navigationPath: string,
      tree: Node,
    ): Node | undefined {
      if (navigationPath == tree.navigation_path) {
        return tree;
      }

      return tree.children?.find((lambda) => {
        if (lambda.navigation_path == navigationPath) {
          return lambda;
        }
        return this.findNodeInTreeDeprecated(navigationPath, lambda);
      });
    },

    checkRequiredPresentNodes(requiredNodes: Array<Node>, tree: Node) {
      const intersection: Node[] = [];

      for (const requiredNode of requiredNodes) {
        const element = this.findNodeInTree(requiredNode.navigation_path, tree);
        if (element) {
          intersection.push(element);
        }
      }

      return intersection;
    },

    checkRequiredAbsentNodes(requiredNodes: Array<Node>, tree: Node) {
      const intersection: Node[] = [];

      for (const requiredNode of requiredNodes) {
        const element = this.findNodeInTree(requiredNode.navigation_path, tree);
        if (element === null) {
          intersection.push(requiredNode);
        }
      }
      return intersection;
    },
  },
};
