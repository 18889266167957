import { AppointmentScheduleTemplate, BusinessCategoryModel, BusinessPurpose } from '@/models/schedule-manager/schedule-manager';
import { MutationTree } from 'vuex';
import { ScheduleManagerState } from "./types";

export const mutations: MutationTree<ScheduleManagerState> = {
    setBusinessCategory(state, payload: BusinessCategoryModel) {
        state.businessCategory = payload
    },

    setBusinessCategories(state, payload: BusinessCategoryModel[]) {
        state.businessCategories = payload
    },
    setBusinessPurpose(state, payload: BusinessPurpose) {
        state.businessPurpose = payload
    },

    setBusinessPurposes(state, payload: Array<BusinessPurpose>) {
        state.businessPurposes = payload
        // state.businessPurposesPublished = payload.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published)
        // state.businessPurposeSelectBox = Utils.businessSelectBox(state.businessPurposesPublished)
    },

    setScheduleTemplate(state, payload: AppointmentScheduleTemplate) {
        state.scheduleTemplate = payload
    },

    setScheduleTemplates(state, payload: Array<AppointmentScheduleTemplate>) {
        state.scheduleTemplates = payload
    },
};