import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { CommissionsState } from './types';
import { Commission } from '@/models/commission/commission';

export const getters: GetterTree<CommissionsState, RootState> = {
  getCommissions(state): Array<Commission> {
    return state.commissions;
  },
};
