import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { auth } from './modules/auth/index';
import { admin } from './modules/admin';
import { layout } from './modules/layout';
import { node } from './modules/node';
import { media } from './modules/media';
import { library } from './modules/library';
import { stateOfficer } from './modules/state-officer';
import { localisation } from './modules/localisation';
import { reseaux } from './modules/reseaux';
import { organigramme } from './modules/organigramme';
import { slides } from './modules/slides';
import { partners } from './modules/partners';
import { commissions } from './modules/commissions';
import { conseillersMunicipaux } from './modules/conseillers-municipaux';
import { conseilMunicipal } from './modules/conseil-municipal';
import { serviceCommunalCategories } from './modules/services-communaux-categories';
import { editorial } from './modules/editorial';
import { scheduleManager } from './modules/schedule-manager';
import { executif } from './modules/executif';
import { profil } from "./modules/profil/index";
import { mediatheques } from './modules/mediatheques';
import { configuration } from './modules/configuration';
import { pagesAdmin } from './modules/pages-admin';
import { routeRedirect } from "./modules/route-redirect/index";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    auth: auth,
    admin: admin,
    layout: layout,
    conseilMunicipal: conseilMunicipal,
    conseillersMunicipaux: conseillersMunicipaux,
    commissions: commissions,
    stateOfficer: stateOfficer,
    slides: slides,
    partners: partners,
    localisation: localisation,
    reseaux: reseaux,
    organigramme: organigramme,
    serviceCommunalCategories: serviceCommunalCategories,
    editorial: editorial,
    library: library,
    node: node,
    media: media,
    scheduleManager: scheduleManager,
    executif: executif,
    profil: profil,
    mediatheques: mediatheques,
    configuration: configuration,
    pagesAdmin: pagesAdmin,
    routeRedirect: routeRedirect
  },
  devtools: false,
};

export default new Vuex.Store<RootState>(store);
