import { AccessControl } from '@/common/acl';

export enum RessourceStateEnum {
  Draft = 'draft',
  Published = 'published',
  Deactivated = 'deactivated',
}

export enum TimeUnit {
  Years = 'years',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
}

export enum ScheduleStub {
  Start = 'start',
  End = 'end',
}

export class BaseModel {
  toStringify(): string {
    return JSON.stringify(this);
  }
}

export interface BusinessPurpose {
  purpose_id: string;
  name: string;
  description: string | undefined;
  organization_unit: string | undefined;
  organization_name: string | undefined;
  access_control: AccessControl | undefined;
  state: string | undefined;
  categories: BusinessCategoryModel[] | undefined
}

export interface BusinessCategoryModel extends BaseModel {
  category_id: string;
  name: string;
  description: string;
  sub_categories: Array<string>;
  state: RessourceStateEnum;
}

export interface TemplateBusinessReference {
  purpose_id: string;
  bp_name: string;
  category_id: string;
  category_name: string;
  sub_category_name: string;
  location_id: string;
  business_id_prefix: string;
}

export interface ParticipantModel {
  user_name: string | undefined;
  ou: string | undefined;
  full_name: string;
  own_ship: boolean;
  is_business_unit?: boolean | undefined;
  meta_data?: Map<string, string> | undefined;
}

export interface ScheduleTemplateMetadataDto {
  title: string;
  description?: string;
  business_reference: TemplateBusinessReference;
  participants: Array<ParticipantModel>;
}

export interface Duration {
  length: number;
  time_unit: TimeUnit;
}

export interface ScheduleSpan {
  start_time: string;
  end_time: string;
  duration: Duration;
  grace_time: Duration | undefined;
  schedule_stub: ScheduleStub | undefined;
  occurrence: number | undefined;
  exclude_weekend: boolean | undefined;
  exclude_holidays: boolean | undefined;
}

export interface ScheduleTemplateCreateDto {
  metadata: ScheduleTemplateMetadataDto;
  schedule_start_time: string;
  schedule_end_time: string;
  schedule_days: Map<string, Array<ScheduleSpan>>;
  state: RessourceStateEnum;
  access_control?: AccessControl;
}

export interface AppointmentScheduleTemplate
  extends BaseModel,
    ScheduleTemplateCreateDto {
  template_id: string;
}
