
import { Vue } from 'vue-property-decorator';
import EditionContainer from '@/components/edition-components/edition-container/edition-container.vue';
import TipTapEdition from '@/components/edition-components/tiptap-edition/tiptap-edition.vue';
import NoveauServiceForm from '@/components/forms/nouveau-service-form/nouveau-service-form.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Media } from '@/models/media/media';
import { ContentElement } from '@/models/content_element/content_element';
import { build } from '@/mixins/build.mixin';
import { nodeMixin } from '@/mixins/node.mixin';
import { rdvModule } from '@/models/rdv-module/rdv-module';
import moment from 'moment'
export default Vue.extend({
  data() {
    return {
      service: {} as ContentElement,
      tiptapData: '',
      tiptapContent: '',
      blockTiptapData: false
    };
  },
  mixins: [build, nodeMixin],
  components: {
    TipTapEdition,
    NoveauServiceForm,
    EditionContainer,
  },
  computed: {
    ...mapGetters('serviceCommunalCategories', [
      'getServicesCommunaux',
      'getServicesCommunauxSousCategories',
    ]),
    ...mapGetters('dataNode', ['getNodes']),
    ...mapGetters('node', ['getTree']),
    getData(): ContentElement | null {
      let service = null;
      if (this.getServicesCommunaux.length !== 0) {
        if (this.$route.params.node_id) {
          this.service = this.getServicesCommunaux.find((service: ContentElement) => service.node_id === this.$route.params.node_id);
          service = this.service
          if (Object.keys(this.service).length !== 0 && !this.blockTiptapData) {
            this.tiptapContent = this.service.value.display_content.html;
          }
        }else{
          service = {} as ContentElement
        }
      }else{
        service = {} as ContentElement
      }
      
      return service;
    }
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('serviceCommunalCategories', [
      'addService',
      'updateServiceContent',
      'updateServiceChildren',
    ]),
    ...mapActions('media', ['updateMediasNode']),

    haveData() {
      return this.getData !== null
    },

    save(serviceFormData: any) {
      this.blockTiptapData = true
      serviceFormData.data['display_content'] = this.tiptapData;
      if (this.$route.params.node_id)
        this.updateService(
          serviceFormData.data,
          serviceFormData.medias,
          serviceFormData.module_rdv,
        );
      else {
        this.addServiceMethod(serviceFormData.data, serviceFormData.medias, serviceFormData.module_rdv)
      }
    },

    addServiceMethod(data: any, medias: Array<Media>, module_rdv: rdvModule) {
      let slug = (this as any).slugify(data.name)
      const service_value = {
        name: data.name,
        desc: data.desc,
        slug: slug,
        type: data.type,
        status: data.status,
        updated_at: moment().format(),
        created_at: moment().format(),
        published_at: '',
        display_content: data.display_content,
        module_rdv: module_rdv,
      };

      if (service_value.status == 'publié') service_value.published_at = moment().format();

      const node = (this as any).buildCreateChildrenPayload(
        'services',
        NodeType.content,
        service_value,
        data.tied_sub_category,
      );

      const mediaNode = (this as any).buildMediaPayload(
        node.contents_requests[0].navigation_path,
        medias,
      );

      const dto = (this as any).buildPayloadWithMedia(node, mediaNode);

      this.addService(dto).then(() => {
        this.$router.push({name: 'ServicesCommunaux', params: { tabIndex: '2' }});
      });
    },
    updateService(service: any, medias: Array<Media>, module_rdv: rdvModule) {
      if (service.published_at == '' || service.published_at == undefined) service.published_at = moment().format();

      let slug = (this as any).slugify(service.name)
      service.slug = slug;
      service.updated_at = moment().format();
      service.module_rdv = module_rdv;
      this.service.value = service;

      const mediaNavigationPath = this.service.navigation_path + '/medias';
      const mediaNode = (this as any).findNodeInTree(
        mediaNavigationPath,
        this.service,
      );
      mediaNode.value.medias = medias;

      this.updateMediasNode(mediaNode).then(() => {
        this.updateServiceContent(this.service);
        this.$router.push({name: 'ServicesCommunaux', params: { tabIndex: '2' }});
      });
    },
  },
});
