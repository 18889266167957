
import { Vue } from 'vue-property-decorator';
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import Section from '@/components/section/section.vue';
import Table from '@/components/table/table.vue';
import ServiceCategoryForm from '@/components/forms/service-category-form/service-category-form.vue';
import ServiceSubCategoryForm from '@/components/forms/service-sub-category-form/service-sub-category-form.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { layoutMixin } from '@/mixins/layout.mixin';
import { Category } from '@/models/category/category';
export default Vue.extend({
  components: {
    Tab,
    TabItem,
    Section,
    Table,
    ServiceCategoryForm,
    ServiceSubCategoryForm,
    ConfirmModal,
  },
  mixins: [layoutMixin],
  data() {
    return {
      tabIndex: 0,
      categoryId: '',
      subCategoryId: '',
      serviceId: '',
      id: '',
      categoryFormIsShown: false,
      subCategoryFormIsShown: false,
      selectedCategory: null as any,
      selectedSubCategory: null as any,
      selectedService: null as any,

      category: null as any,

      subCategory: null as any,

      formRoutes: [{ id: 1, title: 'Service', route: '/nouveau-service' }],
    };
  },
  computed: {
    ...mapGetters('serviceCommunalCategories', [
      'getServicesCommunauxCategories',
      'getServicesCommunauxSousCategories',
      'getServicesCommunaux',
    ]),
  },
  methods: {
    ...mapActions('dataNode', ['deleteNode']),
    ...mapActions('serviceCommunalCategories', [
      'deleteCategory',
      'deleteSubCategory',
      'deleteService',
    ]),

    showCategoryForm() {
      this.categoryFormIsShown = true;
    },
    cancelCategoryForm() {
      this.categoryFormIsShown = false;
      this.category = null;
    },

    editCategory(selectedCategory: Category) {
      this.category = null;
      this.category = selectedCategory;
      this.showCategoryForm();
    },
    showSubCategoryForm() {
      this.subCategoryFormIsShown = true;
    },
    cancelSubCategoryForm() {
      this.subCategoryFormIsShown = false;
      this.subCategory = null;
    },
    editSubCategory(selectedCategory: Category) {
      this.subCategory = null;
      this.subCategory = selectedCategory;
      this.showSubCategoryForm();
    },

    confirmCategory(selectedCategory: Category) {
      const nodeId = selectedCategory.node_id;
      if (nodeId) {
        this.categoryId = nodeId;
        this.$bvModal.show(nodeId);
        this.selectedCategory = selectedCategory;
      }
    },
    confirmSubCategory(selectedCategory: Category) {
      const nodeId = selectedCategory.node_id;
      if (nodeId) {
        this.subCategoryId = nodeId;
        this.$bvModal.show(nodeId);
        this.selectedSubCategory = selectedCategory;
      }
    },
    confirmService(selectedService: any) {
      const nodeId = selectedService.node_id;

      if (nodeId) {
        this.serviceId = nodeId;
        this.$bvModal.show(nodeId);
        this.selectedService = selectedService;
      }
    },
    deleteServiceCategory() {
      this.deleteCategory(this.selectedCategory);
    },
    deleteServiceSubCategory() {
      this.deleteSubCategory(this.selectedSubCategory);
    },
    deleteMunicipalService() {
      this.deleteService(this.selectedService);
    },
  },
  beforeMount() {
    this.categoryId = Math.random().toString();
    if(this.$route.params.tabIndex){
      this.tabIndex = 2;
    }
  },
});
