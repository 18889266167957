import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { EditorialState } from './types';
import { ContentElement } from '@/models/content_element/content_element';
import { Category } from '@/models/category/category';

export const getters: GetterTree<EditorialState, RootState> = {
  getEditorial(state): Array<ContentElement> {
    return state.editorial;
  },
  getTags(state): Array<Category> {
    return state.tags;
  },
};
