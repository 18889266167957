import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/library/getters';
import { actions } from '@/store/modules/library/action';
import { mutations } from '@/store/modules/library/mutations';
import { DocumentsState } from './types';

export const state: DocumentsState = {
  documentCategories: [],
  documents: [],
};

export const library: Module<DocumentsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
