import { MutationTree } from 'vuex';
import { MediaState } from './types';
import { Media } from '@/models/media/media';

export const mutations: MutationTree<MediaState> = {
  setLastMediaUploaded(state, payload: Array<Media>) {
    state.lastMediaUploaded = payload;
  },

  isMediaStore(state, payload: boolean) {
    state.isMediaStore = payload;
  },
};
