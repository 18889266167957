import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/commissions/getters';
import { actions } from '@/store/modules/commissions/action';
import { mutations } from '@/store/modules/commissions/mutations';
import { CommissionsState } from './types';
export const state: CommissionsState = {
  commissions: [],
};

export const commissions: Module<CommissionsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
