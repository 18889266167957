
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import SelectFormGroup from '@/components/inputs/select-form-group/select-form-group.vue';
import { dateMixin } from '@/mixins/date.mixin';
export default Vue.extend({
  components: {
    InputFormGroupText,
    SelectFormGroup,
  },
  name: 'FileInput',
  props: ['value', 'editorial', 'type', 'validate'],
  mixins: [dateMixin],
  data() {
    return {
      onTopOptions: [
        { value: false, text: 'NON' },
        { value: true, text: 'OUI' },
      ],
      tagOptions: [
        { value: 'null', text: 'Choisir le tag' },
      ],
      form: {
        name: '',
        desc: '',
        tag: 'null',
        frontpage: false,
        event_start_date: '',
        event_end_date: '',
      },
    };
  },
  computed: {
    ...mapGetters('editorial', ['getTags',]),
  },
  methods: {
    sendChanges() {
      this.$emit('input', {
        name: this.form.name,
        desc: this.form.desc,
        tag: this.form.tag,
        frontpage: this.form.frontpage,
        event_start_date: new Date(this.form.event_start_date),
        event_end_date: new Date(this.form.event_end_date),
      });
    },
  },
  beforeMount() {
    this.getTags.map((category: any) => {
      this.tagOptions.push({
        value: category.value.name,
        text: category.value.name,
      });
    });
    this.form.tag = this.editorial.value.tag;
    this.form.name = this.editorial.value.name;
    this.form.desc = this.editorial.value.desc;
    this.form.frontpage = this.editorial.value.frontpage;

    this.form.event_start_date = this.formatDate(this.editorial.value.event_start_date);
    this.form.event_end_date = this.formatDate(this.editorial.value.event_end_date);
  },
});
