import { Reseaux } from '@/models/reseaux/reseaux';
import { MutationTree } from 'vuex';
import { ReseauxState } from './types';

export const mutations: MutationTree<ReseauxState> = {
  setReseaux(state, payload: Reseaux) {
    state.reseaux = payload;
  },
  setMounted(state, payload: boolean) {
    state.value = payload;
  },
};
