import { CreateMediaDto } from '@/dto/media-dto';
import { Media } from '@/models/media/media';
import { nodeMixin } from '@/mixins/node.mixin';
import { Node } from '@/models/node/node';

export const mediaMixin = {
  data() {
    return {
      accept: 'image/*',
      acceptAudioFormat: ['audio/*', '.mpeg'],
      acceptDocumentFormat:
        '.doc,.docx,application/msword,' +
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf',
    };
  },

  methods: {
    buildPayload(
      fileName: string,
      tags: Array<string>,
      medias: File[],
    ): CreateMediaDto {
      const dto: CreateMediaDto = {
        file_name: fileName,
        tags: tags,
        medias: medias,
      };

      return dto;
    },
    updateImagesByTags(mediasTable: Media[], media: Media[], isSingle: boolean): Media[] {
      const currentMedia = mediasTable!.find(
        (med) => med.tags[1] === media[0].tags[1],
      );
      if (currentMedia == undefined) mediasTable = mediasTable.concat(media);
      else {
        if(isSingle){
          mediasTable = mediasTable.filter((med) => med.tags[1] !== media[0].tags[1]);
          mediasTable = mediasTable.concat(media);
        }else
          mediasTable = mediasTable.concat(media);
      }
      return mediasTable;
    },

    updateMediasByTags(mediasTable: Media[], media: Media[]): Media[] {
      const currentMedia = mediasTable!.find(
        (med) => med.tags[1] === media[0].tags[1],
      );
      if (currentMedia == undefined) mediasTable = mediasTable.concat(media);
      else {
        mediasTable = mediasTable.filter((med) => med.tags[1] !== media[0].tags[1]);
        mediasTable = mediasTable.concat(media);
      }
      return mediasTable;
    },

    retrieveMediaByTags(tags: string[], medias: Media[]): Media[] {
      const result: Media[] = [];

      if (medias == undefined) return result;
      else {
        medias.filter((media) => {
          const intersection = tags.every((element) =>
            media.tags.includes(element),
          );
          if (intersection) {
            result.push(media);
          }
        });
        return result;
      }
    },

    getMediasFormNode(node: Node, tags: string[]): Media[] {
      if (node) {
        const mediaPath = node.navigation_path + '/medias';

        const mediaNode = nodeMixin.methods.findNodeInTree(mediaPath, node);

        if (mediaNode == null) return [];
        else {
          if (tags == []) return mediaNode.value.medias;
          else return this.retrieveMediaByTags(tags, mediaNode.value.medias);
        }
      } else return [] as Media[];
    },
  },
};
