import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/services-communaux-categories/getters';
import { actions } from '@/store/modules/services-communaux-categories/action';
import { mutations } from '@/store/modules/services-communaux-categories/mutations';
import { ServiceCommunalCategoriesState } from './types';
export const state: ServiceCommunalCategoriesState = {
  servicesCommunauxCategories: [],
  servicesCommunauxSousCategories: [],
  servicesCommunaux: [],
};

export const serviceCommunalCategories: Module<
  ServiceCommunalCategoriesState,
  RootState
> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
