import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { LocalisationState } from './types';
import { Localisation } from '@/models/localisation/localisation';

export const getters: GetterTree<LocalisationState, RootState> = {
  getLocalisation(state): Localisation | null {
    return state.localisation;
  },
};
