import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/editorial/getters';
import { actions } from '@/store/modules/editorial/action';
import { mutations } from '@/store/modules/editorial/mutations';
import { EditorialState } from './types';
export const state: EditorialState = {
  editorial: [],
  tags: []
};

export const editorial: Module<EditorialState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
