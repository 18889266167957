
import { Vue } from 'vue-property-decorator';
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import Section from '@/components/section/section.vue';
import Table from '@/components/table/table.vue';
import ConfirmModal from '@/components/modals/confirm-modal/confirm-modal.vue';
import DocumentCategoryForm from '@/components/forms/document-category-form/document-category-form.vue';
import DocumentForm from '@/components/forms/document-form/document-form.vue';
import { mapActions, mapGetters } from 'vuex';
import { Category } from '@/models/category/category';
import { Document } from '@/models/document/document';
import { layoutMixin } from '@/mixins/layout.mixin';
export default Vue.extend({
  name: 'Bibliotheque',
  mixins: [layoutMixin],
  components: {
    Tab,
    TabItem,
    Section,
    Table,
    DocumentCategoryForm,
    ConfirmModal,
    DocumentForm,
  },
  data() {
    return {
      tabIndex: 0,
      categoryId: '',
      documentId: '',
      id: '',
      categoryFormIsShown: false,
      libraryFormIsShown: false,
      etat: true,
      documentEtat: true,

      selectedCategory: null as any,
      categoriesFilterOptions: [
        { value: null, text: 'Veuillez selectionner le filtre' },
        { value: 'name', text: 'Rubrique' },
        { value: 'desc', text: 'Description' },
      ],

      selectedDocument: null as any,
      LibraryFilterOptions: [
        { value: null, text: 'Veuillez selectionner le filtre' },
        { value: 'category', text: 'Catégorie' },
        { value: 'doc_name', text: 'Document' },
        { value: 'desc', text: 'Description' },
      ],
    };
  },
  computed: {
    ...mapGetters('library', ['getDocumentCategories', 'getDocuments']),
  },
  methods: {
    ...mapActions('dataNode', ['deleteNode']),
    ...mapActions('library', [
      'deleteLibraryCategory',
      'deleteLibraryDocument',
    ]),

    showCategoryForm() {
      this.categoryFormIsShown = true;
      this.etat = false
    },
    cancelCategoryForm() {
      this.categoryFormIsShown = false;
      this.selectedCategory = null;
      this.etat = true
    },
    editCategory(selectedCategory: Category) {
      this.selectedCategory = selectedCategory;
      this.showCategoryForm();
    },

    //library
    showLibraryForm() {
      this.libraryFormIsShown = true;
      this.documentEtat = false
    },
    cancelLibraryForm() {
      this.libraryFormIsShown = false;
      this.selectedDocument = null;
      this.documentEtat = true
    },
    editLibrary(selectedDocument: Document) {
      this.selectedDocument = selectedDocument;
      this.showLibraryForm();
    },

    confirmCategory(selectedCategory: Category) {
      const nodeId = selectedCategory.node_id;
      if (nodeId) {
        this.categoryId = nodeId;
        this.$bvModal.show(nodeId);
        this.selectedCategory = selectedCategory;
      }
    },
    confirmDocument(selectedDocument: Document) {
      const nodeId = selectedDocument.node_id;
      if (nodeId) {
        this.documentId = nodeId;
        this.$bvModal.show(nodeId);
        this.selectedDocument = selectedDocument;
      }
    },
    deleteCategory() {
      this.deleteLibraryCategory(this.selectedCategory);
    },
    deleteDocument() {
      this.deleteLibraryDocument(this.selectedDocument);
    },
  },
  beforeMount() {
    this.categoryId = Math.random().toString();
  },
});
