
import { Vue } from 'vue-property-decorator';
import InputFormGroupText from '@/components/inputs/input-form-group-text/input-form-group-text.vue';
import { mapActions, mapGetters } from 'vuex';
import { NodeType } from '@/models/enums/enums';
import { Localisation } from '@/models/localisation/localisation';
import { build } from '@/mixins/build.mixin';
export default Vue.extend({
  props: ['injectedLocalisation'],
  components: {
    InputFormGroupText,
  },
  mixins: [build],
  data() {
    return {
      oldData: {} as Localisation,
      lockUpdate: true,
      localisation: this.injectedLocalisation,
    };
  },
  computed: {
    haveDefaultData(): boolean {
      return !!this.localisation;
    },

    creating(): boolean {
      return !!this.injectedLocalisation;
    },
  },
  created: function () {
    this.$watch('localisation', this.localisationUpdated, {
      deep: true,
    });
  },
  methods: {
    ...mapActions('dataNode', ['createNode', 'updateNodes', 'deleteNodes']),
    ...mapActions('localisation', ['addLocalisation', 'updateLocalisation']),

    localisationUpdated() {
      this.lockUpdate = false;
    },
    cancel() {
      this.lockUpdate = true;
    },
    save() {
      const node = (this as any).buildCreatePayload(
        this.$data.required_nodes,
        'localisation',
        NodeType.content,
        this.localisation.value,
      );
      this.addLocalisation(node).then(() => {
        this.creating = false;
        this.lockUpdate = true;
      });
    },
    update() {
      this.updateLocalisation(this.localisation);
      this.lockUpdate = true;
    }
  },
  beforeMount() {
    if (!this.haveDefaultData) {
        this.localisation = {
          value: {
            address: '',
            email: '',
            street: '',
            tel1: '',
            tel2: '',
            tel3: '',
          },
        };
        this.lockUpdate = true;
    }
  }
});
