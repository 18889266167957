
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  props: ['title', 'section_collapse'],
  data() {
    return {
      collapse: this.section_collapse
    }
  },
  methods: {
    openCollapse() {
      this.$emit('openCollapse', !this.collapse);
      this.collapse = !this.collapse
    }
  }
});
