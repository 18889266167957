
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  props: ['title', 'index', 'activeIndex', 'disabled'],
  methods: {
    classes() {
      return this.index == this.activeIndex
        ? 'text-primary rounded-0'
        : 'text-secondary rounded-0';
    },
  },
});
