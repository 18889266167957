import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/media/getters';
import { actions } from '@/store/modules/media/actions';
import { mutations } from '@/store/modules/media/mutations';
import { MediaState } from '@/store/modules/media/types';

export const state: MediaState = {
  lastMediaUploaded: [],
  isMediaStore: false,
};

export const media: Module<MediaState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
