import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/node/getters';
import { actions } from '@/store/modules/node/actions';
import { mutations } from '@/store/modules/node/mutations';
import { NodeState } from './types';

export const state: NodeState = {
  tree: undefined,
  nodes: [],
};

export const node: Module<NodeState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
