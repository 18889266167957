
import { Vue } from 'vue-property-decorator';
import InputImage from '@/components/inputs/input-image/input-image.vue';
import { mapGetters } from 'vuex';
import { Media } from '@/models/media/media';
import ActionBtn from '@/components/buttons/action-button/action-button.vue';
export default Vue.extend({
  name: 'ImageCard',
  components: {
    InputImage,
    ActionBtn,
  },
  props: [
    'id',
    'inputTitle',
    'isSingle',
    'inputDisabled',
    'disableCard',
    'disableFontWeigth',
    'disableColor',
    'defaultImgs',
    'tags',
    'images',
    'mediatheque'
  ],

  data() {
    return {
      imageUploaded: [] as Media[],
      imagesListe: [] as Media[],
      clear: 0,
    };
  },

  computed: {
    ...mapGetters('media', ['getLastMediaUploaded']),
  },

  methods: {
    cancel() {
      this.clear++;
    },
    handleDelete(index: any, oneImage: Media) {
      this.imagesListe.splice(index, 1);
      this.$emit('deleteImage', oneImage);
    },

    getUri(oneImage: Media) {
      if (oneImage?.uri) return oneImage.uri;
      return null;
    },

    updateMedias(medias: Media[]) {
      this.imageUploaded = medias;
    },

    imageToDisplay() {
      if (this.imageUploaded.length) {
        this.imagesListe = this.imageUploaded;
        return this.images;
      } else {
        return this.images;
      }
    },
  },
});
