import { ActionTree } from 'vuex';
import { PagesAdminState } from './types';
import { RootState } from '@/store/types';
import { NodeService } from '@/services/node.service';
import { nodeMixin } from '@/mixins/node.mixin';
import { NodeType } from '@/models/enums/enums';
import { Node } from '@/models/node/node';
import { DataNodeUpdateDto } from '@/dto/data-node-dto';
import Vue from 'vue';

export const actions: ActionTree<PagesAdminState, RootState> = {
  loadMotifs(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/motifs',
      tree,
    );
    if (parent) {
      const contentNode = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      contentNode?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setMotifs', contentNode);
    }
  },
  loadContactMotifs(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/contact_motifs',
      tree,
    );
    if (parent) {
      const contentNode = parent.children?.filter(
        (node) => node.node_type == NodeType.content,
      );
      contentNode?.sort(function (a: any, b: any) {
        const A = new Date(a.created_at)
        const B = new Date(b.created_at)
        return A < B ? 1 : -1
      })
      context.commit('setContactMotifs', contentNode);
    }
  },
  addMotif(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode) {
            context.commit('addMotif', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  addContactMotif(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode) {
            context.commit('addContactMotif', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  updateMotif(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateMotif', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  updateContactMotif(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('updateContactMotif', payload);
          Vue.$toast('Modification effectuée');
        }
      });
  },
  deleteMotif(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeMotif', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  deleteContactMotif(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .delete(payload.node_id)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('removeContactMotif', payload);
          Vue.$toast('Suppression effectuée avec succès');
        }
      });
  },
  loadRdvMotifs(context, tree: Node) {
    if (!tree) {
      console.log('Root tree exception');
    }

    const parent = nodeMixin.methods.findNodeInTree(
      '/mairie/rdv_motifs',
      tree,
    );
    if (parent) {
      context.commit('addRdvMotif', parent.children![0]);
    }
  },
  addRdvMotif(context, payload) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .create(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          const contentNode = value.data.nodes.find(
            (node) => node.node_type == NodeType.content,
          );
          if (contentNode) {
            context.commit('addRdvMotif', contentNode);
            Vue.$toast('Ajout effectué avec succès');
          }
        }
      });
  },
  updateRdvMotif(context, payload: DataNodeUpdateDto) {
    NodeService.getInstance(this.getters.getUser.access.access_token)
      .update(payload)
      .then((value) => {
        if (value.apiError) {
          console.log(value);
        }
        if (value.data) {
          context.commit('addRdvMotif', payload);
          Vue.$toast('Mise à jour des motifs effectuée avec succès');
        }
      });
  },
};
