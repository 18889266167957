
  import { CreateMediaDto } from '@/dto/media-dto';
  import { Media } from '@/models/media/media';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import { mediaMixin } from '@/mixins/media.mixin';
  import { build } from '@/mixins/build.mixin';
  
  export default Vue.extend({
    name: 'InputAudio',
    mixins: [mediaMixin, build],
    props: {
      inputId: String,
      id: Number,
      tags: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
  
    data() {
      return {
        audioName: '' as string,
        enableAdd: false,
        documents: [] as Media[],
      };
    },
  
    computed: {
      enableAddButton(): boolean {
        return this.audioName.length == 0;
      },
    },
  
    methods: {
      ...mapActions('media', ['createMedias']),
  
      choose() {
        let file = document.createElement('input');

        file.type = 'file';
        file.accept = (this as any).acceptAudioFormat;
        file.multiple = false;
        file.click();

        const files: File[] = [];

        file.onchange = () => {
            if (file.files) {
            for (let i = 0; i < file.files.length; i++) {
                const currentFile = file.files.item(i);
                if (currentFile) {
                files.push(currentFile);
                }
            }
            }

            this.uploadFile(files);
        };
      },
      uploadFile(files: File[]) {
        const name = this.audioName;

        const payload: CreateMediaDto = (this as any).buildPayload(
            name,
            this.tags,
            files,
        );
        this.createMedias(payload).then((value) => {
            if (value.data) {
                this.$emit('updateMedias', value.data, this.isSingle);
                this.$parent!.$emit('updateMedias', value.data, this.isSingle);
                Vue.$toast('Piste audio ajoutée');
            }
            if (value.apiError) {
                Vue.$toast("Erreur lors de l'upload");
            }
        });
      },
    },
  });
  